@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.home_services {

  width: 100%;
  padding: 0;
  background: $colorTextInverted;
  margin: 40px 0;
  .section_head{
    padding: 0;
  }
  .services_wrapper{
    display: flex;
    gap: 26px;
    @include mediaTo($size991) {
      gap: 12px;
    }
    .service_block{
      display: flex;
      padding: 32px 24px;
      border-radius: $borderRadius20;
      background: $colorTextInverted;
      border: 1px solid $colorBackgroundSurfaceStroke;
      max-width: 378px;
      width: 100%;
      .inner_service{
        display: flex;
        gap: 8px;

        .service_img{
          max-width: 42px;
          height: 30px;
          margin-top: 2px;
          img{
            object-fit: unset;
            width: 100%;
            height: 100%;
          }
        }

        .service_info {
          display: flex;
          flex-direction: column;
          width: calc(100% - 40px);
          .service_title {
            color: $colorTextPrimary;
            font-size: $fontSize16;
            line-height: 22px;
            font-family: "NotoSansArmenian-SemiBold", sans-serif;
            margin-bottom: 8px;
          }

          .service_summary {
            font-size: $fontSize14;
            line-height: 20px;
            color: $colorTextSecondary;

          }
        }
      }
    }
  }
&.isBlack{
    background: $backgroundBlack;
  .product_card{
    padding: 56px 0;
    .section_head{
      .section_title{
        color: white;
      }
    }
    .services_wrapper{
      .service_block{
        border: none;
        background: #272B30;
        .service_info{
          .service_title{
            font-family: "montserrat-medium", "montserratarm";
          }
          .service_title,.service_summary{
            color: white;
            font-weight: normal;
          }
        }
        .service_img{
          img{
            filter: brightness(0) invert(1);
          }
        }
      }
    }
  }
  }
}
