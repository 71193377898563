@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.error_page {
    .inner_soon {
        margin: 70px auto 0;
    }
}

.error_section {
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    .error_block {
        text-align: center;
        max-width: 770px;
        position: relative;
        z-index: 10;
    }

    .image_block {
        position: absolute;
        padding-bottom: 70px;
        padding-right: 10px;
        z-index: 1;

        img {
            width: 100%;
            height: auto;
        }
    }

    .page_links {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .prev_page {
        display: flex;
        align-items: center;
        height: 40px;
        padding: 0 30px;
        background: $white;
        color: $siteColor;
        font-family: $medium;
        border-radius: 10px;
        @include smallText;
        margin-left: 10px;
        border: 1px solid;
    }

    .back_home {
        margin-right: 20px;
        display: flex;
        align-items: center;
        height: 40px;
        border: none;
        color: $white;
        @include smallText;
        padding: 0 30px;
        background: $secondColor;
        font-family: $medium;
        border-radius: 10px;
    }

    @include mediaRange($size576, $size768) {
        .error_block {
            max-width: 460px;
        }
    }
    @include mediaTo($size576) {
        .page_links {
            margin-top: 20px;
        }
        .back_home {
            margin-right: 0;
        }
    }
}


.web {
    .error_section .prev_page {
        @include siteLightRHover;
    }

    .error_section .back_home {
        @include standardBgHover;
    }
}


.touch {
    .error_section .prev_page {
        @include siteLightRActive;
    }

    .error_section .back_home {
        @include standardBgActive;
    }
}