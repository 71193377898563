@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';
@import '../../../components/uiKit/styles/mixins';


.universal_account_block {
  width: 100%;
  position: relative;
  margin: 150px 0 80px;
  background: $colorBackgroundSurfacePrimary;
  @include mediaTo($size991){
    margin: 56px 0 ;
  }
  .content-wrapper {
    background: #FFFFFF;
    box-shadow: 0 4px 16px 0 rgba(34, 59, 148, 0.16);
    display: flex;
    border-radius: 24px;
    flex-direction: row;
    justify-content: space-between;
    padding: 64px;
    align-items: center;
    height: 100%;
    width: 100%;

    .loading_description {
      @include mediumText;
      color: $colorTextPrimary;
    }

    .section_title {
      color: $colorTextPrimary;
      margin-bottom: 16px;
    }

    .right_block {
      justify-content: center;
      display: flex;
      margin-left: auto;
      @include mediaFrom($size960) {
        @include standardCol(6);
      }
      @include mediaTo($size960) {
        @include standardCol(12);
        display: flex;
        justify-content: center;
      }

      img {
        display: block;
        height: auto;
        max-width: 100%;
        max-height: 300px;

        border-radius: 20px;
      }
    }
  }

  &.isBlack {
    .text_btn {
      background: $backgroundBlack;
    }
  }
  .video_btn {
    position: relative;
    //width: 420px;
    min-height: 260px;
    overflow: hidden;
    min-width: 72px;
  }
  .btn_play {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 72px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $colorControlSecondaryDefault;
    border-radius: $borderRadius16;
    cursor: pointer;
    border: none;
    svg{
      margin-right: 0;
    }
  }
  .activate_universal_account{
    position: relative;
    background:$colorBackgroundSurfacePrimary;
    box-shadow: $boxShadowLowest;
    display: flex;
    border-radius: $borderRadius24;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 64px;
    .banner_img{
      width: 100%;
      min-height: 300px;
      display: flex;
      position: relative;
      overflow: hidden;
      border-radius: 10px;
    }

    .banner_info{
      width: 100%;
      z-index: 2;
      display: flex;
      justify-content: center;
      flex-direction: column;
      button,a{
        margin-top: 32px;
      }
    }

    .section_title {
      color: $colorTextPrimary;
      max-width: 540px;
      @include textOverflow(2, relative);
    }
    .banner_description{
      @include mediumText;
      max-width: 540px;
      color: $colorTextPrimary;
      margin-top: 16px;
      p{
        margin: 0;
        padding: 0;
      }
    }
    .primary_btn{
      align-self: flex-start;
      margin-top: 24px;
    }
    .universal_block_sk {
      list-style-type: none;
      width: 100%;
      margin: 0;
      padding: 40px;
      li{
        display: block;
        content: "";
        height: 14px;
        width: 50%;
        margin: 3px 0 4px;
        @include skeleton;
        border-radius: 10px;
        &:first-child{
          margin-bottom: 16px;
          height: 40px;
        }

        &:nth-child(3){
          height: 40px;
          width: 200px;
          margin-top: 32px;
        }
        &:nth-child(2){
          height: 60px;
        }
        &:last-child{
          width: 40%;
          height: 90%;
          top: 4%;
          position: absolute;
          right: 10px;
        }
      }
    }
  }
  @include mediaRange($size960, $size1200) {
    .home_banner_inner {
      .banner_info {
        padding: 0 48px;
      }
    }

  }
  @include mediaRange($size576, $size960) {
    margin-bottom: 60px;
    .home_banner_inner {
      .banner_info {
        padding: 0 32px;
      }
      .banner_img {
        border-radius: 16px;
        min-height: 270px;
      }
    }
  }
  @include mediaTo($size576) {
    .home_banner_inner {
      .banner_info {
        padding: 0 16px;
      }
      .banner_img {
        border-radius: 12px;
        min-height: 240px;
      }
    }
  }
}
@media (max-width: $size991) {
  .universal_account_block {
    .content-wrapper{
      flex-direction: column-reverse;
      justify-content: center;
      padding: 16px 24px;
      .right_block{
        width: 100%;
        margin-left: 0;
        margin-bottom: 10px;
        img {
          margin: 0 auto;
        }
        .video_btn {
          min-height: unset;
        }
      }

      .banner_info{
        align-items: start;
        button,a{
          width: 100% !important;
        }
      }

      .section_title {
        width: 100%;
      }

    }
  }
}