@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.landing_page {
    background-position: initial;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    .template {
        width: 100%;
    }
    @include mediaTo($size960) {
        .home_services .slick-arrow {
            margin-top: -30px;
        }
    }

    &.home_form {
        .landing_form {
            padding:40px 0 40px;
            margin-top: 40px;
            @include mediaTo($size991) {
                margin: 0;
                margin-top: 16px;
                padding:40px 0 64px;
            }
        }
    }

    p {
        margin: 0;
        padding: 0;
    }

    .video_btn {
        position: relative;
        //width: 420px;
        min-height: 260px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 72px;
    }

    .loading_summary {
        @include smallText;
        color: $gray66;
    }

    .blog_landing {
        padding: 40px 0;
        width: 100%;
        background: $colorTextInverted;
        @media (max-width:$size991){
            padding: 20px 0;
        }
    }

    .btn_play {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 72px;
        height: 72px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $colorControlSecondaryDefault;
        border-radius: $borderRadius16;
        cursor: pointer;
        border: none;
        svg{
            margin-right: 0;
        }
    }

    .video_block {
        position: relative;
        max-height: 300px;
        height: 100%;
        > img {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100% !important;
            object-fit: cover;
            z-index: 1;
            display: flex !important;
            align-items: center;
            justify-content: center;
        }
    }

    .loading_description {
        @include mediumText;
        color: $colorTextPrimary;
    }

    .landing_title {
        font-size: 160%;
        line-height: 24px;
        color: $colorTextPrimary;
        font-family: $semibold;
    }

    .banner_bg_section {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .blog_section {

    margin: 40px 0;
        padding: 0;
    }
    .main_slider{
        width: 100%;
    }
    //mobile_app_section
    .mobile_app_section {
        width: 100%;
        .content-wrapper{
            background:$colorBackgroundSurfacePrimary;
            box-shadow: $boxShadowLowest;
            display: flex;
            border-radius: $borderRadius24;
            flex-direction: row;
            justify-content: space-between;
            padding: 0!important;
            .loading_description {
                color: $colorTextPrimary;

            }

            .section_title {
                color: $colorTextPrimary;
                margin-bottom: 16px;
            }

            .left_block {
                padding: 64px 16px 64px 64px;
            }

            .right_block {
                flex: 0 0 50%!important;
                max-width: 50%!important;
                @include mediaTo($size960) {
                    @include standardCol(12);
                    display: flex;
                    margin-top: 30px;
                    justify-content: center;
                }

                img {
                    display: block;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                    border-radius: 20px;
                }
            }

            .primary_btn {
                margin-top: 40px;
                @include mediaTo($size768) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 20px auto;
                    width: max-content;
                }
            }
        }

    }

    //mobile_app_section

    //small_banner
    .small_banner_section{
        padding: 40px 0;
        width: 100%;

        .small_banner {
            background: $colorBrandDarkblue;
            box-shadow: $boxShadowLowest;
            display: flex;
            border-radius: $borderRadius12;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            background-repeat: no-repeat;
            overflow: hidden;
            background-position: right;
            background-size: 40% 100%;
            padding: 64px;
            width: 100%;

            .store_wrapper{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 24px;
                flex-wrap: wrap;
                @include mediaTo($size991) {
                    justify-content: start;
                    gap: 16px;
                }
            }


            //@include mediaRange($size960, $size1200) {
            //    padding: 33px 30px;
            //}

            @include mediaTo($size991) {
                padding: 24px 16px;
                background-position: initial;
                background-size: cover;
            }

            .section_title {
                text-align: center;
                color: $colorTextInverted;
                font-weight: normal;
                margin-bottom: 32px;
                @include mediaTo($size991) {
                    text-align: start;
                }
            }

            .page_row {
                align-items: center;
                justify-content: start;
                gap: 24px;
                a {
                    display: block;
                    //padding: 0 10px;
                }
            }
        }

    }

    //small_banner

    //about_product
    .about_product {
        width: 100%;

        padding: 56px 0;
        background: $colorConstantGrey50;
        margin: 40px 0;

        .slick-dots {
            @include standardList;
            display: flex;
            justify-content: center;
            position: relative;
            bottom: unset;
            margin-bottom: 10px;

            li {
                padding-top: 24px;
                margin: 0 5px;

                &.slick-active button {
                    border-width: 3px;
                    background: $siteColor;
                    pointer-events: none;
                }
            }

            button {
                width: 18px;
                height: 18px;
                font-size: 0;
                line-height: 0;
                border: 4px solid $colorTextInverted;
                background: $grayE9;
                border-radius: 50%;
                padding: 0;

                &:before {
                    display: none;
                    pointer-events: none;
                    overflow: hidden;
                    height: 0;
                }
            }

            @include mediaTo($size960) {
                li {
                    padding-top: 12px;

                    &.slick-active button {
                        border-width: 6px;
                    }
                }
                button {
                    width: 24px;
                    height: 24px;
                    border-width: 7px;
                }
            }
        }

        .slick-slider.product_section {
            @include rowMargin;
            margin-bottom: 40px;
           .slick-list .slick-track{
               display: flex;
               gap: 32px;
           }
        }

        .section_head {
            align-items: center;
            justify-content: center;
            text-align: center;
            @include mediaTo($size991){
                justify-content: start;
                text-align: start;
            }
            .section_title{
                margin: 0;
            }
        }

        .product_section_slider {
            display: block;
            @include overHidden;
            border-radius: 10px;
        }

        .section_img_wrapper{
            background: $colorBrandDarkblue;
            padding: 28px;
            border-radius: $borderRadius24;
            margin-bottom: 16px;
        .section_img {
            border-radius: $borderRadius12;
            display: block;
            filter: drop-shadow(0px 4px 24px rgba(19, 69, 98, 0.1));
            flex: 1 1;
            max-width: 350px;
            position: relative;

            img {
                border-radius: $borderRadius12;
                display: block;
                height: auto;
                width: 100%;
            }
        }
        }

        .landing_title {
            text-align: center;
            max-width: 350px;
            width: 100%;
            margin: 0 auto;
            padding: 0;
        }

        .primary_btn {
            margin: 50px auto 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: max-content;
        }

    }

    //about_product

    //left_section
    .left_section {
        width: 100%;

        position: relative;
        z-index: 1;
        overflow: hidden;
        padding-bottom: 80px;

        @include mediaTo($size991) {
            padding-bottom: 56px;
        }


        @include mediaFrom($size960) {
            background-size: 100% 170%;
            //     padding: 0 0 125px;
        }

        @include mediaRange($size768, $size960) {
            //  padding: 0 0 80px;
            background-size: 100% 170%;
        }

        @include mediaTo($size768) {
            background-size: cover;
            //padding: 0 0 60px;
        }

        .inner_section {
            background: $colorTextInverted;
            border-radius: 30px;
            padding: 65px 24px 0;
            @include mediaFrom($size960) {
                padding: 65px 24px 0;
            }

            @include mediaRange($size768, $size960) {
                padding: 30px 24px 0;
            }

            @include mediaTo($size768) {
                padding: 30px 24px 0;
            }
        }

        .left_block {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;

            img {
                display: block;
                height: auto;
                max-width: 100%;
                max-height: 100%;
                @include mediaTo($size768) {
                    border-radius: 30px;
                }
            }
        }


        .loading_description {
            color: $gray66;
            @include mediaFrom($size960) {
                margin-top: 20px;
            }
            @include mediaTo($size960) {
                margin-top: 10px;
            }
        }

        .primary_btn {
            @include mediaFrom($size960) {
                margin: 40px 0 20px;
            }
            @include mediaTo($size960) {
                margin: 20px 0 10px;
            }
            @include mediaTo($size768) {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 20px auto;
                width: max-content;
            }
        }
    }

    //left_section
    .left_section.left_section_full{
        width: 100%;

        margin-top: 40px;
        .inner_section{
            width: 100%;
            display: flex;
            align-items: start;
            padding: 40px 64px;
            border: 1px solid $colorBackgroundSurfaceStroke;
            justify-content: space-evenly;
            .right_block{
                max-width: 540px;
                .secondary-button{
                    margin: 24px 0;
                }
                .loading_description {
                    color: $colorTextPrimary;

                }
                .loading_summary{
                    color: $colorTextSecondary;
                    line-height: 20px;
                    font-size: $fontSize14;
                }
            }
        }
    }
    //left_section
    .left_section.left_section_50{
        width: calc(50% - 5px);
        margin: unset;
        height: 100%;
        min-height: 372px;
        display: flex;
        padding-bottom: 0;
        margin-bottom: 40px;
        .page_container{
            margin: unset;
            margin-left: auto!important;
            max-width: 615px;
            .page_row{
                height: 100%;
                flex-wrap: unset;
                justify-content: space-between;
                align-items: start;
                .inner_section{
                    width: 100%;
                    display: flex;
                    align-items: start;
                    height: 100%;
                    padding: 40px 64px;
                    border: 1px solid $colorBackgroundSurfaceStroke;

                    .left_block{
                        padding-left: unset;
                        padding-right: unset;
                        flex: unset;
                        max-width: unset;
                    }
                    .right_block{
                        padding-bottom: unset;
                        max-width: unset;
                        min-width: 320px;
                        .secondary-button{
                            margin: 24px 0;
                        }
                        .loading_description {
                            color: $colorTextPrimary;
                        }
                        .loading_summary{
                            color: $colorTextSecondary;
                            line-height: 20px;
                            font-size: $fontSize14;
                            width: max-content;
                        }
                    }
                }
            }
        }
    }

    //right_section
    .right_section {
        margin-bottom: 40px;
        width: calc(50% - 5px);
        position: relative;
        z-index: 1;
        max-width: 620px;
        min-height: 372px;
        display: flex;
        .page_row{
            flex-wrap: unset;
            height: 100%;
            .inner_section {
                display: flex;
                align-items: start;
                height: 100%;
                background: $colorTextInverted;
                border-radius: 30px;
                padding: 40px 64px;
                border: 1px solid $colorBackgroundSurfaceStroke;
                .left_block {
                    display: flex;
                    justify-content: flex-end;
                    align-items: start;
                    height: 100%;
                    min-width: 145px;
                    video,
                    img {
                        display: block;
                        height: auto;
                        max-width: 100%;
                        //max-height: 100%;
                        border-radius: 20px;
                        max-height: 547px;
                    }

                }
                .right_block {
                    min-width: 320px;
                    a,button{
                        margin: 24px 0;
                    }
                    .loading_summary {
                        font-size: $fontSize14;
                        line-height: 20px;
                        color: $colorTextSecondary;
                    }
                }

            }

        }



        .loading_description {
            //color: $colorTextSecondary;
            @include mediaFrom($size960) {
                margin-top: 20px;
            }
            @include mediaTo($size960) {
                margin-top: 10px;
            }
        }

        .primary_btn {
            @include mediaFrom($size960) {
                margin: 40px 0 20px;
            }
            @include mediaTo($size960) {
                margin: 20px 0 10px;
            }
            @include mediaTo($size768) {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 20px auto;
                width: max-content;
            }
        }
    }

    //right_section

    //full_section
    .full_section {
        width: 100%;
        background: $colorTextInverted;
        margin-bottom: 90px;
        position: relative;
        z-index: 1;
        @include mediaFrom($size960) {
            padding-bottom: 100px;
        }

        @include mediaRange($size768, $size960) {
            padding-bottom: 80px;
        }

        @include mediaTo($size768) {
            padding-bottom: 60px;
        }

        .page_row {
            @include mediaFrom($size960) {
                padding: 80px 0;
            }

            @include mediaRange($size768, $size960) {
                padding: 60px 0;
            }

            @include mediaTo($size768) {
                padding: 20px 0;
            }
        }

        .left_block {
            padding-right: 70px;
            align-self: center;
            @include colSize(5);
            @include mediaTo($size960) {
                padding: 0 $colPadding;
                @include colSize(7);
            }

            @include mediaTo($size768) {
                @include colSize(12);
                margin-bottom: 20px;
            }
        }

        .right_block {
            @include colSize(7);
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            @include mediaTo($size960) {
                @include colSize(5);
                align-items: center;
                justify-content: center;
            }

            @include mediaTo($size768) {
                @include colSize(12);
                align-items: center;
                justify-content: center;
            }

            img {
                display: block;
                height: auto;
                max-width: 100%;
                max-height: 100%;
            }
        }

        .loading_description {
            color: $gray66;
            @include mediaFrom($size960) {
                margin-top: 20px;
            }
            @include mediaTo($size960) {
                margin-top: 10px;
            }
        }

        .primary_btn {
            @include mediaFrom($size960) {
                margin: 40px 0 20px;
            }
            @include mediaTo($size960) {
                margin: 20px 0 10px;
            }
            @include mediaTo($size768) {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 20px auto;
                width: max-content;
            }
        }
    }

    //full_section

    //device_trusted
    .device_trusted {
        margin: 40px 0;
        width: 100%;

        position: relative;
        z-index: 1;
            .page_row{
                justify-content: space-between;
                .inner_section {
                    height: 100%;
                    padding: 64px;
                    background: $colorTextInverted;
                    box-shadow: 0 4px 24px rgba(19, 69, 98, 0.1);
                    border-radius: 30px;
                    display: flex;
                    justify-content: space-between;
            }
        }

        .left_block {
            @include colSize(6);
            align-self: center;
            .loading_description{
                color: $colorTextPrimary;
            }
        }

        .right_block {
            @include colSize(6);
            display: flex;
            justify-content: flex-end;
            align-items: center;
            @include mediaFrom($size960) {
                align-items: flex-end;
            }


            img {
                display: block;
                height: auto;
                max-width: 100%;
                max-height: 100%;
                border-radius: 0 30px 30px 0;
                @include mediaTo($size768) {
                    border-radius: 30px;
                }
            }
        }

        .loading_description {
            color: $gray66;
            @include mediaFrom($size960) {
                margin-top: 20px;
            }
            @include mediaTo($size960) {
                margin-top: 10px;
            }
        }

        .primary_btn {
            @include mediaFrom($size960) {
                margin: 40px 0 20px;
            }
            @include mediaTo($size960) {
                margin: 20px 0 10px;
            }
            @include mediaTo($size768) {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 20px auto;
                width: max-content;
            }
        }
    }


    .section_full{
        width: 100%;
        max-width: 100%;
        margin-top: 40px;
        .inner_section{
            align-items: start;
            background: $colorTextInverted;
            border: 1px solid $colorBackgroundSurfaceStroke;
            border-radius: 30px;
            display: flex;
            height: 100%;
            padding: 40px 64px;
            width: 100%;
            justify-content: space-evenly;
            .secondary-button{
                margin: 24px 0;
            }
          .left_block{
            min-width: 340px;
          }
        }
    }
    //device_trusted

    //landing_banner
    .landing_banner {
        width: 100%;

        margin: 40px 0;
        .home_banner_inner {
            position: relative;
            display: flex;
            width: 100%;
            justify-content: space-between;
            // margin-bottom: 70px;
            padding: 64px;
            background: $colorBackgroundSurfaceSecondary;
            border: 1px solid $colorBackgroundSurfaceStroke;
            border-radius: $borderRadius20;
            .banner_img {
                @include colSize(7);
                min-height: 300px;
                display: flex;
                position: relative;
                overflow: hidden;
                border-radius: 30px;
                padding: 20px;
                justify-content: end;
                align-items: center;
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            .banner_info {
                z-index: 2;
                display: flex;
                justify-content: center;
                flex-direction: column;
                @include colSize(5)
            }

            .section_title {
                color:  $colorTextPrimary;
                max-width: 540px;
                @include textOverflow(2, relative);
                @include mediaTo($size768) {
                    -webkit-line-clamp: inherit;
                }
            }

            .banner_description {
                @include standardText;
                max-width: 540px;
                color: $colorTextPrimary;
                @include textOverflow(3, relative);
                @include mediaTo($size768) {
                    -webkit-line-clamp: inherit;
                }

                p {
                    margin: 0;
                    padding: 0;
                    @include textOverflow(3, relative);
                    @include mediaTo($size768) {
                        -webkit-line-clamp: inherit;
                    }
                }
            }

            .primary_btn {
                align-self: flex-start;
                margin-top: 24px;
            }
        }

        @include mediaRange($size960, $size1200) {
            .home_banner_inner {
                .banner_info {
                    padding: 0 48px;
                }
            }

        }
        @include mediaRange($size576, $size960) {
            margin-bottom: 60px;
            .home_banner_inner {
                .banner_info {
                    padding: 0 32px;
                }

                .banner_img {
                    border-radius:$borderRadius16;
                    min-height: 270px;
                }
            }
        }
        @include mediaTo($size576) {
            margin-bottom: 40px;
            .home_banner_inner {
                .banner_img {
                    border-radius: $borderRadius12;
                    min-height: 240px;
                }
            }
        }
    }

    //landing_banner

    //cards_section
    .cards_section {
        width: 100%;
        margin: 40px 0;
        padding: 56px 0;
        background: $colorConstantGrey50;
        position: relative;

        > .page_container {
            position: relative;
            z-index: 2;
        }

        .slick-dots {
            @include standardList;
            display: flex;
            justify-content: center;
            position: relative;
            bottom: unset;
            margin-bottom: 10px;

            li {
                padding-top: 24px;
                margin: 0 5px;

                &.slick-active button {
                    border-width: 3px;
                    background: $siteColor;
                    pointer-events: none;
                }
            }

            button {
                width: 18px;
                height: 18px;
                font-size: 0;
                line-height: 0;
                border: 4px solid $colorTextInverted;
                background: $grayE9;
                border-radius: 50%;
                padding: 0;

                &:before {
                    display: none;
                    pointer-events: none;
                    overflow: hidden;
                    height: 0;
                }
            }

            @include mediaTo($size960) {
                li {
                    padding-top: 12px;

                    &.slick-active button {
                        border-width: 6px;
                    }
                }
                button {
                    width: 24px;
                    height: 24px;
                    border-width: 7px;
                }
            }
        }

        .section_head {
            justify-content: center;
            text-align: center;
            max-width: 1005px;
            margin: 0 auto;
            flex-direction: column;
        }

        .section_title {
            margin-bottom: 32px;

            @include mediaFrom($size991) {
                margin-bottom: 24px;
            }

        }

        .landing_title {
            @include mediaFrom($size960) {
                padding: 30px 20px 15px;
            }

            @include mediaRange($size768, $size960) {
                padding: 20px 20px 15px;
            }

            @include mediaTo($size768) {
                padding: 10px 20px 15px;
            }
        }
        .product_card{
            .slick-list{
                .slick-track{
                    display: flex;
                    gap: 32px;
                }
            }
        }



        .section_img {
            display: block;
            flex: 1 1;
            position: relative;
            border-radius: $borderRadius20;
            img {
                border-radius: $borderRadius20;
                display: block;
                height: auto;
                width: 100%;
            }
        }


        .inner_slider {
            background: $colorTextInverted;
            box-shadow: 0 4px 24px rgba(19, 69, 98, 0.1);
            border-radius: $borderRadius20;
            text-align: center;
        }

        .primary_btn {
            @include mediaFrom($size960) {
                margin: 30px 0 40px;
            }
            @include mediaTo($size960) {
                margin: 20px 0 20px;
            }
        }
        .primary_button{
            margin-top: 40px!important;
        }
    }

    //cards_section

    .home_services {
        width: 100%;
        padding: 0;
        background: $colorTextInverted;
        margin: 40px 0;
         .section_head{
          padding: 0;
        }
        .services_wrapper{
            display: flex;
            gap: 26px;
            @include mediaTo($size991) {
                gap: 12px;
            }
            .service_block{
                display: flex;
                padding: 32px 24px;
                border-radius: $borderRadius20;
                background: $colorTextInverted;
                border: 1px solid $colorBackgroundSurfaceStroke;
                max-width: 378px;
                width: 100%;
                .inner_service{
                    display: flex;
                    gap: 8px;

                    .service_img{
                        width: 28px;
                        height: 20px;
                        margin-top: 2px;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .service_info {
                        display: flex;
                        flex-direction: column;
                        .service_title {
                            color: $colorTextPrimary;
                            font-size: $fontSize16;
                            line-height: 22px;
                            font-family: "NotoSansArmenian-SemiBold", sans-serif;
                            margin-bottom: 8px;
                        }

                        .service_summary {
                            font-size: $fontSize14;
                            line-height: 20px;
                            color: $colorTextSecondary;

                        }
                    }
                }
            }
        }
    }

    //banner_large
    .banner_large {
        text-align: center;
        padding: 64px;
        width: 100%;
        border-radius: $borderRadius12;
        background: $colorBrandDarkblue;
        margin: 40px 0 80px;
        background-repeat: no-repeat;
        overflow: hidden;
        background-position: right;
        background-size: 36% 100%;
        @include mediaRange($size768, $size960) {
            margin-top: 70px;
        }

        @include mediaTo($size768) {
            margin-top: 50px;
            margin-bottom: 50px;
        }

        .page_title {
            color: $colorTextInverted;
            margin-bottom: 16px;
            text-align: center;
            @include mediaTo($size991) {
                text-align: start;
            }
            @include mediaTo($size768) {
                margin-bottom: 15px;
            }
        }

        .banner_description {
            @include subtitleSize;
            text-align: center;
            max-width: 794px;
            margin: 0 auto;
            color: $colorTextInverted;
            @include mediaTo($size991) {
                text-align: start;
            }
        }

        .page_row {
            gap: 24px;
            margin-top: 32px;
            justify-content: center;
            @include mediaTo($size768) {
                margin-top: 25px;
            }
        }
            .store_wrapper{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 24px;
                flex-wrap: wrap;
                margin-top: 32px;
                @include mediaTo($size991) {
                    justify-content: start;
                    gap: 16px;
                    margin-top: 24px;
                }
            }
    }

    //banner_large

    //landing_form
    .landing_form {
        width: 100%;
        margin: 40px 0;

        .page_row{
            @include mediaTo($size991) {
              flex-direction: column-reverse;
                justify-content: center;
                align-items: center;
                .right_block{
                    margin: unset;
                }
                .left_block{
                    margin: 0;
                    padding: 0 ;
                }
            }
            .landing_body{
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                background: $colorBackgroundSurfaceSecondary;
                border-radius: 20px;
                padding: 0;
                .left_block {
                    width: 100%;
                    padding: 68px 0 68px 65px;
                    @include colSize(6);
                    align-self: center;
                    @include mediaTo($size960) {
                        @include colSize(12);
                    }
                    .field_block{
                        margin-bottom: 0;
                    }
                }

                .right_block {
                    height: 355px;
                    @include colSize(6);
                    @include mediaTo($size720) {
                        @include colSize(8);

                    }
                    @include mediaTo($size640) {
                        @include colSize(12);

                    }
                    margin-left: auto;
                    margin-top: auto;

                    img {
                        display: block;
                        height: auto;
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                @include mediaTo($size991) {
                    flex-direction: column-reverse;
                    justify-content: center;
                    padding: 24px 16px;
                    .left_block {
                        padding: 0;
                        margin-top: 32px;
                    }
                    .right_block{
                        margin-left: unset;

                    }
                }
            }
        }


        .section_title {
            margin-bottom: 16px;
            @include mediaTo($size768) {
                margin-bottom: 10px;
            }
        }

        .field_inner {
            display: flex;

            align-items: center;
            position: relative;
            max-width: 400px;

        }

        .subscribe_form {
            width: 100%;
            position: relative;
            margin-top: 24px;

            label {
                width: 100%;
                margin-right: 12px;

            }

            .label {
                font-size: 120%;
                line-height: 15px;
                margin-bottom: 8px;
                width: 100%;

            }


            input {
                height: 48px;
                width: 100%;
                padding: 13px;
                color: $colorTextSecondary;
                font-size: 16px;
                line-height: 18px;
                background: $colorTextInverted;
                border: 1px solid $colorControlBorderDefault;
                border-radius: 10px;
                &::placeholder {
                    color: $black33;
                    font-size: 85%;
                }
            }
            .subscribe_button{
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 15.9px;
                width: max-content!important;
                svg{
                    margin: 0;
                }
            }
        }
    }

    //landing_form

}