@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.banner-wrapper {
  position: relative;

  img {
    max-height: 42vw;
    object-fit: cover;
  }

  .banner-info-wrapper {
    position: absolute;
    top: 25%;
    left: 8.5%;
    color: white;
    z-index: 1;
    max-width: 757px;

    .banner-title {
      @include subtitleSize;
      margin-bottom: 32px;
    }

    .banner-summary {
      @include pageTitleSize;
      margin-bottom: 16px;
    }

    .short-description {
      @include subtitleSize;
      margin-bottom: 32px;

    }

    @media (max-width: 991px) {
      .medium.button {
        width: max-content !important;
      }
    }
  }

  &:after {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    background: #00000080;
  }
}
