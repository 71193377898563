@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.accounts-page,.accounts-child-page{
  .advantages-wrapper {
    gap: 32px;
    flex-wrap: wrap;
    margin-top: 80px;
    .advantage {
      flex-direction: column;
      border: 1px solid $colorBackgroundSurfaceStroke;
      border-radius: 20px;
      padding: 32px;
      gap: 16px;
      width: calc(25% - 24px);
      min-width: 260px;
      .title {
        p {
          margin: 0;
        }

        @include titleSize20;
        font-family: "NotoSansArmenian-SemiBold", sans-serif;
        color: $colorTextPrimary;
      }

      .summary {
        p {
          margin: 0;
        }

        @include standardText;

      }
      img{
        width:40px;
        height: 40px;
        object-fit: cover;
      }
    }

  }
  @media (max-width: 991px) {
    .advantages_block_sk li{
      display: none;
      &:first-child,&:last-child{
        display: block;
        width: 100%;
      }
    }
    .advantage{ width: calc(50% - 24px);}
  }
  @media (max-width: 610px) {
    .advantage{ width: 90%;margin: 0 auto}
  }
}

.accounts-page{
  .dynamic_section .template22:nth-child(odd) {
    margin: 0 auto 32px 16px;
  }
  .dynamic_section .template22:nth-child(even) {
    margin: 0 16px 32px  auto;
  }
}

.accounts-child-page{
  .dynamic_section .template22:nth-child(even) {
    margin: 0 auto 32px 16px;
  }
  .dynamic_section .template22:nth-child(odd) {
    margin: 0 16px 32px  auto;
  }
}

.accounts-page {

  .dynamic_section {
    .default:first-child {
      .content-wrapper {
        padding: 0;
        align-items: end;

        .left_block {
          padding: 64px;
        }

        .right_block {
          align-items: end;
          border-radius: 12px;
          display: flex;
          max-width: 520px;
          overflow: hidden;
          position: relative;
          width: 100%;
          padding: 0;

          img {
            border-radius: 20px;
            height: 300px;
            object-fit: cover;
            width: 100%;
          }
        }
      }
    }

    .template6 {
      .page_container {
        .inner_section {
          margin: 0 auto 40px;
          .right_block{
            @media (max-width: 991px) {
              width: 100%;
            }
          }
        }
      }
    }

    .template5, .template6 {
      padding: 40px 0;
      background: $colorConstantGrey50;
      margin: 0;

      .page_container {
        max-width: unset;

        .inner_section {
          max-width: 1240px;
        }
      }
    }

    .template5 {
      .page_container {
        .inner_section {
          max-width: 620px;
          @media (max-width: 991px) {
            max-width: unset;
          }
        }
      }

      &:nth-child(even) {
        .page_container {
          .page_row.hasTitle {
            flex-direction: column;
            align-items: flex-end;

            .global-title {
              width: 100%;
              max-width: 620px;
              @media (max-width: 991px) {
              max-width: unset;
              text-align: center;
            }
            }
          }
        }
      }

      &:nth-child(odd) {
        .page_container {
          .page_row {
            padding-top: 56px;
            @media (max-width: 991px) {
              padding-top: 0;

            }
          }
        }
      }
    }

  }
}

.accounts-child-page {
  .banner-wrapper {
    position: relative;

    img {
      max-height: 42vw;
      object-fit: cover;
    }

    .banner-info-wrapper {
      position: absolute;
      top: 25%;
      left: 8.5%;
      color: white;
      z-index: 1;
      max-width: 757px;

      .banner-title {
        @include subtitleSize;
        margin-bottom: 32px;
      }

      .banner-summary {
        @include pageTitleSize;
        margin-bottom: 16px;
      }

      .short-description {
        @include subtitleSize;
        margin-bottom: 32px;

      }

      @media (max-width: 991px) {
        .medium.button {
          width: max-content !important;
        }
      }
    }

    &:after {
      width: 100%;
      height: 100%;
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      background: #00000080;
    }
  }

  .scrollable-tabs {

    .tab-buttons {
      gap: 24px;
      row-gap: 26px;
      flex-wrap: wrap;
      box-shadow: 0 4px 16px 0 #0D2B9614;
      padding: 16px 32px;
      display: flex;
      margin-bottom: 80px;
      border-radius: $borderRadius12;
        a {
          display: block;
          padding: 8px 0;
          color: $colorTextPrimary;
          border: none;
          background: unset;
          position: relative;
          transition: 0.3s ease-out;
          @include mediumText;

          &:after {
            transition: 0.3s ease-out;
            position: absolute;
            width: 100%;
            bottom: -4px;
            height: 2px;
            content: '';
            background: white;
            left: 0;
          }

          &.active, &:hover {
            color: $colorTextPrimary;

            &:after {
              background: $colorControlTabPressed;
            }
          }

        }

    }


    .tab-list-wrapper {
      .tab-list {
        .title-wrapper {
          align-items: center;
          gap: 16px;
          margin-bottom: 32px;

          .title {
            @include mediumText;
            font-family: "NotoSansArmenian-SemiBold", sans-serif;
            color: $colorTextPrimary;
          }

        }

        .text {
          @include mediumText;
          color: $colorTextSecondary;
          margin-bottom: 24px;

          p {
            margin: 0;
          }
        }
        table{
          @include tableDesign;
        }

        .files {
          gap: 24px;

          .file {
            width: 100%;
          }
        }

      }

    }
  }
}

.accounts-child-page {
  .account-tabs{
    .title{
      text-align: center;
      margin-bottom: 24px;
    }
  }
  .important-documents-wrapper{
    margin: 80px 0;
    .title{
      text-align: center;
      margin-bottom: 32px;

    }
    .documents{
      justify-content: center;
      gap: 32px;

      .doc{
        border-radius: $borderRadius20;
        box-shadow: 0 4px 16px 0 #0D2B9614;
        padding: 24px 32px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: center;
        align-items: center;
        max-width: 275px;
        text-align: center;
        span{
          @include subtitleSize;
          color: $colorTextPrimary;
          font-family: "NotoSansArmenian-SemiBold", sans-serif;

        }
      }
      @media (max-width: 768px) {
        flex-wrap: wrap;
        .doc{
          width: 100%;
          max-width: unset
        ;
        }
      }
    }
  }
}

.dynamic-section-sk{
  li{
    height: 300px;
  }
}
.tabs-sk{
  li{
    &:first-child{
      height: 40px;
    }
    height: 300px;
  }
}
.important-documents-sk{
  display: flex;
  gap: 32px!important;
  flex-wrap: nowrap!important;
  justify-content: center!important;
    li{
    width: calc(25% - 16px) !important;
    height: 170px;
  }
}
@media (max-width: 991px) {
 .template22.hasTitle{
   width: 100%;
 }
}