@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.menu_sub {
  z-index: 9999;
  position: absolute;
  background-color: aliceblue;
  padding: 30px;
}

.menu_sub_1 {
  position: absolute;
  background-color: rgba(155, 155, 155);
  padding: 30px;
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
}

.menu_sub_2 {
  z-index: 9999;
  position: absolute;
  background-color: rgb(252, 255, 251);
  padding: 30px;
  margin-left: 50px;
}

.submenu_item{
  font-size: 13px;
  &:first-of-type{
    @include standardText;
  }
  p{
    margin: 0;
    padding: 0;
  }
}
.submenu_list {
  max-width: 908px;
  max-height: 406px;
  justify-content: space-between;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  flex-wrap: wrap;
  width: max-content;
  column-gap: 32px;
  z-index: 111111;
  .subtitle{
    @include subtitleSize;
    font-family: "NotoSansArmenian-SemiBold", sans-serif;
    margin: 0;
    padding: 0;

  }
  //.child-wrapper{
  //  //display: grid;
  //  //width: 100%;
  //  //gap: 32px;
  //  //grid-template-columns: calc(50% - 16px) calc(50% - 16px);
  //  display: flex;
  //  flex-wrap: wrap;
  //  width: 100%;
  //  gap: 32px;
  //  flex-direction: column;
    .child-group{
      padding: 8px 0;
      .submenu_item{
        flex-direction: column;
        display: flex;
        &.subtitle{
          width: 100%;
        }
      }
      &.last{
        margin-bottom: 32px;
      }
    }

  //}
  .image-wrapper{
    max-width: 290px;
    img{
      width: 100%
    }
    @media (max-width: 1150px) {
      display: none;
    }
  }
}


/* .menu_sub_hidden {
    display: none;

} */
