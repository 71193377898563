@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.button,.button_ui{
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: $borderRadius12;
  transition: 0.3s ease-out;
  width: max-content;
  border: 3px solid transparent;
  cursor: pointer;
  height: max-content;
  &:hover,&:focus{
    transition:0.3s ease-out;
  }
  svg{
    margin-right: 8px;
  }
}
.bold_nono{
  font-family: 'NotoSansArmenian-Bold' ,sans-serif;
}
.semi_bold{
  font-family: 'NotoSansArmenian-SemiBold' ,sans-serif;
}
.semi_regular{
  font-family: 'NotoSansArmenian-Regular' ,sans-serif;
}
.disabled{
  background: $colorControlDisabled;
  color: $colorTextTertiary;
}

.is-loading.small{
  svg{
    width: 16.7px;
    height: 16.7px;
  }
}

.is-loading{
  svg{
    animation: loading-spinner 3s linear infinite
  }
}

@keyframes loading-spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader{
  background: $colorControlPrimaryPressed;
}

.large{
  padding: 16px 24px 16px 24px;
  font-size: 18px;
  line-height: 24px;
  border-radius: $borderRadius12;
  min-width:138px;
  min-height: 56px
}

.medium{
  padding: 13px 24px 13px 24px;
  font-size: 16px;
  line-height: 22px;
  border-radius: $borderRadius12;
  border: none;
}

.small-medium{
  padding: 8px 12px ;
  font-size: 14px;
  line-height: 20px;
  border-radius: $borderRadius10;
  border: none;
  background: transparent;
}

.small{
  padding: 6px 16px;
  font-size: 14px;
  line-height: 20px;
  border-radius: $borderRadius8;
  min-width: 97px;
  min-height: 32px
}

.primary_button{
  background: $colorControlPrimaryDefault;
  color: $colorConstantGrey0;
  &:hover{
    background: $colorControlPrimaryHover;
  }
  &:active{
    background: $colorControlPrimaryPressed;
  }
  &:focus{
    border: 3px solid $colorControlBorderFocus;
  }
}
.secondary-button{
  background: $colorControlSecondaryDefault;
  color: $colorTextPrimary;
  &:hover{
    background: $colorControlSecondaryHover;
  }
  &:active{
    background: $colorControlSecondaryPressed;
  }
  &:focus{
    border: 3px solid $colorControlBorderFocus;
  }
}
.icon-button{
  background: $colorControlSecondaryDefault;
  color: $colorTextPrimary;
  &:hover{
    background: $colorControlSecondaryHover;
  }
  &:active{
    background: $colorControlSecondaryPressed;
  }

}
.inverted-button{
  background: $colorControlInvertedDefault;
  color: $colorTextPrimary;
  &:hover{
    background: $colorControlInvertedHover;
  }
  &:active{
    background: $colorControlInvertedActive;
  }
  &:focus{
    border: 3px solid $colorControlBorderFocus;
  }
}
.outline-button{
  color: $colorTextPrimary;
  border: 1px solid $colorControlBorderDefault;
  svg{
    path{
      stroke: $colorTextPrimary;

    }
  }
  &:focus{
    border: 3px solid $colorControlBorderFocus;
  }
}
.black-outline-button{
  color: $colorTextPrimary;
  border: 1px solid $backgroundBlack;
  svg{
    path{
      stroke: $colorTextPrimary;

    }
  }
  &:focus{
    border: 3px solid $colorControlBorderFocus;
  }
}
.white-outline-button{
  background: $colorTextPrimary;
  border: 1px solid $backgroundWhite;
  color: $colorTextInverted;
  svg{
    path{
      stroke: $colorTextPrimary;

    }
  }
  &:focus{
    border: 3px solid $colorControlBorderFocus;
  }
}
.black-button{
  background: $colorTextPrimary;
  color: white;
}
.white-button{
  background: white;
  color: $colorTextPrimary;
  border: 1px solid $colorIconsPrimary;
}
.grey-outline-button{
  background: white;
  color: $colorTextSecondary;
  border: 1px solid $colorControlBorderDefault;
}
.transparent-button{
  background: transparent;
  color: $colorTextPrimary;
}
.transparent-button-blue{
  background: transparent;
  color: $colorTextLinkDefault;
}

