@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';


.page_container.small_container{
  max-width: 1038px;
  .page_row {
    margin-left: -20px;
    margin-right: -20px;
  }
}
.about-page{
  background: #f2f2f2;
  .breadcrumbs{
    background: #f2f2f2;
    padding: 20px 36px!important;
    margin: unset!important;
    li{
      &:nth-child(2){
        a {
          color: #666666FF;
        }
      }
    }
    a {
      color: #999999;
    }
  }
  .top-title{
    text-align: start;
    font-family: "NotoSansArmenian-SemiBold", sans-serif;
    @include pageTitleSize;
    color: #070B11;
  }
  .chakra-tabs__tab-panel{
    outline: unset;}
  .tab-buttons{
    gap: 4px;
    margin-bottom: 32px;
    button{
      padding: 10px 12px;
      border-radius: $borderRadius12;
      background: transparent;
      transition: 0.3s ease-out;
      border: none;
      color: #677482;
      a{
        color: #677482;
        width: max-content;
      }
      @include standardText;
      &[aria-selected="true"]{
      background: $colorControlTagActive;
        a{
          color: $colorTextPrimary;
        }
      }
      &:hover{
      background: $colorControlTagActive;
      color: $colorTextPrimary;
        a{
          color: $colorTextPrimary;
        }
      }
    }
  }
  .banner-wrapper{
    position: relative;
    img{
      max-height: 42vw;
      object-fit: cover;
    }
    .banner-info-wrapper{
      position: absolute;
      top: 25%;
      left: 8.5%;
      color: white;
      z-index: 1;
      max-width: 757px;
      .banner-title{
        @include  subtitleSize;
        margin-bottom: 32px;
      }
      .banner-summary{
        @include  pageTitleSize;
        margin-bottom: 16px;
      }

      .short-description{
        @include subtitleSize;
        margin-bottom: 32px;

      }
      @media (max-width: 991px) {
        .medium.button {
          width: max-content !important;
        }
      }
    }
  }
  .page-info-wrapper{
    background: $creamyF2;
    padding: 40px 0 56px;
    .page-info-block{
      gap: 32px;
      display: flex;
      .dynamic_section {
        .loading_description{
          color: $colorTextSecondary;
          p{
            margin: 0;
            padding: 0;
            margin-bottom: 12px;
             &:last-child{
               margin-bottom: 0;
             }
          }
        }
        .template6 .inner_section {
          padding: 40px 0;
          flex-direction: row-reverse;
          background: transparent;
          border: none;
          gap: 32px;

          .section_title {
            margin-bottom: 32px;
            @include titleSize22;
          }
        }
      }
      .page-info{
        h2{
          color: $colorTextPrimary;
        }
        width: 50%;
        color: $colorTextSecondary;
        @include subtitleSize;
      }
      img{
        border-radius: $borderRadius20;
        max-height: 370px;
        object-fit: cover;
      }
    }

    .additional-info-wrapper{
      background: white;
      padding: 48px;
      position: relative;
      border-radius: $borderRadius12;
      color: $colorTextPrimary;
      margin-top: 40px;
      .additional-info{
        @include subtitleSize;
        p{
          margin: 0;
        }

      }
      .quote-top{
        position: absolute;
        top: 30px;
        left: 30px;
        transform: rotate(180deg);
      }
      .quote-bottom{
        position: absolute;
        bottom: 30px;
        right: 30px;
      }
    }
  }
  .footer-wrapper{
    padding: 140px 0;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .lr-wrapper{
      display: flex;
      gap: 32px;
      max-width: 993px;
      margin: 0 auto;
      align-items: center;
      .left-wrapper,.right-wrapper{
        >.title{
          @include  sectionMediumTitleSize;
          font-family: "NotoSansArmenian-SemiBold", sans-serif;
          color: white;
          margin-bottom: 16px;
        }
        >.text{
          @include  smallText;
          color: $backgroundHeaderLine
        }
      }
      .right-wrapper{
        h1,h2,h3,h4,h5{
          @include  pageTitleSize;
          color: white;
        }
        p{
          @include standardNarrow;
          color: $colorControlSecondaryPressed;
        }

      }
      .left-wrapper{
        border-radius: $borderRadius20;
        max-width: 380px;
        background: linear-gradient(180deg, rgba(7, 11, 17, 0.5) 0%, rgba(7, 11, 17, 0.5) 100%);
        border: 1px solid #383838CC;
        backdrop-filter: blur(104px);
        padding: 24px;
        width: 100%;
        .files{
          margin-top: 16px;
          border:unset;
          border-radius:unset;
          gap:16px;
          padding: unset;
          .file{
            width: unset;
            background: #12181F;
            a{
              width: max-content;
            }
            .title{
              color: white;
            }
            .text{
              color: $backgroundHeaderLine
            }
            svg:last-child{
              path{
                stroke: #6685FF;
              }
            }
          }
        }
        >.title{
          @include  mediumText;
        }
      }
    }

  }
  .bank-services-wrapper{

    padding: 80px 0 ;
    .bank-services{
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      .service{
        background: white;
        padding: 35px;
        border-radius: $borderRadius20;
        width: calc(32.3% - 16px);
        min-width: 311px;
        @include mediumText;
        .title-wrapper{
          display: flex;
          flex-direction: column;
          gap: 16px;
          margin-bottom: 32px;
          font-family: "NotoSansArmenian-SemiBold", sans-serif;
          .title{
            @include subtitleSize;
            color: $colorTextPrimary;
          }
        }
        .text{
          color: $colorTextSecondary;
          ul{
            padding: 0 0 0 18px;
          }
        }
        img{
          width: 33px;
        }


      }
    }
  }


  .bank-persons-tab{
    padding-bottom: 120px;
    .bank_persons_slider{
      margin-bottom: 16px;
      .slick-dots{
        bottom: -41px;
        .slick-active{
          button{
            &:before{
              color: #2400FF;
              opacity: 1;
            }
          }
        }
        button{

          &:before{
            transition: 0.3s ease-out;
            font-size: 20px;
          }
          &:hover{
            &:before{
              color: #2400FF;
            }
          }
        }
      }
    }
    .top-title{
      text-align: center;
    }
    .top-text{
      @include subtitleSize;
      text-align: center;
      max-width: 585px;
      margin: 24px auto;
    }
    .tab-buttons{
      margin: 0 auto 24px;
      button{
        border-radius: 0;
        transition: 0.3s ease-out;
        background: transparent;
        border-bottom: 2px solid transparent;
        &[aria-selected="true"],&:hover{
          border-bottom: 2px solid #3F00FF;
          font-weight: unset;
        }
      }
    }
    .chakra-tabs__tab-panels{
    .chakra-tabs__tab-panel{
      outline: unset;
      .bank-persons-wrapper{
        //background: #EFF1FA;
        padding: 56px 0;
        .bank-persons{
          //display: flex;
          //gap: 32px;
          //flex-wrap: wrap;
          .short_slider {
            .slick-track{
              justify-content: center;
            }
          }
          .slick-track{
            display: flex;
            align-items: center;
            //justify-content: center;
            .slick-slide{
              padding: 0 20px;
              text-align: center;
              width: max-content!important;
              .bank-person{
                cursor: pointer;
                border-radius: 24px;
                background: #2400FF;
                width: 300px!important;
                min-height: 400px;
                display: inline-flex!important;
                align-items: end;
                justify-content: center;
                //padding-bottom: 26px;
                img{
                  width: 100%;
                  object-fit: contain;
                  border-radius: 24px;
                  max-height: 390px;
                }
                .title{
                  font-size: 20px;
                  font-family: "NotoSansArmenian-SemiBold", sans-serif;
                  line-height: 26px;
                  letter-spacing: -0.0045em;
                  text-align: left;
                  color: #070B11;
                  margin-bottom: 8px;
                  padding-left: 16px;
                }
                .text{
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 22px;
                  text-align: left;
                  color: #666666;
                  padding-left: 16px;
                }
              }

            }
          }
          .popup_close{
            top: 10px;
            right: 10px;
          }
            .bank-person-modal{
              .popup_container{
                max-width: unset;
                width: unset;
              .person-info-wrapper{
                gap: 30px;
                .position{
                  @include  smallText;
                  color: $colorTextSecondary;
                  margin-bottom: 8px;
                }
                .name{
                  @include  subtitleText;
                  color: $colorTextPrimary;
                  margin-bottom: 32px;
                  font-family: "NotoSansArmenian-SemiBold", sans-serif;
                }
                .info-wrapper{
                  width: max-content;
                  min-width: 20vw;
                  max-width: 480px;
                  flex-direction: column;

                  .info{
                    max-height: 305px;
                    overflow-y: scroll;
                    &::-webkit-scrollbar {
                      width: 6px;
                    }
                    &::-webkit-scrollbar-thumb {
                      border-radius: 100px;
                      background: #95A4B4;
                    }
                    &::selection {
                      color: white;
                      background: #3F00FF;
                    }
                    @include  standardNarrow;
                  }
                }
                .image-wrapper{
                  .position,.name{
                    display: none;
                  }
                  img{
                    background: #3F00FF;
                    border-radius: 20px;
                    max-height: 390px;
                  }
                }
              }

            }
          }
        }
      }

    }

    }

  }
  .bank-history-tab{
    .bank-history-slider{
      background: $colorBrandDarkblue;
      padding: 12px 0 64px;

      position: relative;
      color: white;

      .bank-history-wrapper{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        min-height: 250px;

        .info-wrapper{
          gap: 60px;
          display: flex;
          justify-content: space-between;
          margin-top: 40px;
          .buttons-wrapper{
            button {
              width: 21px;
              height: 21px;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: 0.3s ease-out;
              background: transparent;
              border: 0;
              padding: 0;

              svg {
                margin-right: 0;
                transition: 0.3s ease-out;
                path{
                  stroke: white;
                }
              }
            }
              &:last-child{
                button{
              &:hover{

                  svg{
                    transform: translateX(10px);
                  }
                }
                }


              }
              &:first-child{
                button{
                  justify-content: flex-start;

                &:hover{

                  svg{
                    transform: translateX(-10px);
                  }
                  }
                }
            }
          }
          .title-files{
            width: 50%;
            display: flex;
            flex-direction: column;
            height: fit-content;
            gap: 16px;
            .year-buttons{
              display: flex;
              align-items: center;
              gap: 16px;
              .active-year{
                font-size: 52px;
                font-family: "NotoSansArmenian-SemiBold", sans-serif;
                line-height: 58px;
              }
            }
            .history-title{
              display: block;
              color: white;
              @include subtitleSize;
            }
          }
          .files{
            flex-direction: column;
            width: 100%;
            .file{
              width: 100%;
            }
          }
        }

        .bank-history-slider-wrapper{
          transition: 0.3s ease-out;
          width: 50%;
          position: relative;
          .item{
            user-select: none;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            cursor: pointer;
            display: none;
            position: relative;
            .summary{
              @include subtitleText;
              font-family: "NotoSansArmenian-SemiBold", sans-serif;
            }
            .info{
              //max-height: 0; overflow: hidden;
              //transition: max-height 0.3s ease;
              //overflow: hidden;
              @include standardText;
              color: $colorControlSecondaryPressed;
              p{
                margin: 0;
                transition: 0.3s ease-out;
              }
              &.expanded {
                max-height: 500px;
              }
              ul{
                border: none;
                color: unset;
                padding: unset;
                li{
                  width: unset;
                  a{
                    color: white;
                    text-decoration: underline;
                  }
                }
              }
            }
            &:first-child{
              display: block;
            }

          }

          .bank_history_slider{
            .item{
              display: flex;
              flex-direction: column;
              gap: 32px;
              .summary{
                @include subtitleText;
                font-family: "NotoSansArmenian-SemiBold", sans-serif;
              }
              .info{
                @include standardText;
                color: $colorControlSecondaryPressed;
              }
            }
          }
        }
      }
      .years{
        display: flex;
        justify-content: space-between;
        margin: 0;
        position: relative;
        overflow: hidden;
        transition: margin-left 0.5s ease-in-out;
        cursor: pointer;

        .year{
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          display: flex;
          float: left;
          flex-direction: column;
          align-items: center;
          position: relative;
          gap: 12px;
          z-index: 2;
          color: #95A4B4;
          margin: 0 95px;
          @include subtitleSize;
          transition: 0.3s ease-out;
          .dot{
            width: 14px;
            height: 14px;
            border-radius: 100%;
            background: #95A4B4;
            border: 2px solid #002366;
            position: relative;

          }
          &.active,&:hover{
            color: white;
            .dot{
              background: white;
            }
          }
          &.active{
            &:before{
              position: absolute;
              content: '';
              height: 1px;
              top: 5.5px;
              width: 300px;
              z-index: -1;
              background: white;
            }
            &:after{
            }
          }
        }
        &:after{
          z-index: 1;
          content: '';
          position: absolute;
          width: calc(100% + 80px);
          height: 1px;
          background: #95A4B4;
          top: 6px;
          left: -40px;
          right: 0;
          bottom: 0;
          margin: 0 auto;
        }

      }
    }

  .files-wrapper{
    background: white;
    padding: 32px 0;
  }
  }
  .bank-shareholders-investors-tab{
    .banner-wrapper{
      background: white;
      .banner{
        background-position: 100% 100%;
        background-repeat: no-repeat;
        background-size: auto 80%;
        justify-content: space-between;
        height: 41vw;
        .image-wrapper{
          max-width: 45vw;
          width: 100%;
          text-align: end;
          img{
            //max-width: 45vw;
          }
        }
        .info-wrapper{
          max-width: 720px;
          padding-left: 8.5vw;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .head-title,.text{
            color: $colorTextSecondary;
            @include subtitleSize;
            p{
              margin: 0;
            }
          }
          .title{
            color: $colorTextPrimary;
            font-family: "NotoSansArmenian-SemiBold", sans-serif;
            @include  pageTitleSize;
            margin: 24px 0;
          }
        }
      }
      //padding-left: 123px;
    }
    .files-accordion-wrapper,.text-accordion-wrapper{
      padding: 80px 0;
    }
    .files-accordion-wrapper{
        .files{
          gap: 24px;
          flex-direction: row;
          flex-wrap: wrap;
          .file{
            max-width: 100%;
            flex: 0 0 calc(50% - 12px );
            justify-content: space-between;
            align-items: center;
            .title{
              margin-bottom: 0;
            }
          }
        }
      .chakra-accordion__panel{
        background: white;
        padding: 24px;
      }
    }
    .dynamic_section{
      background-repeat: no-repeat !important;
      overflow: hidden !important;
      background-position: right !important;
      background-size: 100% 100% !important;
      min-height: 40vw;
      height: auto;
      align-items: center;
      .content-wrapper{
        background: transparent;
        box-shadow: none;
        padding: 10vw 0;
        .right_block{
          display: none;
        }
        .left_block{
          max-width: 585px;
          .loading_summary,.loading_description{
            @include  subtitleSize;
            color: $colorTextSecondary;

          }
        }
      }
    }
  }
  .bank-shareholders-tab{
    background-repeat: no-repeat !important;
    overflow: hidden !important;
    background-position: top !important;
    background-size: 100%  !important;
    padding: 12vw 0 0;
    .capital-wrapper{
      flex-direction: column;
      gap: 10px;
      align-items: center;
      justify-content: center;
      .title,.capital{
        color: $colorTextPrimary;
        font-family: "NotoSansArmenian-SemiBold", sans-serif;
        p{
          margin: 0;
        }
      }
      .title{
        @include  mediumText;
      }
      .capital{
        font-size: 10vw;
        font-family: "NotoSansArmenian-SemiBold", sans-serif;
        line-height: 12vw;
        position: relative;
        p{
          margin: 0;
        }
        span{
          position: absolute;
          right: -50px;
          top: 0;
          color: $colorTextSecondary;
          @include  mediumText;
        }
      }
    }
    .shareholders-wrapper{
      gap: 30px;
      margin: 13vw auto 80px;
      justify-content: space-between;

      .shareholder{
      border-radius: $borderRadius20;
        padding: 32px;
        background: white;
        flex-direction: column;
        max-width: 320px;
        justify-content: space-between;
        .percent{
          font-size: 52px;
          font-family: "NotoSansArmenian-SemiBold", sans-serif;
          line-height: 58px;
          letter-spacing: 0.004em;
          color: #2400FF;
          margin-bottom: 16px;
          margin-top: 8px;
        }
        .title{
          @include mediumText;
          color: $colorTextPrimary;
          margin-bottom: 24px;
          font-family: "NotoSansArmenian-SemiBold", sans-serif;;
        }
        .text{
          @include standardText;
          color: $colorTextSecondary;
          margin-bottom: 24px;
          p{
            margin:0;
          }
        }
        button{
          color: $colorTextLinkDefault;
          svg{
            path{
              stroke: $colorTextLinkDefault;
            }
          }
        }

      }
    }
    .content-title{
      @include subtitleSize;
      font-family: "NotoSansArmenian-SemiBold", sans-serif;
      color: $colorTextPrimary;
    }
    .indirectly-shareholders-wrapper{
      gap: 30px;
      margin: 32px auto 80px;
      justify-content: space-between;
      .shareholder{
      border-radius: $borderRadius20;
        padding: 32px 24px;
        background: white;
        flex-direction: column;
        max-width: 480px;
        justify-content: space-between;
        .activity-type{
          @include subtitleSize;
          color: $colorTextPrimary;
          margin-bottom: 32px;
          font-family: "NotoSansArmenian-SemiBold", sans-serif;
        }
        .title{
          @include titleSize22;
          color: $colorTextPrimary;
          font-family: "NotoSansArmenian-SemiBold", sans-serif;
          margin-bottom: 24px;
        }
        .text,.type{
          @include standardText;
          color: $colorTextSecondary;
          margin-bottom: 24px;
          p{
            margin:0;
          }
        }
        .type{
          margin-bottom: 8px;
        }

        button{
          color: $colorTextLinkDefault;
          svg{
            path{
              stroke: $colorTextLinkDefault;
            }
          }
        }
      }
    }
    .bank-shareholders-modal{
      .shareholders-info-wrapper{
        .info-wrapper{
          flex-direction: column;
          gap: 24px;
          .desc{
            @include  mediumText;
            color: $colorTextSecondary;
            margin-bottom: 8px;
          }
          .info{
            @include  mediumText;
            font-family: "NotoSansArmenian-SemiBold", sans-serif;
            color: $colorTextPrimary;
          }
          .title{
            color: $colorTextPrimary;
            font-family: "NotoSansArmenian-SemiBold", sans-serif;
            @include  subtitleSize;
          }
        }
      }
    }
  }
  .structure-tab{
    .structure{
      gap: 80px;
      border-radius: 20px;
      //box-shadow: 0 4px 16px 0 #0D2B9614;
      padding: 24px;
      display: flex;
      flex-direction: column;
      img{
        width: 100%;
        margin: 0 auto;
      }

    }
  }
  .blocks-accordion,.files-accordion,.text-accordion{
    .chakra-accordion__item{
      margin-bottom: 24px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    .chakra-accordion__button{
      padding: 24px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      background: white;
      border-radius: $borderRadius12;
      font-family: "NotoSansArmenian-SemiBold", sans-serif;
      border: none;
      @include subtitleSize;
      &[aria-expanded="true"]{
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        position: relative;

        &:after{
          background: #CCD6E6;
        }
      }
      &:after{
        position: absolute;
        bottom: 0;
        width: calc(100% - 48px);
        height: 1px;
        background: transparent;
        content: '';
      }
    }
    .accordion-wrapper{
      padding: 24px;
      background: white;
      border-radius: $borderRadius12;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      @include mediumText;
      color: $colorTextSecondary;
      .title{
        @include  sectionMediumTitleSize;
        font-family: "NotoSansArmenian-SemiBold", sans-serif;
        color: $colorTextPrimary;
        margin-bottom: 32px;
      }
      p{
        @include mediumText;
        color: $colorTextSecondary;
        ul{
          li{
            margin-bottom: 16px;
            &:last-child{
              margin-bottom: 0;
            }
          }
        }

      }
    }

  }



  .bank-services-sk{
    content: "";
    width: 100%;
    padding: 80px 0 ;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    list-style: none;
    justify-content: space-between;
    li{
      height: 350px;
      border-radius: $borderRadius12;
      @include skeleton;
      width: calc(32.3% - 16px);
      min-width: 311px;
      @media (max-width: 991px) {
        width: 100%;
      }
    }
  }

  .sk{
    margin: 0;
    content: "";
    width: 100%;
    padding: 20px 0 ;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    list-style: none;
    justify-content: space-between;
    li{
      border-radius: $borderRadius12;
      @include skeleton;
      width: 100%;
    }
  }
  .tabs-sk{
    li{
      height: 40px;
    }
  }

  .banner-sk{
    li{
      height: 40vw;
    }
  }
  .sections-sk{
    li{
      height: 25vw;
    }
  }
  .add-sk{
    li{
      height: 15vw;
    }
  }
  .block-sk{
    li{
      width: 350px;
      height: 30vw;
    }
  }
  .text-sk{
    li{

      height: 10vw;
      &:first-child{
        height: 5vw;
      }
      @media (max-width: 991px) {
        width: 90%;
        margin: 0 auto;
      }
    }
  }
  .text-short-sk{
    li{
      width: 50%;
      margin: 0 auto;
      height: 10vw;
      &:first-child{
        height: 5vw;
      }
      @media (max-width: 991px) {
        width: 90%;
        margin: 0 auto;
      }
    }
  }
  .acc-sk{
    li{
      height: 5vw;
      @media (max-width: 991px) {
        width: 90%;
        margin: 0 auto;
      }
    }
  }
}