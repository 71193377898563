@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';
@import '../uiKit/styles/mixins';

.currency_block {
    .action_btn {
        font-size: 170%;
        font-family: $medium;
    }
    .currency_inner {
        position: absolute;
        overflow: hidden;
        top: 100%;
        margin-top: 11px;
        right: -4px;
        min-width: 440px;
        background: $white;
        border-radius: 20px;
        max-height: 0;
        opacity: 0;
        z-index: 101;
        box-shadow: 0px 4px 24px rgba($colorTextPrimary, 0.08);
        @include animStyles(max-height 0s 0.3s, opacity 0.3s);
    }
    .currency_close {
        border: none;
        padding: 16px;
        background: transparent;
        color: $gray66;
        font-size: 160%;
        margin-left: auto;
        margin-bottom: -10px;
        &:before {
            display: block;
        }
    }
    .currency_title {
        @include subtitleSize;
        font-family: $medium;
        padding: 0 26px;
        margin-bottom: 32px;
    }
    .switch_btns {
        display: flex;
        padding-bottom: 18px;
        margin: 0 26px 4px;
        button {
            display: flex;
            align-items: center;
            justify-content: start;
            width: max-content;
            min-width: 120px;
            border: none;
            background: transparent;
            @include standardNarrow;
            color: $colorTextPrimary;
            text-align: left;
            position: relative;
            padding: 6px 12px;
            border-radius: 10px;
            svg{
                margin-right: 10px;
                path{
                    stroke: $colorIconsTertiary;
                }
            }
            &.selected {
                box-shadow: $boxShadowLowest;
                pointer-events: none;
            }
        }
    }
    table {
        width: 100%;
        border-spacing: 0;
        thead {
            tr {
                justify-content: space-between;
                th {
                    padding: 16px 14px;

                    &:first-child {
                        text-align: left;
                        width: 50%;
                        flex: 0 0 40%;
                        max-width: 40%;
                    }

                    &:nth-child(2) {
                        text-align: right;
                        min-width: 80px;
                        width: max-content;
                        max-width: 120px;
                        display: flex;
                        font-family: "NotoSansMono", sans-serif;
                        justify-content: end;
                    }

                    &:last-child {
                        text-align: right;
                        min-width: 90px;
                        width: max-content;
                        max-width: 120px;
                        display: flex;
                    }
                }

            }
        }
        td{
            padding: 16px 14px;
            &:first-child {
                text-align: left;
                width: 50%;
                flex: 0 0 40%;
                max-width: 40%;
            }

            &:not(:first-child) {
                text-align: right;
                min-width: 90px;
                width: max-content;
                max-width: 120px;
                display: flex;
                font-family: "NotoSansMono", sans-serif;
                justify-content: space-between;
                letter-spacing: -1px;
            }
            &:last-child {
                text-align: right;
                min-width: 90px;
                width: max-content;
                max-width: 120px;
                display: flex;
            }

        }
        th {
            font-weight: normal;
            @include standardNarrow;
            &:nth-child(2) {
                @include mediaTo($size480) {
                    justify-content: center;

                }
            }
        }
        td {
            align-items: center;
            @include standardNarrow;
            &.asc:before {
                display: inline-block;
                content: "";
                width: 13px;
                height: 10px;
                //border-width: 12px 9px 0;
                //border-color: $colorAccentPositive transparent;
                //border-style: solid;
                vertical-align: middle;
                background-image: url("../uiKit/icons/arrow_up.png");
            }
            &.desc:before {
                display: inline-block;
                content: "";
                width: 13px;
                height: 10px;
                //border-width: 12px 9px 0;
                //border-color: $colorAccentNegative transparent;
                //border-style: solid;
                vertical-align: middle;
                background-image: url("../uiKit/icons/arrow_down.png");
            }
        }
        thead {
            box-shadow: 0 8px 12px rgba($colorTextPrimary,0.03);
            position: relative;
            z-index: 2;
            display: block;
            width: 100%;
            tr{
                display: flex;
                width: 100%;
            }
        }
        tbody {
            display: block;
            position: relative;
            overflow-x: hidden;
            overflow-y: auto;
            max-height: 30vh;
            width: 100%;
            color: $colorTextPrimary;
            font-size: $fontSize14;
            border-bottom: 1px solid $creamyF2;
            tr:nth-child(4) {
                border:none;
            }
            tr:nth-last-child(2) {
                border-bottom: none;
            }
            tr:last-child{
                display: flex!important;
            }
            .child_four{
                border-bottom: 1px solid $colorBackgroundSurfaceStroke!important;
            }
            tr {
                border-bottom: 1px solid $colorBackgroundSurfaceStroke;
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
            }
            tr:last-child {
                border-bottom: none;
                border-top: 1px solid $colorBackgroundSurfaceStroke;
            }
            tr:first-child {
                border-top: 1px solid $colorBackgroundSurfaceStroke;
            }
        }

    }
    .update_date {
        width: 200px;
        font-size: $fontSize14;
        line-height: 18px;
        padding: 20px 40px 28px;
        text-align: right;
        color: $colorTextSecondary;
    }
    .swap_section .update_date {
            width: 100%;
    }
    &.opened {
        z-index: 101;
        .currency_inner {
            max-height: 1000px;
            opacity: 1;
            @include animStyles(max-height 0s 0s, opacity 0.3s);
            .switch_btns{
                padding-bottom: 0;
            }
            .currency_content{
                margin: 8px 24px 24px;
                table{
                    background: $colorBackgroundSurfacePrimary;
                    border: 0.5px solid $colorBackgroundSurfaceStroke;
                    border-radius: $borderRadius16;
                    font-size: $fontSize14;
                    thead{
                        color: $colorTextSecondary;
                        background: $colorControlTableHeader;
                        border-radius: $borderRadius16;
                        border-bottom-right-radius: 0;
                        border-bottom-left-radius: 0;

                    }
                    tbody{
                        &::-webkit-scrollbar {
                            width: 0;
                            background: transparent; /* make scrollbar transparent */
                        }
                    }

                }

                .update_date{
                    padding: 12px 0 0;
                }
            }
            .currency_title{
                margin-bottom: 12px;
                color: $colorTextPrimary;
            }
        }
        .action_btn {
            color: $white;
            background: $colorControlPrimary;
        }
    }
    @include mediaTo($size960) {
        z-index: 101;
        .currency_inner {
            min-width: 320px;
            margin-top: 6px;
        }
        .currency_title {
            padding: 0 20px;
            margin-bottom: 24px;
        }
        .switch_btns {
            padding-bottom: 12px;
            margin: 0 20px 4px;
            button {
                &:after {
                    margin-top: 12px;
                }
            }
        }
        table {
            td,
            th {
                padding: 12px 10px;
                &:first-child {
                    padding-left: 20px;
                }
                &:last-child {
                    padding-right: 20px;
                }
            }
        }
        
        .update_date {
            @include standardNarrow;
            padding: 16px 20px 24px;
            text-align: right;
            color: $gray66;
        }
        &.opened {
            z-index: 110;
        }
    }
    @include mediaTo($size480) {
        .currency_inner {
            right: -121px;
        }
    }
}

.web {
    .currency_block .switch_btns button,
    .currency_close {
        @include LinkHover;
    }

}

.touch {
    .currency_close {
        @include standardActive;
    }
}