@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.form_select {
  margin-top: 5px;
  @include standardNarrow;
  color: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin-right: 2rem;
  font-family: inherit;
  width: 100%;
}
.select__menu-list{
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background: $colorControlTabbarInactive;
  }
}
.select__control {
  border-radius: $borderRadius12;
  min-height: 49px;
  box-shadow: unset;
  margin-top: 5px;
  .select__placeholder {
    @include mediumText;
    color: $colorTextSecondary;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    overflow: hidden;
    line-height: 21px;
  }

  &:hover {
    border: 1px solid $colorControlBorderFocus;
  }

  .select__indicators {
    > div {
      cursor: pointer;
      padding: 4px;
    }

    .select__dropdown-indicator {
      svg {
        transition: 0.3s ease-out;
        color: $colorIconsSecondary;
      }
    }

    .select__loading-indicator {
      margin-right: 0;
      padding: 3px;
    }

    .select__indicator-separator {
      display: none;
    }
  }

  .select__multi-value {
    background: $colorBrandElectricblue;
    color: white;
    border-radius: 10px;

    .select__multi-value__remove {
      cursor: pointer;

      &:hover {
        background: transparent;
      }
    }

    .select__multi-value__label {
      color: white;

    }
  }

  .select__value-container {

    .select__single-value {
      @include standardNarrow;
      @media (max-width: 600px){
        font-size: 110%;
        line-height: 15px;
      }
    }
  }
}
.select__menu{
  top: 100%;
}
.select__control--menu-is-open {
  .select__indicators .select__dropdown-indicator svg {
    transform: rotate(-180deg);
  }
}

.select__menu {
  .select__menu-list {
    .select__option{
      font-size: 12px;
      cursor: pointer;
      &:hover{
        background-color: $colorControlSecondaryDefault;
        color: $colorTextPrimary;
      }
    }
    .select__option--is-focused, .select__option--is-selected {
      background-color: $colorControlSecondaryDefault;
      color: $colorTextPrimary;
    }
    .select__option--is-selected {
      background-color: $colorControlSecondaryDefault!important;
    }
  }
}
.select-full-placeholder {
  position: absolute;
  top: -20px;
  right: 0;
  border-radius: 10px;
  font-size: 11px;
  background: #f0f3f5;
  padding: 2px 5px;
  color: $colorTextSecondary;
  opacity: 0;
  transition: 0.3s ease-out;
  width: max-content;
}
.select__option.select__option--is-focused{
  &:first-child{
    background-color: transparent;
  }
}