@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.home_page .calc_section {
    .popup_wrapper {
        .popup_block {
            top: 0;
            overflow: hidden;

            .popup_container {
                max-width: 753px;
                width: 584px;
                overflow: hidden;
                border-radius: 24px;
                background: $colorBackgroundSurfacePrimary;
                z-index: 111111111;
            }
        }
    }
    .popup_container_by_padding{
        padding: 24px;
    }
    .popup_container {
        max-width: 756px;
        overflow: hidden;
        border-radius: $borderRadius24;
    }


    .results_table {
        color: $colorTextPrimary;
        background: $colorBackgroundSurfacePrimary;
        border: 0.5px solid $colorBackgroundSurfaceStroke;
        border-radius: $borderRadius16;
        font-size: $fontSize14;
        font-family: "NotoSansMono", sans-serif;
        margin-top: 20px;
        margin-bottom: 28px;
        .table_head {
            color: $colorTextSecondary;
            background: $colorControlTableHeader;
            border-radius: $borderRadius16;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            width: 100%;
            border-bottom: 1px solid $calcBorder;
            .table_cell {
                padding-top: 12px;
                padding-bottom: 12px;
            }
        }
        .table_body {
            max-height: 384px;
            width: 100%;
            @include mediaVTo($size600) {
                max-height: 49vh;
            }
            @include mediaVTo($size480) {
                max-height: 40vh;
            }
            position: relative;
            overflow-x: hidden;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 4px;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 100px;
                background: $colorControlTabbarInactive;
            }
            .table_cell {
                padding-top: 20px;
                padding-bottom: 20px;
            }
            .table_row:nth-child(1n) {
                border-bottom: 1px solid $colorBackgroundSurfaceStroke;
            }
        }
        .table_row {
            display: flex;
            .table_cell {
                &:nth-child(1){
                    font-family: 'NotoSansArmenian-Regular' ,sans-serif;
                }
                &:nth-child(2){
                    text-align: right;
                }
                &:nth-child(3){
                    text-align: right;
                    font-family: 'NotoSansArmenian-Regular' ,sans-serif;

                }
            }
        }
        .table_cell {
            flex: 1;
            padding-left: 16px;
            padding-right: 16px;
        }
        .icon {
            font-family: 'montserratarm-light';
        }
    }
    @include mediaRange($size640, $size960) {
        .download_btn {
            margin-bottom: 24px;
        }
        .popup_container {
            padding-left: 24px;
            padding-right: 24px;
            max-width: 640px;
        }
        .results_table {
            margin-left: -24px;
            margin-right: -24px;
            .table_row {
                padding-left: 12px;
                padding-right: 12px;
            }
            .table_cell {
                padding-left: 12px;
                padding-right: 12px;
            }
            .table_body .table_cell {
                padding-top: 16px;
                padding-bottom: 16px;
            }
        }
    }
    @include mediaTo($size640) {
        .download_btn {
            margin-bottom: 20px;
        }
        .popup_container {
            padding-left: 16px;
            padding-right: 16px;
            max-width: 100%;
        }
        .results_table {
            margin-left: -16px;
            margin-right: -16px;
            .table_row {
                padding-left: 8px;
                padding-right: 8px;
            }
            .table_cell {
                padding-left: 8px;
                padding-right: 8px;
            }
            .table_body .table_cell {
                padding-top: 12px;
                padding-bottom: 12px;
            }
        }
    }
}


