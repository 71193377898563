@import './variables';
@import '../../components/uiKit/styles/variablesLightMode';

@media (max-width: $size1366) {
  body {
    .compare_accounts_block {
      padding: 32px 0 56px
    }
  }
}


@media (max-width: $size1201) {
  body {
    .footer {
      .info_footer {
        .description_wrapper {
          align-items: flex-start;

          .footer_description {
            margin-top: 0;
          }

          .subscribe_section {
            max-width: 40%;

            .page_row {
              flex-wrap: nowrap;
            }
          }
        }
      }
    }

    .account-to-account-block {

      .account-to-account {
        background: unset;

        .account-to-account-top {
          margin-bottom: 20px;

          .banner_info {
            padding: 0 0 40px 50px;
          }

          .banner_img {
            @include standardCol(4);

            img {
              border-radius: $borderRadius20;
            }

            &:after {
              content: unset;
            }
          }
        }

        .account-to-account-bottom {
          .banner_img {
            @include standardCol(0);
          }

          .card-wrapper {
            .card {
              width: 100%;

              &:first-child {
                border-top-left-radius: $borderRadius20;
              }
            }
          }
        }
      }

    }


    .image-text-block .image-text .banner_img {
      max-height: 300px;
      margin: 0 auto;
    }

    .image_right .banner_img {
      margin-left: 20px;
    }

    .image_left .banner_img {
      margin-right: 20px;
    }

    .futures_template .items-wrapper {
      gap: 20px;

      > div {
        min-width: 300px;
      }
    }

    .image-text-block.video_block.block-7 {
      .image-text .banner_img {
        max-height: unset;
      }
    }
  }
}

@media (max-width: $size991) {
  body {
    .about-page {
      .files{
        justify-content: center;
      }
      .bank-history-tab .bank-history-slider {
        padding: 12px 0 32px;

        .bank-history-wrapper .bank-history-slider-wrapper {
          width: 100%;
        }


        .years .year.active:before {
          top: 5.5px !important;
        }
      }
    }

    .image_right .banner_img {
      margin-left: 0;
    }

    .image-text-block .image-text {
      .banner_info .mobile_app {
        background: transparent;
        color: white;
        font-size: 12px;
        line-height: 16px;
        padding: 0;
        text-transform: uppercase;
      }

      .banner_img img {
        height: unset;
      }
    }

    .image_left .banner_img {
      margin-right: 0;
    }

    .image-text-block.video_block.block-7 {
      .image-text {
        .banner_img {
          max-height: unset;
          max-width: unset;
          object-fit: unset;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }

        .banner_info {
          max-width: unset;
          padding: 24px 16px;
        }
      }
    }

    .blog_section .section_head .section_title {
      margin-bottom: 16px;
    }

    .image-text-block.background_blue_50,
    .image-text-block.background_transparent {
      .banner_info {
        .banner_description {
          color: $colorTextSecondary;
        }
      }
    }

    .font-size-26, .section_title {
      font-size: $fontSize20;
      line-height: 26px;
      font-family: "NotoSansArmenian-SemiBold", sans-serif;
    }

    .medium.button {
      width: 100% !important;
    }

    .slide_info {
      .medium.button {
        width: max-content !important;
      }
    }

    .exchange_section .exchange_inner .form_currency, .exchange_section .currency_content {
      width: 100%;
    }

    .exchange_section {
      .switch_btns {
        max-width: unset;
      }

      .exchange_inner {
        .form_currency {
          margin: 0 0 25px;
          padding: 24px 16px;
          width: 100%;

          .swap_section {
            max-width: 100%;

            .page_row {
              display: block;
              width: 100%;

              .currency_field {
                float: left;
                width: calc(100% - 130px);

                input {
                  width: 100%;
                }

                .select_block {
                  top: 0;
                }
              }

              .currency_field.currency {
                width: 100px;
              }

              .btn_exchange {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: $fontSize26;
                position: relative;
                margin: auto;
                margin-bottom: 10px;

                &:before {
                  transform: rotate(90deg);
                  z-index: 11;
                  position: relative;
                }

                &:after {
                  position: absolute;
                  content: '';
                  height: 1px;
                  width: 100%;
                  background: $colorBackgroundSurfaceStroke;
                }

              }
            }
          }
        }
      }

      .currency_content {
        width: 100%;
        overflow: hidden;
        padding: 24px 16px;

        .bottom_currency {
          display: flex;
          flex-direction: column-reverse;
          align-items: start;

          .update_date {
            padding-top: 10px;
            width: 100%;
            border-top: 1px solid $colorBackgroundSurfaceStroke;

          }

          .see_all {
            padding: 10px 0 20px;
          }
        }
      }

      .section_title {
        text-align: start;
      }
    }

    .futures_template {
      padding: 56px 0 24px;

      .items-wrapper {
        flex-wrap: nowrap;
        overflow-x: scroll;

        > div {
          padding: 24px 16px;
          min-width: 288px;
          display: flex;
          flex-direction: column;

          img {
            margin-bottom: 16px;
          }
        }

        &::-webkit-scrollbar {
          width: 0;
          background: transparent; /* make scrollbar transparent */
        }
      }
    }

    .compare_accounts_block {
      .info-wrapper {
        flex-direction: column;
        padding: 24px 16px;

        div {
          @include standardCol(12);

          p {
            color: $colorTextPrimary;
            margin: 0 0 16px;
          }
        }
      }
    }

    .image-text-block.image_right {
      .image-text {
        flex-direction: column-reverse;
      }
    }

    .image-text-block.image_left {
      .image-text {
        flex-direction: column;
      }
    }

    .image-text-block.image_left, .image-text-block.image_right {
      .image-text {
        .banner_info {
          margin-top: 32px;
        }
      }
    }

    .image-text-block.background_blue .image-text,
    .image-text-block.background_blue_50 .image-text,
    .image-text-block.background_transparent .image-text,
    .image-text-block.background_blue_50.credit_limit_facility .image-text,
    {
      padding: 0;

      .banner_info {
        padding: 16px;
      }
    }

    .universal_account_block .activate_universal_account .banner_info {
      padding: 16px;
    }

    .account-to-account-block {

      .account-to-account {
        .account-to-account-top {
          max-height: unset;
          flex-direction: column;

          .banner_info {
            padding: 20px;
          }

          .banner_img {
            width: 90%;
            @include standardCol(12);
            max-height: unset;

            img {
              border-radius: $borderRadius20;
            }

            &:after {
              content: unset;
            }
          }
        }

        .account-to-account-bottom {

          .banner_info {
            padding: 0 0 40px 50px;
          }

          .banner_img {
            display: none;

            img {
              border-radius: $borderRadius20;
            }

            &:after {
              content: unset;
            }
          }

          .card-wrapper {
            flex-direction: column;
            flex-wrap: wrap;

            .card {
              min-width: unset;
              padding: 20px;

              .card-body {
                padding: 15px;
              }
            }
          }
        }
      }

      .bottom-block {
        flex-direction: column;
        flex-wrap: wrap;
        margin: 30px 0 0;

        .bottom-block-wrapper {
          > div {
            width: 100%;
            flex-direction: column;
            max-width: unset !important;
            flex: unset !important;
            padding: 16px !important;
            align-items: flex-end !important;

            p {
              width: 100%;
              margin: 0;
              padding: 0;
              word-break: break-word;
            }
          }
        }
      }
    }

    .image-text-block {
      padding: 0 0 56px;
    }

    .image-text-block.video_block .image-text {
      flex-direction: column-reverse;

      .banner_img {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }

      .banner_info {
        border-radius: $borderRadius12;

      }
    }

    .landing_page {
      .mobile_app_section {
        margin: 56px 0 28px;

        .content-wrapper {
          flex-direction: column-reverse;

          .left_block {
            padding: 24px 16px;
          }

          .right_block {
            margin-left: unset;
            flex: unset;
            margin-top: 0;
          }
        }
      }

      .small_banner_section {
        padding: 28px 0;
      }

      .about_product, .left_section.left_section_50, .right_section, .cards_section, .device_trusted, .landing_banner, .home_services, .blog_section, .landing_form {
        margin: 28px 0;
        padding: 24px 16px;
      }

      .banner_large {
        margin: 28px 0;
      }

      .left_section.left_section_full {
        margin: 14px 0;
        padding: 14px 0;
      }

      .left_section .right_block, .right_section .right_block, .device_trusted .left_block, .landing_banner .home_banner_inner, .banner_large {
        padding: 24px 16px !important;
        width: 100%;
      }

      .banner_large .page_row {
        justify-content: start;
      }

      .right_section .inner_section, .left_section.left_section_full .inner_section, .left_section.left_section_50 .inner_section, .device_trusted .page_row .inner_section {
        padding: 0 !important;
      }

      .left_section.left_section_50, .right_section {
        width: 100%;
        max-width: unset;

        .page_container {
          margin-left: unset !important;
          width: 100%;
          max-width: unset;
        }
      }

      .left_section.left_section_50 .inner_section, .right_section .inner_section, .device_trusted .page_row .inner_section, .landing_banner .home_banner_inner {
        flex-direction: column-reverse;
      }

      .left_section .left_block, .device_trusted .right_block, .landing_banner .home_banner_inner .banner_img {
        width: 100%;
        max-width: unset !important;
        padding-right: unset;
        justify-content: center;
      }

      .left_section .inner_section {
        flex-direction: column;
        justify-content: center;
        padding: 24px 16px;
      }

      .left_section .right_block, .device_trusted .left_block, .landing_banner .home_banner_inner .banner_info {
        max-width: unset !important;
        width: 100%;
      }

      .right_section .inner_section {
        width: 100%;
      }

      .page_row .inner_section .right_section .left_block {
        justify-content: center;
        width: 100%;
      }

      .inner_section {
        .right_block, .left_block {
          img {
            max-height: 500px;
          }
        }
      }
    }

    .about-page {
      .banner-wrapper {
        img {
          min-height: 400px;
          object-fit: cover;
        }

        .banner-info-wrapper {
          top: 10%;
        }
      }

      .page-info-wrapper {
        .page-info-block {
          .page_container {
            padding-left: 0;
            padding-right: 0;

            .page_row {
              margin-left: 0;
              margin-right: 0;

              .inner_section {
                padding: 4px;
                flex-direction: column-reverse !important;

                img {
                  width: unset;
                  max-width: 100%;
                  max-height: 350px;
                }
              }
            }
          }
        }

        .additional-info-wrapper {
          margin-top: 0;
          padding: 32px;

          .quote-top {
            top: 18px;
          }

          .quote-bottom {
            bottom: 18px;
          }
        }
      }

      .bank-services-wrapper {
        padding: 56px 0 0;

        .bank-services .service {
          width: 100%;
          padding: 24px;
        }
      }

      .footer-wrapper {
        padding: 0;
        background-position: center;

        .lr-wrapper {
          flex-direction: column-reverse;
          padding: 32px;
        }
      }

      .tab-buttons {
        margin-top: 24px;
        flex-wrap: wrap;
      }

      .bank-persons-tab .chakra-tabs__tab-panels .chakra-tabs__tab-panel .bank-persons-wrapper {
        padding: 0 0 56px;

        .bank-persons {
          .slick-slide {
            padding: 0 15px;
          }

          .bank-person-modal .popup_container {
            width: 100%;

            .person-info-wrapper {
              flex-direction: column;

              .image-wrapper {
                .position, .name {
                  display: block;
                  text-align: start;
                }

                img {
                  width: unset;
                  max-width: 400px;
                }

              }

              .info-wrapper {
                width: 100%;
                max-width: unset;

                .position, .name {
                  display: none;
                }

                .info {
                  overflow-y: unset;
                  width: 100%;
                  max-height: unset;
                }
              }

            }

            .popup_body {
              margin: 0;
            }
          }
        }
      }

      .blocks-accordion .chakra-accordion__button, .files-accordion .chakra-accordion__button,
      .text-accordion .chakra-accordion__button {
        .svg-wrapper {
          display: none;
        }
      }

      .bank-persons-tab {
        padding-bottom: 56px;
      }

      .bank-history-tab {
        .bank-history-wrapper {
          .bank-history-slider-wrapper {
            width: 100%;
          }

          .info-wrapper {
            flex-direction: column;

            .title-files {
              width: 100%;
            }

            .buttons-wrapper {
              display: none;
            }
          }
        }
      }

      .bank-shareholders-investors-tab {
        .banner-wrapper {
          padding-top: 0;
          background: #0000004D;

          .banner {
            background-position: center;
            background-size: cover;
            height: unset;

            .info-wrapper {
              padding: 64px 20px;
              width: 100%;
              max-width: unset;

              .head-title, .text {
                color: white;
              }

              .primary_button {
                width: max-content !important;
              }
            }
          }
        }

        .text-accordion-wrapper {
          padding: 56px 0;
        }

        .files-accordion-wrapper .files {
          flex-direction: column;
          flex-wrap: wrap;

          .file {
            width: 100%;
          }
        }

        .dynamic_section {
          .content-wrapper {
            padding: 10vw 20px;

            .left_block {
              color: white !important; /////////////////
            }
          }
        }
      }

      .bank-shareholders-tab .shareholders-wrapper, .bank-shareholders-tab .indirectly-shareholders-wrapper {
        flex-wrap: wrap;
        justify-content: center;
      }

      .files .file {
        width: 90%;
      }
    }
  }


  .footer {
    .footer_menu .menu_list {
      column-count: 2;
    }

    .info_footer {
      .description_wrapper {
        align-items: flex-start;
        flex-direction: column;

        .footer_description {
          width: 100%;
          margin-top: 0;
        }

        .footer_description.desc {
          width: max-content;
          margin-top: 0;
        }

        .subscribe_section {
          max-width: 100%;
          margin: 20px 0;

          .page_row {

            flex-wrap: nowrap;
          }
        }
      }
    }
  }

}

@media (max-width: $size768) {
  .footer_menues {
    display: block !important;
  }
  .footer {
    .footer_menu .menu_list {
      column-count: 1;

      a {
        width: max-content;
        max-width: unset;
      }
    }

    .info_footer {
      padding-top: 0 !important;

      .description_wrapper {
        align-items: flex-start;
        flex-direction: column-reverse;

        .footer_description {
          width: 100%;
          margin-top: 0;
        }

        .subscribe_section {
          max-width: 100%;
          width: 100%;
          margin: 20px 0 40px;

          .page_row {
            flex-wrap: nowrap;
          }

          .socials_list {
            width: 100%;
            justify-content: space-between;
            margin-top: 0;

          }
        }
      }

    }

    .bottom_block {
      align-items: start !important;
      flex-direction: column;
    }

  }
  .landing_page {
    .inner_section {
      .right_block, .left_block {
        img {
          max-height: 400px;
        }
      }
    }
  }
  body .about-page .bank-shareholders-investors-tab .dynamic_section {
    background-size: auto 100% !important;
  }
}

@media (max-width: $size640) {
  body {
    .activate_package_block .activate_package .activate_package_info .card-wrapper .card {
      min-width: 288px;
    }

    .landing_page .landing_banner .home_banner_inner .banner_img img {
      width: 100%;
    }

    .exchange_section {
      .switch_btns {
        overflow-x: scroll;
        padding: 0 0 18px 10px;
      }
    }

    .home_services {
      padding: 28px 0;
    }

    .home_services .services_wrapper .services {
      width: 100% !important;
    }

    .exchange_section .currency_row {
      flex-direction: column;

    }

    .banner_block {
      margin-bottom: 0;

      .home_banner_inner {
        border-radius: $borderRadius12;
        flex-wrap: wrap-reverse;

        .banner_img img {
          width: 100%;
        }

        .section_title {
          width: 100%;
        }

        .banner_info {
          padding: 24px 16px;
          margin-top: 8px;

          a {
            width: 100% !important;
          }
        }
      }
    }

    .blog_section {
      .section_head {
        flex-direction: column;
        align-items: start;

        a {
          margin: 0;
        }
      }
    }


    .landing_page .landing_form .left_block {
      padding: 10px;

      .field_block {
        margin: 0;
      }
    }

    .calc_section .calc_results .calc_results_wrapper {
      top: 0;
    }

    .calc_section .results_list li > span:first-child {
      font-size: 14px;
    }

    .calc_section .actions_block {
      padding: 0 20px;
      margin-top: 4px;
    }
  }


}

@media (max-width: $size576) {
  body .about-page {
    .footer-wrapper {
      background-position: center;
      background-size: auto 100%;
    }

    .bank-shareholders-tab .capital-wrapper {

      align-items: flex-start;

      .title {
        text-align: center;
      }
    }
  }
  .landing_page {
    .inner_section {
      .right_block, .left_block {
        img {
          max-height: 300px;
        }
      }
    }
  }
  .landing_page .banner_large .page_row {
    justify-content: start;
  }
  .landing_page .landing_form .subscribe_form input {
    padding: 7px !important;;
  }

}