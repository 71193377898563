@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
.payment_methods_container {
  margin-top: 24px;
  margin-bottom: 40px;
}
.payment_methods_container .payment_title_box {
  background-color: #002366;
  color: white;
  align-items: center;
  font-size: 12px;
  line-height: 1.5em;
  font-family: "montserrat-medium", "montserratarm";
  justify-content: center;
  border-radius: 20px;
  height: 98px;
  min-width: 98px;
  width: max-content;
}
.payment_methods_container .payment_title_box .title_bold {
  text-align: center;
  word-break: break-word;
}
.payment_methods_container .payment_header_text {
  font-size: 14px;
  line-height: 1.5em;
  margin: 0;
  margin-top: 16px;
  color: #070B11;
}
.payment_methods_container .page_title {
  line-height: 1.4em;
}
@media screen and (min-width: 1280px) {
  .payment_methods_container .page_title {
    font-size: 32px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1279px) {
  .payment_methods_container .page_title {
    font-size: 32px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .payment_methods_container .page_title {
    font-size: 32px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .payment_methods_container .page_title {
    font-size: 280%;
  }
}
@media screen and (max-width: 575px) {
  .payment_methods_container .page_title {
    font-size: 240%;
  }
}
.payment_methods_container .main_slider {
  border-radius: 20px;
  margin-top: 32px;
}
.payment_methods_container .slide_block {
  border-radius: 20px;
  height: 21vw;
}
.payment_methods_container .slide_block a {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.payment_methods_container .slide_block a .play-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 1;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.payment_methods_container .slide_block a .play-cover .play-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  width: 72px;
  height: 72px;
  border-radius: 16px;
}
.payment_methods_container .slick-disabled {
  opacity: 0;
  pointer-events: none;
}
.payment_methods_container .slick-prev {
  width: 40px !important;
  height: 40px !important;
  z-index: 10000;
  left: 32px;
}
.payment_methods_container .slick-next {
  width: 40px !important;
  height: 40px !important;
  right: 32px;
}
.payment_methods_container .slick-slide.slick-active:first-child {
  padding-right: 20px;
}
.payment_methods_container .slick-list {
  padding: 0 -10px;
}
.payment_methods_container .payment_methods_cards {
  padding: 40px 48px 32px;
  border-radius: 20px;
  margin-top: 40px;
  margin-bottom: 40px;
  box-shadow: 0 4px 16px 0 rgba(13, 43, 150, 0.08);
  flex-direction: column;
}
.payment_methods_container .payment_methods_cards > div:first-child {
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
}
.payment_methods_container .payment_methods_cards > div:first-child img {
  max-width: 100px;
  max-height: 56px;
  object-fit: contain;
}
.payment_methods_container .payment_methods_cards > p {
  margin-top: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5em;
  color: #070B11;
}
.payment_methods_container .payment_methods_cards .methods_buttons {
  gap: 20px;
  flex-wrap: wrap;
}
.payment_methods_container .payment_methods_cards .methods_buttons > span {
  white-space: normal;
  text-align: left;
  border: none;
  font-size: 14px;
  line-height: 1.5em;
  border-radius: 10px;
  background-color: #F6F7FF;
  padding: 20px;
}
.payment_methods_container .payment_methods_cards .methods_buttons > span svg path, .payment_methods_container .payment_methods_cards .methods_buttons > span svg rect {
  fill: #3F00FF;
}
.payment_methods_container .description_section {
  margin: 40px 0;
}
.payment_methods_container .blue_section {
  background: #F6F7FF;
  padding: 72px 0;
  margin: 40px 0;
}
.payment_methods_container .blue_section .block_wrapper:last-child {
  margin-bottom: 0 !important;
}
.payment_methods_container .blue_section .block_wrapper .group_items .item_title {
  margin-bottom: 28px;
}
@media screen and (max-width: 990px) {
  .payment_methods_container .blue_section .block_wrapper .group_items .item_title {
    margin-bottom: 16px;
  }
}
.payment_methods_container .blue_section .block_wrapper .group_items .item_title .section_title {
  margin: 0;
}
.payment_methods_container .payment_blocks {
  margin-top: 20px;
  list-style: none;
  width: 100%;
  padding: 0;
  grid-column: 1/span 4;
  display: flex;
  gap: 34px;
  flex-wrap: wrap;
}
.payment_methods_container .payment_blocks > li {
  width: 30%;
  min-height: 194px;
  border-radius: 20px;
  background: linear-gradient(110deg, #D9D9D9 8%, #f5f5f5 18%, #D9D9D9 33%);
}
.payment_methods_container .payment_blocks > li:before {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 16px;
  animation: 1.5s shine linear infinite;
}
.payment_methods_container .img_blog > img {
  height: 155px;
  object-fit: cover;
  border-radius: 20px 20px 0 0;
}
.payment_methods_container .block_title {
  line-height: 25px;
  color: #070B11;
  margin: 0 0 8px 0;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.5em;
}
.payment_methods_container .block {
  padding: 16px;
}
.payment_methods_container .main_block {
  border: 1px solid #F2F2F2;
  border-radius: 12px;
}
.payment_methods_container .block_text {
  color: #677482;
  font-size: 14px;
  line-height: 1.5em;
  margin: 0;
}
.payment_methods_container .block_text p, .payment_methods_container .block_text span, .payment_methods_container .block_text div, .payment_methods_container .block_text b, .payment_methods_container .block_text ul, .payment_methods_container .block_text li, .payment_methods_container .block_text i, .payment_methods_container .block_text h1, .payment_methods_container .block_text h2, .payment_methods_container .block_text h3, .payment_methods_container .block_text h4, .payment_methods_container .block_text h5, .payment_methods_container .block_text h6 {
  font-size: 14px;
  line-height: 1.5em;
  color: #677482 !important;
  margin: 0;
}

.payment_header_title_skeleton > li {
  width: 40%;
  height: 100%;
  margin-bottom: 10px;
  border-radius: 20px;
  background: linear-gradient(110deg, #D9D9D9 8%, #f5f5f5 18%, #D9D9D9 33%);
}
.payment_header_title_skeleton > li:before {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 16px;
  animation: 1.5s shine linear infinite;
}

.payment_title_box_skeleton {
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;
  grid-column: 1/span 4;
  display: flex;
  gap: 34px;
  flex-wrap: wrap;
}
.payment_title_box_skeleton > li {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: linear-gradient(110deg, #D9D9D9 8%, #f5f5f5 18%, #D9D9D9 33%);
}
.payment_title_box_skeleton > li:before {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 16px;
  animation: 1.5s shine linear infinite;
}

.payment_title_skeleton > li {
  width: 30%;
  min-height: 194px;
  border-radius: 20px;
  background: linear-gradient(110deg, #D9D9D9 8%, #f5f5f5 18%, #D9D9D9 33%);
}
.payment_title_skeleton > li:before {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 16px;
  animation: 1.5s shine linear infinite;
}

.payment_buttons_group {
  margin-top: 60px;
  max-width: 1200px;
  padding: 16px 32px;
  min-height: 84px;
  justify-content: space-between;
  box-shadow: 0 4px 16px 0 rgba(13, 43, 150, 0.08);
  border-radius: 12px;
}
.payment_buttons_group button a {
  color: white;
}
.payment_buttons_group .payment_buttons {
  display: flex;
  justify-content: start;
  padding: 0;
  gap: 16px;
}
.payment_buttons_group .payment_buttons .payment_methods_button {
  white-space: nowrap;
  border: none;
  font-size: 16px;
  line-height: 22px;
  background-color: white;
  color: #070B11;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid transparent;
  transition: 0.3s ease-out;
  padding: 0;
  padding-bottom: 12px;
}
.payment_buttons_group .payment_buttons .payment_methods_button.active {
  border-bottom: 2px solid #3F00FF;
}
.payment_buttons_group .payment_buttons .payment_methods_button:hover {
  -o-transition: color 0.3s;
  -ms-transition: color 0.3s;
  -moz-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  border-bottom: 2px solid #3F00FF;
}
.payment_buttons_group .payment_buttons .payment_methods_button:hover:hover {
  color: #3800E2;
}

.title_bold {
  font-size: 22px;
  line-height: 28px;
}

.primary_btn {
  width: max-content;
}

.another_payment_systems_block {
  margin: 80px 0;
}
.another_payment_systems_block .main_grid {
  grid-template-columns: repeat(4, 1fr);
  gap: 32px;
}

.view_blocks {
  justify-content: space-between;
  background-color: white;
  border-radius: 16px;
  padding: 32px;
  line-height: 22px;
  border: 0.5px solid #CCD6E6;
}
.view_blocks ul {
  padding: 0 0 0 15px;
}
.view_blocks ul li {
  font-size: 14px;
  color: #677482;
}
.view_blocks ul li::marker {
  color: #3F00FF;
}
.view_blocks > p:first-child {
  line-height: 25px;
}
.view_blocks > .block_text > ul {
  padding-left: 20px;
  list-style-type: inherit;
}
.view_blocks > .block_text > ul > li::marker {
  color: #3F00FF;
  border-radius: 10px;
  font-size: 19px;
}
.view_blocks .info-wrapper {
  display: flex;
  flex-direction: column;
  flex: 0 0 50%;
  max-width: 50%;
}
.view_blocks .button_wrapper {
  gap: 32px;
}
.view_blocks .button_wrapper a {
  width: 50%;
}
.view_blocks .button_wrapper a svg {
  margin-right: 8px;
}

.download_btn {
  width: 283px;
  margin-left: 20px;
  border: none;
  background: #E5F2F9;
  color: #007DC3;
  font-family: "montserrat-medium", "montserratarm";
  font-size: 14px;
  line-height: 20px;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: top;
  color: #333333;
  height: 50px;
}
.download_btn[disabled], .download_btn.disabled, .download_btn:disabled, .download_btn.inactive {
  pointer-events: none;
  opacity: 0.6;
}

.payment_block_grid {
  margin-top: 4px;
  grid-template-columns: repeat(2, 1fr);
  gap: 34px;
}
@media screen and (max-width: 990px) {
  .payment_block_grid {
    gap: 16px;
  }
}

@media (max-width: 1200px) {
  .view_block_with_buttons {
    justify-content: center;
  }
  .view_block_with_buttons > div:first-child {
    width: 50%;
  }
}
@media (max-width: 1240px) {
  .payment_methods_container .main_slider {
    margin-left: 20px;
  }
}
@media (max-width: 1025px) {
  .main_grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .payment_block_grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .payment_container {
    margin-top: 60px;
  }

  .view_block_with_buttons {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
  }
  .view_block_with_buttons div:first-child {
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
  }
  .view_block_with_buttons div:first-child p {
    max-width: 100%;
  }
}
@media (max-width: 991px) {
  .payment_methods_container .blue_section {
    margin: 32px 0;
    padding: 35px 0 32px;
  }
  .payment_methods_container .blue_section .view_blocks {
    padding: 20px;
  }

  .another_payment_systems_block {
    margin: 32px 0 64px;
  }
  .another_payment_systems_block .main_grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .methods_buttons {
    gap: 8px;
  }

  .payment_buttons_group {
    display: flex !important;
    justify-content: start !important;
    flex-wrap: wrap;
    gap: 16px;
  }
  .payment_buttons_group .payment_buttons {
    overflow-x: scroll;
    display: flex;
    justify-content: start;
    padding: 0;
  }
  .payment_buttons_group .payment_buttons::-webkit-scrollbar {
    width: 0;
    background: transparent;
    margin: 0;
    padding: 0;
  }
}
@media (max-width: 668px) {
  .another_payment_systems_block {
    margin: 32px 0 64px;
  }
  .another_payment_systems_block .main_grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .view_blocks img {
    width: 100%;
  }

  .button_wrapper {
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .button_wrapper > button {
    width: 100%;
    margin: 10px 0 10px 0;
  }
  .button_wrapper .download_btn {
    margin-top: 10px !important;
    margin-left: 0 !important;
  }

  .methods_buttons > button {
    width: 100%;
  }

  .primary_btn {
    width: 100%;
    margin: 10px auto 0;
    grid-column: span 4/span 4;
  }
}
@media (max-width: 500px) {
  > div {
    align-items: start;
  }

  .payment_title_box {
    height: 100px !important;
    width: 130px !important;
  }
}
@media (max-width: 576px) {
  .payment_methods_container .page_title {
    font-size: 22px !important;
  }

  .main_grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .block_title {
    font-size: 18px !important;
  }

  .section_title {
    font-size: 20px;
  }

  .block_text {
    font-size: 14px;
  }

  .payment_container {
    margin-top: 40px;
  }

  .payment_buttons_group {
    padding: 16px 8px;
  }

  .primary_btn {
    width: 100%;
  }

  .payment_blocks {
    gap: 10px !important;
  }
}

/*# sourceMappingURL=payment-methods.css.branches */
