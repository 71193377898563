@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.qr_page {
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    @include mediaFrom($size960) {
        padding: 76px 0 80px;

    }
    @include mediaTo($size991) {
        padding: 24px 0 56px;
    }


    .inner_section {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-bottom: 58px;
        @include mediaTo($size991) {
           flex-direction: column-reverse;
            .right_block{
                margin-bottom: 24px;
            }
            .app-list{
                flex-wrap: wrap;
            }
        }
        .left_block {
            padding: 0 $colPadding;

            .page_row {
                .section_title{
                    font-size: 32px;
                    line-height: 38px;
                    @include mediaTo($size991) {
                        font-size: 26px;
                        line-height: 32px;
                        text-align: start;
                    }
                    @include mediaTo($size640) {
                        @include mediumText;
                        text-align: start;
                    }
                }
                .app-list{
                    display: flex;
                    width: 100%;
                    gap: 16px;
                    margin-top: 8px;

                    a {
                        display: block;
                        img{
                            max-width: 122px;
                        }
                    }
                }

            }

            @include mediaFrom($size960) {
                @include colSize(7);
            }
            @include mediaTo($size960) {
                @include colSize(8);
            }
            @include mediaTo($size768) {
                @include colSize(12);
                text-align: center;
            }
        }

        .right_block {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 $colPadding;
            @include mediaTo($size991) {
                padding: 19px 0 ;
            }
            .qr-wrapper{
                border-radius: $borderRadius24;
                padding: 44px;
                max-width: 276px;
                max-height: 276px;
                box-shadow: $boxShadowLow;
                @include mediaTo($size991) {
                    max-width: 208px;
                    max-height: 208px;
                }
                img {
                    display: block;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                    margin: 0 auto;
                }

            }

            @include mediaFrom($size960) {
                @include colSize(5);
            }
            @include mediaTo($size960) {
                @include colSize(4);
            }
            @include mediaTo($size768) {
                @include colSize(12);
            }
        }

    }

    .list_pay {
        @include standardList;
        display: flex;
        gap: 32px;
        @include subtitleSize;
        flex-wrap: nowrap;
        justify-content: space-between;
        @include mediaTo($size1440) {
            flex-wrap: wrap;
        }
        @include mediaTo($size991) {
            flex-wrap: nowrap;
            overflow-x: scroll;
            &::-webkit-scrollbar {
                width: 0;
                background: transparent;
                margin: 0;
                padding: 0;
            }
        }
        li {
            @include colSize(2.75);
            padding: 32px 24px;
            display: flex;
            border: 0.5px solid $colorBackgroundSurfaceStroke;
            border-radius: $borderRadius20;
            flex-direction: column;

            div{
                font-family: "NotoSansArmenian-SemiBold", sans-serif;
                color: $colorTextPrimary;
                font-size: 16px;
                line-height: 22px;
            }
            @include mediaTo($size1440) {
                @include colSize(5.84);
            }
            @include mediaTo($size1200) {
                @include colSize(5.5);
            }
            @include mediaTo($size991) {
                @include colSize(3);
                min-width: 250px;
            }

            img {
                margin-bottom: 16px;
                display: block;
                height: auto;
                max-width: 32px;
                max-height: 32px;
            }
        }
    }
}