@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.map-page{
  margin: 40px 0 80px;
  .content-wrapper{
    .chakra-tabs{
      width: 100%;
      .types-tab-list{
        position: absolute;
        top: 5px;
        right: 0;
        z-index: 111;
        .type-buttons{
          padding:8px 12px!important;
          border-radius: 10px;
            &[aria-selected="true"]{
              box-shadow: 0 4px 16px 0 #223B9429;
              border-bottom: none!important;
            }
        }

      }
      .chakra-tabs__tablist{
        align-items: center;
        .tab-buttons{
          gap: 24px;
          .chakra-tabs__tab{
            border: none;
            @include mediumText;
            padding: 12px 0;
            background: transparent;
          &[aria-selected="true"]{
            border-bottom: 2px solid $colorControlTabPressed;
          }
          }
        }
        .branches_type_select{
          z-index: 1111;
          width: 100%;
          display: none;
          border-radius: $borderRadius12;
          @include mediumText;
          color: $colorTextPrimary;
          border: 1px solid $colorBackgroundSurfacePrimary;
          span{
            display: none;
          }
          svg{
            color:$colorIconsSecondary;
          }
          #react-select-3-listbox{
            >div{
              div{
                &:first-child{
                  background: transparent;
                }
              }
            }
            .branches_type_select__option--is-selected{
              background: #E1E6EB!important;
              color: $colorTextPrimary;
            }
            .branches_type_select__option{
              &:hover{
                color: $colorTextPrimary;
                background: #E1E6EB;
              }
            }
          }
          .branches_type_select__control{
            border-radius: $borderRadius12;
            border-color: #CCD6E6;
            .branches_type_select__input-container{
              @include mediumText;
              color: $colorTextPrimary;
            }
          }
          .branches_type_select__menu{
            position: relative;
          }
        }
      }
      .chakra-tabs__tab-panels{
        margin-top: 24px;
      }
      .list-wrapper{
        border-radius: 16px;
        margin-top: 24px;
        background: $colorBackgroundSurfaceSecondary;
        padding: 32px;
        width: 100%;
        .locations-wrapper{
          width: 100%;
          gap: 24px;
          flex-direction: column;
          .list-item{
            width: 100%;
            padding: 32px;
            background: white;
            border-radius: 16px;
            .list-item-wrapper{
              justify-content: space-between;
              p{
                margin: 0;
              }
              .left-wrapper{
                align-items: center;
                .title-desc-wrapper{
                  align-items: start;
                  gap: 4px;
                  margin-left: 8px;
                  flex-direction: column;
                  p{
                    color: $colorTextSecondary;
                    @include standardText;
                  }
                  b{
                    color: $colorTextPrimary;
                    @include mediumText;
                  }
                }
              }
              .right-wrapper{
                align-items: center;
                gap: 32px;
                color: $colorTextPrimary;
                @include mediumText;
                div{
                  align-items: center;
                  .work-days{
                    flex-direction: column;
                    align-items: start;
                    gap: 4px;

                  }
                  svg{
                    margin-right: 12px;
                  }
                }
              }
            }

          }
        }
        .regions-wrapper{
          display: flex;
          margin-bottom: 24px;
          gap: 12px;
          .regions_select{
            z-index: 1111;
            border-radius: $borderRadius12;
            @include mediumText;
            color: $colorTextPrimary;
            border: 1px solid #CCD6E6;
            span{
              display: none;
            }
            svg{
              color:$colorIconsSecondary;
            }
            .regions_select__menu{
              >div{
                div{
                  &:first-child{
                    background: transparent;
                  }
                }
              }
              .regions_select__option--is-selected{
                background: #E1E6EB!important;
                color: $colorTextPrimary;
              }
              .regions_select__option{
                &:hover{
                  color: $colorTextPrimary;
                  background: #E1E6EB;
                }
              }
            }
            .regions_select__control{
              border-radius: $borderRadius12;
              border-color: #CCD6E6;
              height: 48px;
              border: 1px;
              width: max-content;
              min-width: 320px;
              .loans_select__input-container{
                @include mediumText;
                color: $colorTextPrimary;
              }
            }
          }
          .select-wrapper{
            position: relative;
            .select-label-text{
              position: absolute;
              left: 12px;
              top: 0;
              z-index: 11111;
              color: $colorTextSecondary;
            }
          }
          .regions_select_label{
            color: $colorTextSecondary;
            display: block;
            @include standardNarrow;
            margin-bottom: 4px;
          }
        }
      }

    }

  }
  .branches-map-wrapper{
    .cluster{
      background: #002366;
      border-radius: 100%;
      >div{
        color: white!important;
        font-size: 20px!important;
      }
      img{
        display: none;
      }
    }
  }
}

.map_sk.skeleton {
  width: 100%;
  list-style-type: none;
  padding: 0;
  li:before {
    display: block;
    width: 100%;
    height: 600px;
    max-width: 100%;
    content: ".";
    color: transparent;
  }
  li::marker{display: none}
}
.branches_sk.skeleton {
  width: 100%;
  list-style-type: none;
  padding: 0;
  gap: 24px;
  display: flex;
  flex-direction: column;
  li:before {
    border-radius: 16px;
    display: block;
    width: 100%;
    height: 110px;
    max-width: 100%;
    content: ".";
    color: transparent;
  }
  li::marker{display: none}
}

@media screen and (max-width: $size991) {
  .map-page .content-wrapper .chakra-tabs {
    .chakra-tabs__tablist {
      .tab-buttons .chakra-tabs__tab{
        display: none;
      }
      .branches_type_select,{
        display: block;
      }
    }
    .types-tab-list{
      position: relative;
      .tab-buttons{
        display: flex;
        .type-buttons{
          display: block;
        }
      }
    }
    .list-wrapper{
      padding: 16px;
      .locations-wrapper{
        gap: 16px;
        .list-item .list-item-wrapper{
          flex-direction: column;
          gap: 32px;
          .right-wrapper{
            flex-direction: column;
            align-items: start;
          }
        }

      }
    }
  }
}