@use "sass:math";
@import '../../components/uiKit/styles/variablesLightMode';

@mixin rowMargin {
    @include mediaFrom($size960) {
        margin-left: $rowMargin;
        margin-right: $rowMargin;
    }
    @include mediaTo($size960) {
        margin-left: $mobileRowMargin;
        margin-right: $mobileRowMargin;
    }
}

@mixin gridPadding {
    @include mediaFrom($size960) {
        padding-left: $colPadding;
        padding-right: $colPadding;
    }
    @include mediaTo($size960) {
        padding-left: $mobileColPadding;
        padding-right: $mobileColPadding;
    }
}

@mixin gridMargin {
    @include mediaFrom($size960) {
        margin-left: $colPadding;
        margin-right: $colPadding;
    }
    @include mediaTo($size960) {
        margin-left: $mobileColPadding;
        margin-right: $mobileColPadding;
    }
}

@mixin standardList {
    padding: 0;
	margin: 0;
	list-style-type: none;
}

@mixin standardRowList {
    padding: 0;
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	margin-top: 0;
	margin-bottom: 0;
	@include rowMargin;
}

@mixin customList($margin, $padding, $type) {
	padding: $padding;
	margin: $margin;
	list-style-type: $type;
}

@mixin centerCover {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

@mixin animStyle($parameters) {
  	-o-transition: $parameters;
	-ms-transition: $parameters;
	-moz-transition: $parameters;
	-webkit-transition: $parameters;
	transition: $parameters;
}

@mixin animStyles($param1,$param2) {
	-o-transition: $param1,$param2;
  -ms-transition: $param1,$param2;
  -moz-transition: $param1,$param2;
  -webkit-transition: $param1,$param2;
  transition: $param1,$param2;
}

@mixin transStyle($parameters) {
	transform: $parameters;
	-moz-transform: $parameters;
    -ms-transform: $parameters;
    -webkit-transform: $parameters;
    -o-transform: $parameters;
}

@mixin torigin($parameters) {
	transform-origin: $parameters;
	-webkit-transfrom-origin: $parameters;
}

@mixin animDelay($parameters) {
	-o-transition-delay: $parameters;
	-ms-transition-delay: $parameters;
	-moz-transition-delay: $parameters;
	-webkit-transition-delay: $parameters;
	transition-delay: $parameters;
}

@mixin overHidden {
	position: relative;
	overflow: hidden;
}

@mixin psevdoAbs {
	display: block;
	content: "";
	position: absolute;
}

@mixin coverLayer {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

@mixin fixCoverLayer {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

@mixin coverMedia {
	display: block;
	@include coverLayer;
	object-fit: cover;
}

@mixin hiddenElement {
	position: absolute;
	width: 0;
	height: 0;
	top: 0;
	left: 0;
	visibility: hidden;
}

@mixin colCount($col) {
	-moz-column-count: $col;
    -webkit-column-count: $col;
    column-count: $col;
}

@mixin colGap($gap) {
    -webkit-column-gap: $gap;
    -moz-column-gap: $gap;
    column-gap: $gap;
}

@mixin customScroll($size, $mozWidth, $trackRadius, $barRadius, $trackBg, $barBg) {
	scrollbar-color: $barBg $trackBg;
	scrollbar-width: $mozWidth;
	&::-webkit-scrollbar {
		width: $size;
		height: $size;
		border-radius: $trackRadius;
		background:  $trackBg;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: $barRadius;
		background: $colorControlTabbarInactive;
	}
}

@mixin textOverflow($rowCount,$position) {
	position: $position;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: $rowCount;
	-webkit-box-orient: vertical;
}

@mixin popupBody($background, $opacity, $transition) {
	&:before {
		display: block;
		position: fixed;
		content: "";
		z-index: 999;
		top: 0;
		left: 0;
		width: 100%;
		background: $background;
		opacity: 0;
		height: 0;
		@include animStyles(height 0.05s, opacity $transition);
		@include animDelay($transition);
   }
   &.popup_opened {
	   overflow: hidden;
	   &:before {
			height: 100%;
			opacity: $opacity;
			@include animDelay(0s);
	   }
   }
}

@mixin popupBlock($transition) {
	position: fixed;
	left: 0;
	width: 100%;
	height: 100%;
	top: -100%;
	overflow: hidden;
	z-index: 10001;
	@include animStyle(top $transition);
	
}

@mixin popupInner($padding) {
	display: flex;
	width: 100%;
	min-height: 100%;
	justify-content: center;
	align-items: center;
	padding: $padding;
}

@mixin popupContainer($background,$maxWidth, $padding) {
	background: $background;
	width: max-content;
	max-width: $maxWidth;
	position: relative;
}

@mixin menuBtn($width, $height, $color, $padding, $lineSize, $lineWidth, $lineEdge, $lineLeft, $transition) {
	border: none;
	background: transparent;
	color: $color;
	padding: $padding;
	width: $width;
	height: $height;
	position: relative;
	overflow: hidden;
	z-index: 100;
	&:before,
	&:after {
		@include psevdoAbs;
		@include animStyle(all $transition);
		border-top: $lineSize solid;
		left: $lineLeft;
		width: $lineWidth;
	}
	&:before {
		top: $lineEdge;
	}
	&:after {
		bottom: $lineEdge;
	}
	span {
		display: block;
		border-top: $lineSize solid;
		@include animStyle(opacity 0.2s);
		@include animDelay($transition - 0.2);
	}
}

@mixin menuClose($rotateSize) {
	span {
		opacity: 0;
		@include animDelay(0s);
	}
	&:before {
		@include transStyle(rotate($rotateSize));
	}
	&:after {
		@include transStyle(rotate(-$rotateSize));
	}
}

@mixin standardCol($col) {
	flex: 0 0 math.div($col, 12) * 100%;
	max-width: math.div($col, 12) * 100%;
	@include gridPadding;
}

@mixin colSize($col) {
	flex: 0 0 math.div($col, 12) * 100%;
	max-width: math.div($col, 12) * 100%;
}

@mixin customCol($col, $maxWidth, $padding) {
	flex: 0 0 math.div($col, 12) * 100%;
	max-width: $maxWidth;
	padding-left: $padding;
	padding-right: $padding;
}

@mixin mobileMenu($position, $top, $background, $transition) {
	position: $position;
	overflow: hidden;
	top: $top;
	background: $background;
	left: 0;
	width: 100%;
	height: 0;
	@include animStyle(height $transition);
}

@mixin tabBlock($transition) {
	position: relative;
	@include animStyle(opacity $transition);
	&:not(.selected) {
		height: 0;
		opacity: 0;
		overflow: hidden;
	}
}

@mixin videoIcon($background, $color) {
	&:before {
		@include psevdoAbs;
		left: 50%;
		top: 50%;
		border-radius: 50%;
		background: $background;
		@include transStyle(translate3d(-50%,-50%,0));
	}
	&:after {
		@include psevdoAbs;
		left: 50%;
		top: 50%;
		border-style: solid;
		border-color: transparent $color;
		@include transStyle(translate3d(-50%,-50%,0));
	}
}

@mixin videoIconSize($size, $innerWidth, $innerHeight) {
	&:before {
		width: $size;
		height: $size;
	}
	&:after {
		width: $innerWidth;
		height: $innerHeight;
		border-width: math.div($innerHeight, 2) 0 math.div($innerHeight, 2) $innerWidth;
	}
}

@mixin proportional($proportionPersent) {
	@include overHidden;
	&:before {
		display: block;
		content: "";
		padding-bottom: $proportionPersent;
	}
	img, video, iframe {
		@include coverLayer;
	}	
	img, video {
		object-fit: cover;
	}
}

@mixin iconFont {
	&:before,
	&:after {
		font-family: 'icon' !important;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

@mixin noBlink {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
}

@mixin centerFlex {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}

@mixin linkTypeBtn {
	border: none;
	padding: 0;
	background: transparent;
}

@mixin siteColorHover {
	@include animStyle(color 0.3s);
	&:hover {
		color: $colorControlPrimaryHover;
	}
}

@mixin siteColorActive {
	@include animStyle(color 0.15s);
	&:active {
		color: $siteColor;
	}
}

@mixin siteColorAlfaHover {
	@include animStyle(color 0.3s);
	&:hover {
		color: rgba($colorControlPrimaryHover, 0.7);
	}
}

@mixin siteColorAlfaActive {
	@include animStyle(color 0.15s);
	&:active {
		color: rgba($siteColor, 0.7);
	}
}

@mixin siteBgHover {
	@include animStyle(background-color 0.3s);
	&:hover {
		background-color: $colorControlPrimaryHover;
	}
}

@mixin siteBgActive {
	@include animStyle(background-color 0.15s);
	&:active {
		background-color: $siteColor;
	}
}

@mixin siteBgAlfaHover {
	@include animStyle(background-color 0.3s);
	&:hover {
		background-color: $secondDarkColor;
	}
}

@mixin siteBgAlfaActive {
	@include animStyle(background-color 0.15s);
	&:active {
		background-color: $secondDarkColor;
	}
}

@mixin darkReverseHover {
	@include animStyle(all 0.3s);
	&:hover {
		background-color: $colorTextPrimary;
		color: $white;
		border-color: transparent;
	}
}

@mixin darkReverseActive {
	@include animStyle(all 0.15s);
	&:active {
		background-color: $colorTextPrimary;
		color: $white;
		border-color: transparent;
	}
}

@mixin lightReverseHover {
	@include animStyle(all 0.3s);
	&:hover {
		color: $colorTextPrimary;
		background-color: $white;
		border-color: transparent;
	}
}

@mixin lightReverseActive {
	@include animStyle(all 0.15s);
	&:active {
		color: $colorTextPrimary;
		background-color: $white;
		border-color: transparent;
	}
}

@mixin opacityHover {
	@include animStyle(opacity 0.3s);
	&:hover {
		opacity: 0.7;
	}
}

@mixin opacityActive {
	@include animStyle(opacity 0.3s);
	&:active {
		opacity: 0.7;
	}
}

@mixin siteDarkRHover {
	@include animStyle(all 0.3s);
	&:hover {
		background-color: $colorControlPrimaryHover;
		color: $colorTextPrimary;
		border-color: transparent;
	}
}

@mixin siteDarkRActive {
	@include animStyle(all 0.15s);
	&:active {
		background-color: $siteColor;
		color: $textColor;
		border-color: transparent;
	}
}

@mixin siteLightRHover {
	@include animStyle(all 0.3s);
	&:hover {
		color: $white;
		background-color: $colorControlPrimaryHover;
		border-color: transparent;
	}
}

@mixin siteLightRActive {
	@include animStyle(all 0.15s);
	&:active {
		color: $white;
		background-color: $siteColor;
		border-color: transparent;
	}
}

@mixin darkHover {
	@include animStyle(color 0.3s);
	&:hover {
		color: $textColor;
	}
}

@mixin darkActive {
	@include animStyle(color 0.15s);
	&:active {
		color: $textColor;
	}
}

@mixin lightHover {
	@include animStyle(color 0.3s);
	&:hover {
		color: $white;
	}
}

@mixin lightActive {
	@include animStyle(color 0.15s);
	&:active {
		color: $white;
	}
}

@mixin standardHover {
	@include animStyle(color 0.3s);
	&:hover {
		color: $colorIconsPrimary;
	}
}

@mixin standardActive {
	@include animStyle(color 0.15s);
	&:active {
		color: $colorTextLinkActive;
	}
}

@mixin standardBgHover {
	@include animStyle(background-color 0.3s);
	&:hover {
		background-color: $colorTextLinkActive;
	}
}

@mixin standardBgActive {
	@include animStyle(background-color 0.15s);
	&:active {
		background-color: $colorTextLinkActive;
	}
}

@mixin btnLightHover {
	@include animStyle(all 0.3s);
	&:hover {
		color: $colorBrandElectricblue;
		background: $colorConstantBlue50;
	}
}

@mixin btnLightActive {
	@include animStyle(all 0.15s);
	&:active {
		color: $siteColor;
		background: $colorConstantBlue50;
	}
}

@mixin gradientColor($gradient) {
	background-image: $gradient;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@mixin zIndex($index) {
	position: relative;
	z-index: $index;
}

@mixin popupBackLayer {
	&:before {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        content: "";
        background: $textColor;
        opacity: 0;
        @include animStyles(height 0s 0.5s, opacity 0.5s);
        z-index: 200;
    }
}

@mixin showedPopupBackLayer {
	&:before {
		height: 100%;
		opacity: 0.5;
		@include animStyles(height 0s 0s, opacity 0.5s);
	}
}

@mixin textUnSelect {
	-webkit-touch-callout: none; 
    -webkit-user-select: none;
     -khtml-user-select: none; 
       -moz-user-select: none; 
        -ms-user-select: none; 
            user-select: none;
}

@mixin pageTitleSize {
	line-height: 1.4em;
	@include mediaFrom($size1200) {
		font-size: $fontSize32;
	}
	@include mediaRange($size960, $size1200) {
		font-size: $fontSize32;
	}
	@include mediaRange($size768, $size960) {
		font-size: $fontSize32;
	}
	@include mediaRange($size576, $size768) {
		font-size: 280%;
	}
	@include mediaTo($size576) {
		font-size: 240%;
	}
}

@mixin sectionTitleSize {
    font-size: 280%;
    line-height: 1.4em;
    @include mediaRange($size576, $size960) {
        font-size: 240%;
    }
    @include mediaTo($size576) {
        font-size: 220%;
    }
}

@mixin titleSize22 {
    font-size: 22px;
    line-height: 28px;
    @include mediaRange($size576, $size960) {
        font-size: 20px;
    }
    @include mediaTo($size576) {
        font-size: 18px;
    }
}
@mixin titleSize20 {
    font-size: 20px;
    line-height: 26px;
    @include mediaRange($size576, $size960) {
        font-size: 18px;
    }
    @include mediaTo($size576) {
        font-size: 16px;
    }
}

@mixin sectionMediumTitleSize {
    font-size: $fontSize26;
    line-height: 32px;
    @include mediaRange($size576, $size960) {
        font-size: 26px;
    }
    @include mediaTo($size576) {
        font-size: 210%;
    }
}

@mixin subtitleSize {
	font-size: $fontSize18;
	line-height: 1.5em;
	@include mediaTo($size960) {
		font-size: 160%;
	}
}

@mixin standardText {
	font-size: $fontSize14;
	line-height: 1.5em;
}

@mixin montserratMedium {
	font-family: $medium;
}
@mixin montserratBold {
	font-family: $semibold;;
}

@mixin subtitleText {
	font-size: 22px;
	line-height: 28px;
	margin-bottom: 20px;
	@include mediaTo($size960) {
		font-size: $fontSize16;
	}
}

@mixin mediumText {
	font-size: $fontSize16;
	line-height: 22px
}

@mixin standardNarrow {
	font-size: $fontSize14;
	line-height: 20px
}

@mixin smallText {
	font-size: $fontSize12;
	line-height: 1.50em;
}

@mixin primaryBtn {
	border: none;
	background: $secondColor;
	color: $white !important;
	font-family: $medium;
	@include standardNarrow;
	border-radius: 10px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	vertical-align: top;
	&[disabled],
	&.disabled,
	&:disabled,
	&.inactive {
		pointer-events: none;
		opacity: 0.6;
	}
}

@mixin secondaryBtn {
	border: none;
	background: $siteLightColor;
	color: $siteColor;
	font-family: $medium;
	@include standardNarrow;
	border-radius: 10px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	vertical-align: top;
	&[disabled],
	&.disabled,
	&:disabled,
	&.inactive {
		pointer-events: none;
		opacity: 0.6;
	}
}

@mixin secondaryLight {
	border: none;
	background: transparent;
	text-decoration: underline;
	color: $siteColor;
	font-family: $medium;
	@include standardNarrow;
	border-radius: 10px;
	display: inline-block;
	vertical-align: top;
	&[disabled],
	&.disabled,
	&:disabled,
	&.inactive {
		pointer-events: none;
		opacity: 0.6;
	}
}

@mixin largeBtn {
	@include mediaFrom($size960) {
		padding: 16px 48px;
	}
	@include mediaTo($size960) {
		@include smallBtn;
	}
}

@mixin smallBtn {
	padding: 12px 24px;
}

@mixin skeleton {
    animation: 1.5s shine linear infinite;
    background: #eee;
    background: linear-gradient(110deg, #D9D9D9 8%, #f5f5f5 18%, #D9D9D9 33%);
    background-size: 200% 100%;
}

@mixin loading {
	pointer-events: none;
	-webkit-text-fill-color: transparent;
	position: relative;
	&:after {
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -4px -12px;
		border-radius: 50%;
		color: $white;
		display: block;
		width: 8px;
		height: 8px;
		content: "";
		animation: loadAnimation 1.6s infinite linear;
	}
}



@mixin mediaRange($from,$to) {
	@media screen and (min-width: $from) and (max-width: $to - 1) {
		@content
	} 
} 

@mixin mediaFrom($from) {
	@media screen and (min-width: $from) {
		@content
	} 
} 

@mixin mediaTo($to) {
	@media screen and (max-width: $to - 1) {
		@content
	} 
} 

@mixin mediaVRange($from,$to) {
	@media screen and (min-height: $from) and (max-height: $to - 1) {
		@content
	} 
} 

@mixin mediaVFrom($from) {
	@media screen and (min-height: $from) {
		@content
	} 
} 
@mixin mediaVTo($to) {
	@media screen and (max-height: $to - 1) {
		@content
	} 
} 

@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}  
}

@mixin tableDesign {
		border: none;
		border-radius: $borderRadius12;
		@include mediumText;

		thead {
			th {
				text-align: start;
				background: $colorControlTableHeader;

				&:first-child {
					border-top-left-radius: $borderRadius12;
				}

				&:last-child {
					border-top-right-radius: $borderRadius12;

				}
			}
		}

		td, th {
			border: 1px solid $colorBackgroundSurfaceStroke;
			padding: 13px 16px;
		}

		tbody tr {
			&:last-child {
				td, th {
					&:first-child {
						border-bottom-left-radius: $borderRadius12;
					}

					&:last-child {
						border-bottom-right-radius: $borderRadius12;
					}
				}
			}
		}

		&:not(:has(thead)) tbody tr {
			&:first-child {
				td {
					&:first-child {
						border-top-left-radius: $borderRadius12;
					}

					&:last-child {
						border-top-right-radius: $borderRadius12;
					}
				}
			}
		}
}

