@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.header {
    .search_block {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        order: 1;
        &.opened {
            .search_inner {
                top: 0;
            }
        } 
    }
    .search_inner {
        left: 0;
        right: 0;
        height: 32px;
        z-index: 20;
        background: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include animStyle(top 0.3s);
    }
    .search_form {
        height: 32px;
        flex: 1;
        display: flex;
        align-items: center;
        border-radius: $borderRadius8;
        background: $colorBackgroundSurfaceSecondary;
        border: 1px solid $colorControlBorderDefault;
    }
    .search_field {
        display: flex;
        flex: 1;
        @include placeholder {
            color: $gray66;
        }
        input {
            border: none;
            background: transparent;
            @include mediumText;
            color: $colorTextSecondary;
            height:100%;
            width: 320px;
        }
    }
    .search_btn {
        border: none;
        min-width: 32px;
        height: 32px;
        background: transparent;
        color: $colorIconsTertiary;
        border-radius: 50%;
        padding: 0;
        font-size: 160%;
        &:before {
            display: block;
        }
    }
    .search_close {
        border: none;
        background: $colorBackgroundSurfaceSecondary;
        padding: 10px;
        font-size: 11px;
        color: $gray99;
        width: 32px;
        height: 32px;
        border-radius: $borderRadius8;
        margin: 0 0 0 10px;
        &:before {
            display: block;
        }
    }
    @include mediaTo($size960) {
        .search_block {
            width: 28px;
            margin-left: 8px;
            z-index: 102;
            .action_btn:after {
                @include psevdoAbs;
                top: -6px;
                right: -4px;
                bottom: -6px;
                left: -4px;
            }
        }
        .search_inner {
            padding-left: $mobileContainerPadding;
            padding-right: $mobileContainerPadding;
        }
        .search_form {
            margin: 4px;
        }
        .search_field input {
            font-size: 120%;
            line-height: 14px;
            padding: 9px 16px;
            height: 32px;
        }
        .search_btn {
            margin: 0;
            border-radius: 8px;
            font-size: 160%;
        }
        .search_close {
            padding: 12px;
            margin-right: -12px;
            font-size: 120%;
        }
    }
}

.web {
    .search_btn {
        @include btnLightHover;
    }
    .search_close {
        @include standardHover;
    }
}

.touch {
    .search_btn {
        @include standardBgActive;
    }
    .search_close {
        @include standardActive;
    }
}