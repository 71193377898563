.rs-picker-daterange-menu .rs-calendar:first-child {

    border-right: 0;
}


.rs-picker-toolbar{
    display: none;
}

.rs-calendar-header-forward, .rs-calendar-header-backward  {
    /* display: none; */
}

.date-picker-trigger-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px 10px 16px;
    gap: 8px;

    background: #EFF3F6;
    border-radius: 40px;

    flex: none;
    order: 2;
    flex-grow: 0;

}
.rs-picker-menu {
    position: absolute;
    left: 9% !important;
    top: 54% !important;
}

.date-picker-trigger-btn-open {
    box-sizing: border-box;

    /* Auto layout */
    
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px 10px 16px;
    gap: 8px;
 
    background: #FFFFFF;
    /* brand_blue */
    
    border: 1px solid #007DC3;
    border-radius: 40px;
    
    /* Inside auto layout */
    
    flex: none;
    order: 2;
    flex-grow: 0;
}

.rs-calendar-table-cell-day {

}


.rs-calendar-table-cell-content {
    border-radius: 50%;

}

.rs-calendar-table-cell-selected-start {
    display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 0px;

width: 38px;
height: 38px;

/* brand_blue */

background: #007DC3;
border-radius: 38px;

/* Inside auto layout */

flex: none;
order: 5;
flex-grow: 0;
z-index: 5;
}


/* @media screen and (min-width: 1197px) {
    .rs-picker-menu {
        left: 16% !important;
        top: 49% !important;
    }
 } */