@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';
@import '../uiKit/styles/mixins';


.without_margin{
    margin-top: 0!important;
}
.calc_section {
    margin-top: 140px;
    padding-bottom: 80px;
.nodata_hint{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    &::before{
        content: "";
        position: absolute;
        width: 100%;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        background: $white;
        opacity: 0.9;
        border-radius: 10px;
        z-index: 2;
        
    }
    span{
        @include subtitleSize;
        font-family: $medium;
        position: relative;
        z-index: 3;
        padding: 0 15px;
        text-align: center;

    }
}
    .section_title {
        text-align: center;
        font-family: $medium;

    }

    .section_inner {
        margin-top: 24px;
        border: 1px solid $colorBackgroundSurfaceStroke;
        border-radius: $borderRadius16;
        display: flex;
        justify-content: space-between;
        padding: 0;
        position: relative;
    }

    .calc_options {
        @include customCol(7, 570px + 2 * 0, 0px);
        margin: 40px;
        overflow: hidden;
    }

    .calc_results {
        @include colSize(5);
        background: $white;
        //border: 1px solid $colorBackgroundSurfaceStroke;
        border-radius: inherit;
        display: flex;
        flex-direction: column;
        justify-content: start;
        box-shadow: $boxShadowLow;
        padding-bottom: 40px;
        position: absolute;
        right: 0;
        width: 100%;
        height: calc(100% + 40px);
        .calc_results_wrapper{
            border-radius: inherit;
            box-shadow:  0 -10px 16px 0 rgba(13, 43, 150, 0.08);
            background: white;
            //border: 1px solid $colorBackgroundSurfaceStroke;
            border-bottom: none;
            padding: 40px;
            display: flex;
            height: 100%;

            flex-direction: column;
            justify-content: start;
            position: relative;
        }
    }

    .results_title {
        color:$colorTextPrimary;
        @include subtitleText;
        font-family: "NotoSansArmenian-SemiBold", sans-serif;
        font-family: $medium;

    }

    .calc-options {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 1em;
        padding: 44px 56px;

    }

    .type_btns {
        display: flex;
        margin-bottom: 28px;
        overflow-x: scroll;
        width: 100%;
        &::-webkit-scrollbar {
            width: 0;
            background: transparent;
        }
        button {
            border: none;
            padding: 0 0 16px;
            text-align: left;
            font-size: 16px;
            color: $colorTextPrimary;
            background: transparent;
            margin-bottom: -1px;
            border-bottom: 1px solid transparent;

            &.selected {
                color: $colorTextPrimary;
                border-bottom: 2px solid $colorControlTabPressed;
                pointer-events: none;
            }

            &:not(:first-child) {
                margin-left: 24px;
            }
        }
    }

    .options_title {
        @include standardText;
        color: $colorTextSecondary;
    }

    .amount-wrapper{
        box-shadow: $boxShadowLow;
        padding: 12px 16px;
        border-radius: $borderRadius12;
        margin: 10px 0 20px;
    }

    .currency_options {
        display: flex;
        align-items: start;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 7px;

        .options_list {
            display: flex;
            align-items: center;
            border-radius: 10px;
            margin: 20px 0 10px;

            button {
                background: none;
                border: none;
                font-size: 14px;
                line-height: 20px;
                border-radius: 10px;
                padding: 6px 12px;
                min-width: 28px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;

                &:not(:first-child) {
                    margin-left: 4px;
                }
                &:last-child {
                    order: 1;
                }
                &:nth-child(2) {
                    order: 3;
                }
                &:first-child {
                    order: 2;
                }

                &.selected {
                    background: $white;
                    pointer-events: none;
                    color: $colorTextPrimary;
                    font-size: 14px;
                    border-radius: 10px;
                    box-shadow: $boxShadowLowest;
                }

                &:disabled:not(.selected) {
                    pointer-events: none;
                    opacity: 0.5;
                    color: $textColor;
                }
            }
        }
    }

    .duration_options {
        .options_title {
            @include standardText;
            color: $colorTextPrimary;
            font-family: $fontFamilySemiBold;
            margin-bottom: 12px;
        }
        .options_list {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
        }

        button {
            background: none;
            border-radius: $borderRadius8;
            @include standardNarrow;
            color: $colorTextSecondary;
            justify-content: center;
            padding: 3px 8px 5px 8px;
            margin:  0;
            border: 1px solid transparent;
            font-family: $fontFamilyBold;
            font-family: "NotoSansArmenian-SemiBold", sans-serif;

            &.selected {
                box-shadow: $boxShadowLow;
                color: $colorAccentActive;
                background: $white;
                pointer-events: none;
            }

            &:disabled:not(.selected) {
                color: $textColor;
                pointer-events: none;
                opacity: 0.5;
            }
        }
    }

    .amount_block {
        .rc-slider.single-thumb.rc-slider-horizontal{
            margin-top: 30px;
        }
    }

    .amount_size {
        display: block;
        position: relative;
        svg{
            position: absolute;
            top: 17px;
            right: 14px;
            cursor: pointer;
        }
    }

    .showed_amount {
        position: absolute;
        pointer-events: none;
        left: 19px;
        top: 19px;
        font-size: 16px;
        line-height: 8px;
        color: $colorTextPrimary;
        .showed_size {
            visibility: hidden;
            color: $colorTextSecondary;
        }
    }

    #amount-number {
        background: $white;
        border: 1px solid $colorAccentActive;
        border-radius: $borderRadius12;
        height: 48px;
        width: 100%;
        @include standardText;
        font-family: $semibold;
        color: $black33;
        padding: 7px 18px;
        caret-color: $colorAccentActive;
    }

    .rc-slider-track{
        margin-top: -8px;
        background: $colorControlPrimary;
        @include animStyle(all 0.15s linear);

    }

    .rc-slider-handle-dragging {
        background-color: $colorControlPrimary;
        box-shadow: none !important;
        border-color: $colorControlPrimary !important;
    }
    .rc-slider-handle {
        margin-top: -15px !important;
        position: absolute;
        width: 18px;
        height: 18px;
        background: white;
        box-shadow:  0 0 2px rgb(0 0 0 / 15%);;
        border: 4px solid $colorControlPrimary;
        outline: 1px solid $colorControlPrimary;
        outline-offset: -1px;
        opacity: 1;
        @include animStyle(all 0.15s linear);

        &[data-lower] {
            width: 0;
            visibility: hidden;
        }
        &:hover {
            background-color: white;
            box-shadow: none;
            border-color: $colorControlPrimaryHover;
        }

    }
    .rc-slider .rc-slider-rail {
        margin-top: -8px;

        height: 4px;
        background: $colorControlInactive;
        border-radius: 2px;
        z-index: 0;

    }

    .range_hints {
        margin-top: 8px;
        display: flex;

        >span {
            display: block;
            flex: 2;
            text-align: center;
            @include smallText;
            color: $colorTextSecondary;

            &:first-child,
            &:last-child {
                flex: 1;
            }

            &:first-child {
                text-align: left;
            }

            &:last-child {
                text-align: right;
            }
        }
    }

    .switch_btns {
        margin-top: 28px;

        .switch_check {
            &:not(:first-child) {
                margin-top: 16px;
            }
        }
    }

    .actions_block {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
        padding: 0 40px;
        flex-direction: column;
        .see_btn {
            @include secondaryLight;
            @include smallBtn;
            @include gridMargin;
            white-space: nowrap;
        }

        .primary_btn {
            width: 80%;
            @include gridMargin;
        }
    }

    .results_list {
        @include standardList;

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            //border-bottom: 1px solid $grayF5;
            padding: 15px 0;
            @media (max-width: $size480) {
             flex-direction: column;
                align-items: start;
                justify-content: start;
                span{
                    &:last-child {
                        padding: 0!important;
                        margin: 0;

                    }
                }

            }
            span {
                @include colSize(6);
            }

            &:nth-child(3) {
                span:last-child {
                    @include iconFont;
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    flex-direction: row-reverse;

                }
            }

            >span {
                &:first-child {
                    text-wrap: nowrap;
                    @include mediumText;
                    color: $colorTextSecondary;
                }

                &:last-child {
                    color: $colorTextPrimary;
                    font-family: "NotoSansArmenian-SemiBold", sans-serif;
                    @include subtitleSize;
                    padding-left: 10px;
                    text-align: right;
                    white-space: nowrap;
                    display: flex;
                    justify-content: flex-end;
                }
            }
            &:last-child{
                border-top: 1px solid $colorBackgroundSurfaceStroke;
                span{
                    color: $colorTextPrimary;
                    &:first-child{
                        margin-top: 12px;
                    }
                }
            }
        }
    }

    @include mediaRange($size960, $size1200) {
        margin-top: 120px;

        .calc_options {
            margin: 32px 0;
            padding: 0 32px;
        }

        //.calc_results {
        //    padding: 32px;
        //}
    }

    @include mediaTo($size960) {
        margin-top: 30px;

        .calc_options {
            margin: 20px 0;
            padding: 0 16px;
        }

        //.calc_results {
        //    padding: 20px 16px;
        //}

        .type_btns {
            align-items: flex-start;
            margin-bottom: 0;
            padding-bottom: 0;
            gap: 20px;

            button {
                padding: 6px 0;
                border: none;

                &:not(:first-child) {
                    margin-left: 0;
                }

                &.selected {
                    font-family: $medium;
                }
            }
        }

        .results_list li {
            padding: 16px 0;
            @media (max-width: $size480) {
                padding: 10px 0;
            }
            >span {
                @include colSize(6);
            }
        }

        .actions_block {
            flex-direction: column;

            .primary_btn {
                margin-right: auto;
            }
        }

        .results_title {
            margin-bottom: 20px;
        }

        .make_btn {
            margin-top: 10px;
        }
    }

    @include mediaRange($size640, $size1200) {

        .calc_options,
        .calc_results {
            @include colSize(6);
        }
    }

    @include mediaTo($size640) {
        .section_inner {
            flex-wrap: wrap;
        }

        .calc_options,
        .calc_results {
            @include colSize(12);
        }
    }

    //Tooltip text container

    .tooltip_block {
        position: relative;
        font-family: $regular;

        .tooltip_info {
            position: absolute;
            opacity: 0;
            bottom: unset;
            top: 35px;
            margin-bottom: 5px;
            right: -116px;
            background: $colorBackgroundSurfacePrimary;
            box-shadow: 0 0 16px 0 #223b9429;;
            width: 256px;
            border-radius: $borderRadius6;
            padding: 8px  12px  8px 12px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            white-space: initial;
            text-align: initial;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            @include animStyle(opacity 0.3s);

            @include mediaTo($size1366) {
                right: -30px;
            }

            @include mediaTo($size960) {
                right: -10px;
            }

            @include mediaTo($size480) {
                right: -10px;
            }

            &:after {
                display: block;
                content: "";
                position: absolute;
                left: 53%;
                margin-left: -15px;
                top: -10px;
                width: 17px;
                height: 11px;
                border-style: solid;
                transform: rotate(180deg);
                border-width: 9px 9px 0;
                border-color: $white transparent;
                z-index: 9;

                @include mediaTo($size1366) {
                    left: 85%;
                }

                @include mediaTo($size960) {
                    left: 93%;
                }

                @include mediaTo($size480) {
                    left: 93%;
                }
            }
        }

        .icon_info_calc {
            position: relative;
            z-index: 1;
            background: none;
            color: $colorIconsSecondary;
            border: none;
            font-size: 20px;
            line-height: 1.4em;
            padding-right: 0;
        }


        .icon_close {
            background: none;
            border: none;
            color: $gray99;
            font-size: 14px;
            line-height: 1.4em;
            position: absolute;
            top: 16px;
            right: 13px;
            &:before{
                display: none;
            }
        }

        .text_tooltip {
            font-size: 13px;
            line-height: 1.4em;
            color: $black33;
            padding: 8px 25px 0 0;
        }
    }



    .app_inner {
        @include standardRowList;
        align-items: center;
        padding-bottom: 73px;

        @include mediaTo($size960) {
            justify-content: space-between;
        }

        @include mediaTo($size768) {
            justify-content: center;
        }
    }

    .app_left {
        // @include standardCol(5);
        margin-left: auto;
        .page_row {
            justify-content: center
        }
        .app_wrapper{
            padding: 4px;
            >div{
                text-align: start;

            }
            .page_row{
                justify-content: start;
            }
        }
    }

    .app_right {
        @include gridPadding;
        @include standardCol(5);
        // &:before{
        //     content: "";
        //     background: $siteColor;
        //     position: absolute;
        //     height: 670px;
        //     right: 0;
        //     border-radius: 100% 0 0;
        //     z-index: -1;
        //     width: 580px;
        // }

        // &:after{
        //     content: "";
        //     background: $secondColor;
        //     position: absolute;
        //     height: 100%;
        //     right: -60px;
        //     border-radius: 55%;
        //     z-index: -1;
        //     width: 537px;
        //     bottom: -62%;
        // }
    }

    .app_form {
     //   @include gridPadding;
        display: block;
    }

    .img_mobile {
        flex: 1;
        max-width: 301px;
        position: relative;
        margin-top: 40px;
        padding-top: 63px;

        @include mediaTo($size960) {
            width: 100%;
            max-width: unset;
            margin-top: 0;
        }

        img {
            display: block;
            height: auto;
            width: auto;
            max-width: 370px;

            @include mediaTo($size960) {
                width: 100%;
                max-width: unset;
            }
        }
    }

    .app_description {
        @include standardText;
        color: $black33;
        margin: 10px 0 40px;
    }

    @include mediaTo($size960) {
        .app_left {
            margin-left: 0
        }
    }

    @include mediaTo($size768) {
        .app_left {
            @include colSize(12);
            padding-top: 40px;
        }
    }

}
.sk_parent{
    display: flex;
    .loans_calculator_sk_first{
        list-style-type: none;
        width: 100%;
        padding: 0;
        display: flex;
        gap: 20px;
        margin: 20px 0;
        justify-content: end;
        @include standardList;
        flex-wrap: wrap;
        li{
            display: block;
            position: relative;
            &:nth-child(1) {
                height: 440px;
                width: 100%;
            };
            &:after{
                border-radius: 20px;
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                @include skeleton;
            }
        }

    }
    .loans_calculator_sk_second{
        list-style-type: none;
        width: 100%;
        padding: 0;
        display: flex;
        gap: 20px;
        margin: 20px 0;
        justify-content: end;
        @include standardList;
        flex-wrap: wrap;
        li{
            display: block;
            position: relative;
            &:nth-child(1){
                width: 95%;
                height: 140px;
            };
            &:nth-child(2) {
                height: 285px;
                width: 95%;
            }

            &:after{
                border-radius: 20px;
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                @include skeleton;
            }
        }

    }
}

.tooltip_open {
    .tooltip_block .tooltip_info {
        opacity: 1;
    }
}

.web .calc_section {

    .tooltip_block .icon_close,


    .switch_btn .slider.round {
        @include opacityHover;
    }

    .calc_footer .see_btn {
        @include standardHover;
    }
    .tooltip_block .icon_info_calc {
        @include LinkHover;
    }

    .download_btn {
        @include btnLightHover;
    }
}

.touch .calc_section {

    .tooltip_block .icon_close,
    .options_list button {
        @include siteColorActive;
    }

    .switch_btn .slider.round {
        @include opacityActive;
    }

    .tooltip_block .icon_info_calc,
    .type_btns button,
    .calc_footer .see_btn {
        @include standardActive;
    }

    .download_btn {
        @include btnLightActive;
    }
}

.single-thumb {
    // margin-top: -4px;
}
@media (max-width: $size991) {
    .section_inner{
        flex-direction: column;
        .calc_options,.calc_results{
            position: relative;
            width: 100%;
            max-width: unset;
        }
        .calc_results_wrapper{
            padding-bottom: 0!important;
        }
    }
    .calc_section{
        padding-bottom: 40px;
    }
    .loan_calculator_section{
        .section_inner{
            padding: 24px 16px!important;
            flex-direction: column-reverse;
            .section_left{
                width: 100% !important;
            }
            .section_right{
                width: 100% !important;
                text-align: center;
                 .check_application_status .check_application_form .field_block .field_inner{
                    max-width: unset!important;
                     button{
                         width: 48px!important;
                     }
                }
            }
        }
    }
}
@media (max-width: $size640) {
    .calc_section .tooltip_block .tooltip_info {
        right: unset;
        left: -50px;
        &:after{
            left: 28%;
        }
    }
    .calc_section .results_title {
        margin-bottom: 5px;
    }
    .calc_section{
        padding-bottom: 28px;
        .calc_results{
            transform: unset;
            padding-bottom: 24px;
            .calc_results_wrapper{
                padding: 20px 20px 0;
            }
        }
        .results_list li{
            flex-direction: column;
            align-items: start;
            padding: 0 0 10px;
            span{
                padding-left: 0!important;
            }
        }
    }

}