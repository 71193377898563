.pagination{
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;

  li{
    list-style: none;
    width: 40px;
    height: 32px;
    display: flex;
    font-weight: 400;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 8px;
    transition: 0.3s ease-out;
    a{
      color: #070B11;

    }
    &:hover,&.selected{
      background: #3F00FF;
      a{
        color: white;
        svg{
          path{
            stroke: white;
          }
        }
      }
    }

  }
}