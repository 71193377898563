@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.banner_block {
    position: relative;
    padding: 64px 0 80px;
    background: $colorConstantGrey50;
    @include mediaTo($size991){
        padding: 56px 0 ;
    }
    .home_banner_inner{
        border-radius: $borderRadius20;
        position: relative;
        background:$colorBrandDarkblue;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .banner_img{
            width: 100%;
            min-height: 300px;
            display: flex;
            position: relative;
            overflow: hidden;
            img {
                border-top-right-radius: $borderRadius20;
                border-bottom-right-radius: $borderRadius10;
                width: 100%;
                object-fit: cover;
            }
        }

        .banner_info{
            width: 100%;
            padding: 0 64px;
            z-index: 2;
            display: flex;
            justify-content: center;
            flex-direction: column;
            a{
                margin-top: 24px;
            }
        }

        .section_title { 
            color: $colorTextInverted;
            max-width: 540px;
            @include textOverflow(2, relative);
        }
        .banner_description{
            @include mediumText;
            max-width: 540px;
            color: $colorTextInverted;
            @include textOverflow(3, relative);
            p{
                margin: 0;
                padding: 0;
                @include textOverflow(3, relative);
            }
        }
        .primary_btn{
            align-self: flex-start;
            margin-top: 24px;
        }
    }
    @include mediaRange($size960, $size1200) {
        .home_banner_inner {
            .banner_info {
                padding: 0 48px;
            }
        }
        
    }
    @include mediaRange($size576, $size960) {
        margin-bottom: 60px;
        .home_banner_inner {
            .banner_info {
                padding: 0 32px;
            }
            .banner_img {
                min-height: 270px;
            }
        }
    }
    @include mediaTo($size576) {
        margin-bottom: 40px;
        .home_banner_inner {
            .banner_info {
                padding: 0 16px;
            }
            .banner_img {
                min-height: 240px;
            }
        }
    }
}
@media (max-width: $size991) {
    .banner_block .home_banner_inner {
        border-radius: 20px;
        position: relative;
        background: #002366;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        .banner_img{
            border-top-left-radius: $borderRadius10;
            border-bottom-right-radius: unset;
            img{
                border-bottom-right-radius: unset;
            }
        }
        .banner_info{
            padding: 50px;
        }
    }
}