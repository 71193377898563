@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.section_description{
  @include standardText;
  color: $colorTextSecondary;
  margin: 0;
}

.loan_calculator_section{
  padding-bottom: 40px;
  .section_inner{
    gap: 10%;
    padding: 64px;
     .amount-wrapper{
       box-shadow: unset;
      margin-top: 0;
       padding: 0;
       .section_title{
         text-align: start;
         @include sectionMediumTitleSize;
         color: $colorTextPrimary;
         margin-bottom: 8px;
       }

       .options_list{
         button{
           order: unset;
         }
       }
       .currency_options{
         margin-top: 24px;
       }
    }
    .section_left{
      width: 60%;
    }
    .section_right{
      width: 40%;
      img{
        max-width: 445px;
        max-height: 275px;
      }
      .check_application_status{
        box-shadow: $boxShadowLow;
        padding: 24px;
        border-radius: $borderRadius12;
        margin-bottom: 32px;
        .section_title{
          text-align: start;
          @include titleSize20;
        }
        .check_application_form{
          .field_block{
            margin-bottom: 0;
            .field_inner {
              display: flex;
              align-items: center;
              position: relative;
              max-width: 400px;
              margin-top: 16px;
              label {
                width: 100%;
                margin-right: 12px;
                input {
                  height: 48px;
                  width: 100%;
                  padding: 13px;
                  color: $colorTextSecondary;
                  font-size: 16px;
                  line-height: 18px;
                  background: $colorBackgroundSurfacePrimary;
                  border: 1px solid $colorBackgroundSurfaceStroke;
                  border-radius: 10px;

                  &::-webkit-outer-spin-button,
                  &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                  }
                }
              }
              .check_button {
                height: 48px;
                width: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 15.9px;
                svg{
                  margin-right: unset;
                }
              }
            }
          }
        }
      }
    }
  }
}
.loans_section{
  margin-top: 36px;
  margin-bottom: 40px;
  .section_description{
    margin-bottom: 32px;
  }
  .loans-wrapper{
    display: flex;
    flex-direction: column;
    gap: 32px;
    .loan{
      display: flex;
      background: #EFF1FA;
      padding: 32px 40px;
      border-radius: $borderRadius12;
      gap: 48px;
      .left_wrapper{
        img{
          max-height: 550px;
        }
      }
      .right_wrapper{
        width: 100%;
        .loan_title{
          @include subtitleText;
          color: $colorTextPrimary;
          font-family: "NotoSansArmenian-SemiBold", sans-serif;
          font-family: $regular;
        }
        .loan_description{
          @include mediumText;
          color: $colorTextSecondary;
          margin-bottom: 24px;
        }
        .loan_body{
          border-bottom: 1px solid $colorBackgroundSurfaceSecondary;
          padding-bottom: 16px;
          @include smallText;
          ul{
            display: flex;
            gap: 48px;
            flex-wrap: wrap;
            padding-left: 20px;
            li{
              @include mediumText;
              color: $colorTextPrimary;
              width: max-content;
              font-family: "NotoSansArmenian-SemiBold", sans-serif;
            }
          }
        }
        .loan_buttons{
          padding: 16px 0 0;
          display: flex;
          justify-content: end;
          align-items: center;
        }
      }
    }


  }
  .loans_sk{
    list-style-type: none;
    width: 100%;
    padding: 0;
    display: flex;
    gap: 20px;
    margin: 20px 0;
    justify-content: end;
    @include standardList;
    flex-wrap: wrap;
    li{
      display: block;
      position: relative;
      &:nth-child(1) {
        height: 180px;
        width: 25%;
      };
      &:nth-child(2){
        width: 70%;
        height: 200px;
      };
      &:nth-child(3) {
        height: 60px;
        width: 300px;
        justify-content: end;
      }

      &:after{
        border-radius: 20px;
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        @include skeleton;
      }
    }

  }
}
@media (max-width: $size991) {
  .loan{
    flex-direction: column;
    .loan_buttons{
      flex-direction: column;
      gap: 20px;
    }
  }
}