@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.chakra-accordion.default{
  .chakra-accordion__item{
    &:not(:last-child){
      margin-bottom: 32px;
    }
    .chakra-accordion__button{
      justify-content: space-between;
      padding: 32px;
      border: 1px solid $colorBackgroundSurfaceStroke;
      border-radius: $borderRadius20;
      background: white;
      align-items: center;
      .button-content{
        gap: 16px;
        align-items: center;

        .title{
          @include titleSize22;
          font-family: "NotoSansArmenian-SemiBold", sans-serif;
          margin: 0;
        }
        svg{
          width: 30px;
        }
      }
      >svg{
        width: 32px;
        height: 32px;
        path{
          stroke: $colorTextPrimary;
          stroke-width: 0;
        }
      }
    }
    .chakra-accordion__panel {
      padding: 0 32px 32px;
      @include mediumText;
      color: $colorTextSecondary;
      .description{
        p{
          margin: 0;
        }
      }
    }
    table{
      @include tableDesign;
    }


  }

}
.chakra-accordion__item:has(button[aria-expanded="true"]) {
  box-shadow: 0 4px 16px 0 #0D2B9614 !important;
  border-radius: $borderRadius20;
}

.chakra-accordion__item button[aria-expanded="true"]{
  border: 1px transparent!important;
}