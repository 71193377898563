@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.breadcrumbs {
    padding: 0 0 5px;
    position: relative;
    z-index: 1;
    margin: 20px $rowMargin*2 36px;
    @include mediaTo($size960) {
        display: none;
    }

    ul {
        @include standardList;
        @include standardNarrow;
        display: flex;
        align-items: center;
    }

    li {
        display: flex;
        align-items: center;

        a,
        div {
            display: block;
            max-width: 400px;
            white-space: nowrap;
            @include overHidden;
            text-overflow: ellipsis;

        }

        &:not(:first-child):before {
            margin: 0 5px;
            content: "/";
            width: 8px;
            height: 18px;
            color: $gray99;
        }
        &:last-child{
          a{
              color: $colorTextPrimary;
              cursor: inherit;
          }
        }
    }

    a {
        color: $colorTextSecondary;
        @include siteColorHover;

    }

    @include mediaTo($size768) {
        li {
            &:nth-child(n+2):not(:nth-last-child(-n+2)) {
                display: none;

            }

            &:first-child:nth-last-child(n+4) {
                &:after {
                    margin: 0 3px;
                    content: '/ ...';
                    display: block;
                }
            }
        }
    }
}