@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.field_block {
    margin-bottom: 30px;
    .label_text {
        background: $white;
        color: $colorTextSecondary;
        @include standardText;
        display: block;
        padding: 0 4px;
        margin-bottom: 4px;
        pointer-events: none;
        @include animStyle(all 0.3s);

        &.top {
            top: -24px;
            left: 0;

        }
    }
}
.displayNone{
    display: none!important;
}
.otp-wrapper{
    .type_field{
        input{
            height: 49px;
            font-size: 14px;

            &:focus {
                border-color: $colorControlPrimaryDefault;

                ~ .psevdo_ph {
                    top: -10px;
                    color: $colorControlPrimaryDefault;
                    font-size: 14px;
                }
            }
        }
        span{
            font-size: 14px;

        }
    }
    .type_field .psevdo_ph.top{
        top: -10px;
        font-size: 14px;
        line-height: 1.5em;
    }
    .type_field .psevdo_ph{
        font-size: 14px;
    }
}
.type_field {
    display: block;
    position: relative;
    margin-top: 5px;
    svg {
        position: absolute;
        top: 17px;
        left: 16px;
        pointer-events: none;
        ~ input {
            text-indent: 35px;
        }
    }
    input,textarea {
        @include mediumText;
        background: transparent;
        border: 1px solid $colorControlBorderDefault;
        border-radius: $borderRadius12;
        color: #333;
        font-family: inherit;
        padding: 13px;
        @include animStyle(border-color 0.3s);

        &:focus {
            border-color: $colorControlBorderFocus;

            ~ .psevdo_ph {
                top: -24px;
                left: 0;
                color: $colorTextSecondary;
            }
        }
    }
    textarea{
        height: 135px;
    }

    .psevdo_ph {
        background: $white;
        color: $colorTextSecondary;
        @include standardText;
        display: block;
        padding: 0 4px;
        position: absolute;
        top: 16px;
        left: 12px;
        pointer-events: none;
        @include animStyle(all 0.3s);

        &.top {
            top: -24px;
            left: 0;

        }
    }

    .label_text {
        background: $white;
        color: $colorTextSecondary;
        @include standardText;
        display: block;
        padding: 0 4px;
        margin-bottom: 4px;
        pointer-events: none;
        @include animStyle(all 0.3s);

        &.top {
            top: -24px;
            left: 0;

        }
    }
    .field_clear{
        width: 12px;
        height: 12px;
        position: absolute;
        top: 18px;
        right: 12px;
        border: none;
        background: transparent;
        padding: 0;
        color: $colorIconsSecondary;
    }
}
.type_has_error{
    .psevdo_ph{
        font-size: 14px;
    }
}
.chakra-form__label.type_field.form_description{
    margin-top: 30px;
}
.field_block .type_field .error-icon{
    top: 38px;
}
input::placeholder{
    font-size: 13px;
    line-height: 1.50em;
}
.field_block .success-icon{
    top:37px
}