@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.footer {
    background: $colorBackgroundSurfaceFooter;
    padding: 24px 0 40px;
    color: $colorTextSecondary;
    overflow: hidden;
    z-index: 11;
    position: relative;
    .top_footer {
        display: flex;
        flex-wrap: wrap;
        @include rowMargin;
        .footer_menues{
            .footer_menu{
                width: 66.6%;
            }
            .download_app{
                display: flex;
                align-items: center;
                margin: 40px 0 20px;
                .image_wrapper{
                    width: 44px;
                    height: 44px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: $colorBrandElectricblue;
                    border-radius: $borderRadius8;
                    margin-right: 10px;
                }
                .texts_wrapper{
                    display: flex;
                    flex-direction: column;
                    .text_top{
                        font-size: 16px;
                        font-family: "NotoSansArmenian-SemiBold", sans-serif;
                        color:  $colorTextPrimary;
                        margin-bottom: 8px;
                    }
                    .text_bottom{
                        font-size: 16px;
                        color:  $colorTextLinkDefault;
                    }
                }
            }
        }
    }

    .developer {
        font-size: 120%;
        line-height: 15px;
        color: $white;
        margin-top: 7px;

        a {
            color: inherit;
        }
    }

    &_menues {
            flex: 0 0 100%;
            max-width: 100%;
            display: flex;
            align-items: flex-start;
    }

    .menu_title {
        font-size: $fontSize16;
        line-height: 20px;
        display: inline-block;
        margin-bottom: 15px;
        color: $colorTextPrimary;
        font-family: "NotoSansArmenian-SemiBold", sans-serif;
        margin-top: 40px;
    }

    &_about_menu {
        @include gridPadding;
        flex: 1;
        max-width: 33.3%;
        .menu_list {
            min-width: 300px;
            @include standardList;

            li {
                margin-bottom: 15px;
                max-width: 232px;
            }

            a {
                font-size: $fontSize16;
                line-height: 20px;
                color: inherit;
                display: inline-block;
                vertical-align: top;
            }
        }
    }

    &_menu {
        .menu_list {
            @include standardList;
            @include colCount(2);

            li {
                margin-bottom: 15px;
                display: inline-block;
                vertical-align: top;
                width: 100%;
                max-width: 290px;
                @include gridPadding;
            }

            a {
                font-size: $fontSize16;
                line-height: 20px;
                color: inherit;
                display: inline-block;
                vertical-align: top;
            }
        }
    }


    .info_footer {
        margin-top: 10px;
        padding-top: 25px;
    }

    .bottom_block {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    .copyrights {
        @include smallText;
        margin-top: 28px;

        a {
            color: inherit;
        }
    }

    &_description {
        font-size: $fontSize16;
        line-height: 25px;
        margin-top: 25px;
        width: 63%;
    }
    .description_wrapper{
    display: flex;
        align-items: center;
    }
    .finance_partners {
        display: flex;
        margin: 28px -14px 0;

        a {
            display: block;
            margin: 0 10px;
            @include opacityHover;

            img {
                height: 40px;
                display: block;
                width: auto;
            }

            @include mediaTo($size576) {
                margin: 0 10px 15px;
            }
        }

        @include mediaTo($size576) {
            flex-wrap: wrap;
        }
    }

    .subscribe_section {
        margin-left: auto;

        .footer_description {
            padding-top: 6px;
            width: 100%;
            .page_row{
                width: 100%;
                justify-content: space-between;
                svg{

                }
            }
        }
    }
.footer_apps{
    gap: 12px;
    display: flex;
    margin-top: 16px;
    .app_form {
        @include opacityHover;

        img {
            display: block;
            width: 122px;
            height: 40px;
        }
    }
}
    .section_name {
        font-size: 200%;
        line-height: 1.5em;
        display: flex;
        align-items: center;
        font-family: $medium;

        img {
            margin-right: 10px;
            object-fit: cover;
            max-width: 24px;
            width: 100%;
        }
    }

    .socials_list {
        padding: 0;
        list-style-type: none;
        display: flex;
        margin: 0;
        align-items: center;
        gap: 24px;
        li {
            a {

                display: block;
                color: inherit;
                font-size: 0;
                line-height: 0;
                &:before {
                    color: $colorBackgroundSurfaceFooterLogoDefault;
                    display: block;
                    font-size: 44px;
                    width: 44px;
                    height: 44px;
                }
            }
        }
    }

    .subscribe_form {
        width: 100%;
        position: relative;
        margin-top: 10px;

        label {
            width: 100%;
        }

        .label {
            font-size: 120%;
            line-height: 15px;
            display: block;
            margin-bottom: 8px;
            width: 100%;
        }

        .validate_btn {
            font-size: 0;
            background: $secondColor;
            border-radius: 10px;
            border: none;
            width: 40px;
            height: 40px;
            position: absolute;
            right: 0;
            bottom: 0;
            color: $white;

            &:before {
                font-size: 1.5rem;
                display: block;
            }

            &.loading:after {
                @include transStyle(scale(0.6));
                margin-left: -10px;
            }
        }

        input {
            height: 40px;
            width: 100%;
            border: 1px solid $white;
            border-radius: 10px;
            background: transparent;
            padding: 7px 57px 7px 15px;
            color: $white;
            font-size: 140%;
            line-height: 18px;

            &::placeholder {
                color: #ffffff;
                font-size: 85%;
            }
        }
    }

    .field_inner {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
    }

    .scroll_top_btn {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 32px;
        right: 40px;
        padding: 12px;
        border-radius: $borderRadius12;
        cursor: pointer;
        width: 48px;
        height: 48px;
        z-index: 10000000000;
        background: $colorControlSecondaryDefault;

        @include standardText();

        .icon_arrow_down {
            @include transStyle(scaleY(-1));
            padding-left: 8px;
            display: flex;
        }
    }

    @include mediaRange($size768, $size1200) {
        padding-top: 48px;


        .subscribe_section {
            @include colSize(4);
        }

        .finance_partners {
            margin-left: -10px;
            margin-right: -10px;

            a {
                margin-left: 10px;
                margin-right: 10px;

                img {
                    height: 44px;
                }
            }

        }
    }

    @include mediaRange($size576, $size1200) {
        &_about_menu {
            @include colSize(4);
        }

        &_menu {
            width: calc(100% - 200px);
        }
    }

    @include mediaTo($size768) {
        padding-top: 40px;

        &_menues {
            @include colSize(12);
        }

        .subscribe_section {
            @include colSize(12);

            &:before {
                display: block;
                content: "";
                border-top: 1px solid rgba($white, 0.2);
                //margin-bottom: 20px;
            }
        }

        .info_footer {
            padding-top: 20px;
        }

        .info_inner {
            padding: 20px 0;
        }

        .finance_partners {
            margin-left: -8px;
            margin-right: -8px;
            margin-top: 20px;

            a {
                margin-left: 8px;
                margin-right: 8px;

                img {
                    height: 40px;
                }
            }
        }

        .subscribe_form,
        .socials_list,
        .copyrights {
            margin-top: 20px;
        }
    }

    @include mediaTo($size576) {
        &_about_menu {
            @include colSize(12);

        }

        &_menu {
            @include colSize(12);

            .menu_list {
                @include colCount(1);
            }

            &:before {
                display: block;
                content: "";
                border-top: 1px solid rgba($white, 0.2);
                margin-bottom: 20px;
            }
        }
    }
}

.web .footer {

    .developer a,
    .copyrights a,
    .menu_title,
    .footer_menu .menu_list a,
    .footer_about_menu .menu_list a,
    .socials_list a {
        @include standardHover;
    }

    .subscribe_form .validate_btn {
        @include standardBgHover;
    }
}

.touch .footer {

    .developer a,
    .copyrights a,
    .menu_title,
    .footer_menu .menu_list a,
    .footer_about_menu .menu_list a,
    .socials_list a {
        @include standardActive;
    }

    .subscribe_form .validate_btn {
        @include standardBgActive;
    }
}