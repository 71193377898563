@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.payment_container {
  margin-top: 130px;
  margin-bottom: 80px;

  .main_block {
    border: 1px solid #F2F2F2;
    border-radius: 20px;
    .img_blog{
      img{
        max-height: 155px;
        object-fit: cover;
      }
    }
  }

  .block {
    padding: 16px;
  }

  .block_title {
    color: $colorTextPrimary;
    margin: 0 ;
    font-family: $semibold;
    font-size: 16px;
    padding-bottom: 8px;
  }

  .block_text {
    margin: 0 ;
    color: $colorTextSecondary;
    @include standardNarrow;
  }

  .payment_blocks {
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
    grid-column: 1 / span 3;
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    & > li {
      width: 30%;
      min-height: 194px;
      border-radius: 20px;
      background: linear-gradient(110deg, #D9D9D9 8%, #f5f5f5 18%, #D9D9D9 33%);

      &:before {
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 16px;
        animation: 1.5s shine linear infinite;
      }
    }
  }
  .payment_title {
    width: 280px;
  }
}
.business_slider{
  .slide_block{
    height: 37.5vw;
  }
}
.main_grid_payments{
  margin-top: 20px;
  grid-template-columns: repeat(3, 1fr);
  gap: 34px;
}

@media (max-width: 1025px) {
  .main_grid_payments {
    grid-template-columns: repeat(2, 1fr);
  }
  .payment_container{
    margin-top: 60px;
  }
}

@media (max-width: 576px) {
  .main_grid_payments {
    grid-template-columns: repeat(1, 1fr);
  }
  .block_title {
    font-size: 18px !important;
  }
  .section_title{
    font-size: 20px;
  }
  .block_text{
    font-size: 14px;
  }
  .payment_container{
    margin-top: 40px;
  }
  .payment_blocks{
    grid-column: 1 / span 1 !important;
  }
}
