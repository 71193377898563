@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.chakra-tabs.default {
  .chakra-tabs__tablist {
    display: flex;
    gap: 4px;
    margin-bottom: 32px;
    justify-content: center;
    .tab-buttons{
      gap: 4px;
      flex-wrap: wrap;
    }
    button {
      border-radius: $borderRadius12;
      padding: 8px 12px;
      color: $colorTextSecondary;
      border: none;
      @include standardText;

      &[aria-selected="true"], &:hover {
        background: $colorControlTagActive;
        color: $colorTextPrimary;
      }

    }
  }

  .chakra-tabs__tab-panels {
    .chakra-tabs__tab-panel {
      border-radius: $borderRadius20;

      .info-wrapper {
        .title {
          @include mediumText;
          font-family: "NotoSansArmenian-SemiBold", sans-serif;
          margin-bottom: 16px;
          color: $colorTextPrimary;
        }

        .text {
          @include mediumText;
          color: $colorTextSecondary;
          margin-bottom: 24px;

          p {
            margin: 0;
          }
        }
        table{
          border-radius: $borderRadius8;
          border:1px solid #CCD6E6;
          @include standardNarrow;
          width: 100% !important;
          color: $colorTextPrimary;
          tr:not(:last-child){
            position: relative;
            &:after{
              content: '';
              position: absolute;
              width: calc(100% + 4px);
              height: 1px;
              background: #CCD6E6;
              bottom: 0;
              left: -2px;
              right: 0;
            }
          }
          td{
            text-align: start;
            border: none;
          }
          td{
            padding: 14px 16px;
            width: 50%;
          }
        }
        ul{
          border: 1px solid #CCD6E6;
          border-radius: $borderRadius20;
          padding: 32px 32px 32px 52px;
          color: $colorTextLinkActive;
          li:not(:last-child){
            margin-bottom: 32px;
          }
          li{
            width: max-content;
            a{
              color: $colorTextLinkActive;
            }
          }
        }
      }

      .files {
        border-radius: $borderRadius20;
        gap: 24px;
        border: 1px solid #CCD6E6;
        padding: 32px;
        .file {
          width: calc(50% - 16px);
        }
      }
    }
  }
  .child-tabs-wrapper{
    margin-top: 16px;
    .chakra-tabs__tablist{
      justify-content: start;
      gap: 16px;
      button{
        background: transparent;
        position: relative;
        padding: 0;
        &[aria-selected="true"], &:hover {
          &:after {
            position: absolute;
            bottom: 0;
            content: '';
            width: 100%;
            height: 1px;
            background: $colorControlTabPressed;
          }
        }
      }
    }
  }
}
