@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.files{
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  .file{
    width: calc(50% - 8px);
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    display: flex;
    background: $colorConstantGrey50;
    padding: 10px 16px 10px 16px;
    border-radius: 8px;
    a{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      .title{
        @include standardNarrow;
        color: #070B11;
        margin-bottom: 0;
      }
      .text{
        @include smallText;
        color:  #677482;
      }
    }
  }
}