@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.SME-status-modal {
  .popup_wrapper {
    .popup_container {
      padding: 16px 8px 0;
      max-width: unset;
      .popup_body{
        .popup_description{
          margin: 0!important;
          max-width: 600px;
          color: $colorTextPrimary;
          box-shadow: $boxShadowLow;
          border-radius: 20px;
          .body {
            .header-line {
              padding: 14px;
              @include mediumText;
              margin: 0;
              border-top-left-radius: 20px;
              border-top-right-radius: 20px;
              &.accepted{
                background: $colorConstantYellow200;
              }
              &.rejected{
                background: $colorConstantRed200;
              }
              &.confirmed{
                background: $colorConstantGreen200;
              }
            }

            .status-data {
              padding: 16px 28.5px 32px;
              .title{
                text-align: start;
                margin-top: 0;
                margin-bottom: 24px;
                @include subtitleText;
              }
              .data{
                border: 1px solid $colorBackgroundSurfaceStroke;
                border-radius: 20px;
                text-align: start;
                ul{
                  list-style: none;
                  margin: 0;
                  padding: 0;
                  width: 100%;
                  li{
                    display: flex;
                    width: 100%;
                    padding: 14px 16px;
                    gap: 16px;
                    border-bottom: 1px solid $colorBackgroundSurfaceStroke;
                    justify-content: space-between;
                    &:last-child{
                      border-bottom: unset;
                    }
                    span,b{
                      @include standardText;
                      width: calc(50% - 16px);
                      display: block;
                      text-wrap: wrap;
                    }
                    span{
                      color: $colorTextSecondary;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .popup_close{
      top: 19px;
      right: 19px;
      color: $colorIconsSecondary;
    }
  }
}
