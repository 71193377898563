@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.activate_package_block {
  width: 100%;
  position: relative;
  padding: 56px 0 0;
  background: $colorConstantGrey50;

}
.activate_package {
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .activate_package_info {
    text-align: center;
    width: 100%;
    .switch_btns {
      a, button {
        height: 50px;
      }
    }

    .section_title {
      color: $colorTextPrimary;
      @include textOverflow(2, relative);
    }

    .section_short_text {
    }

    .card_sk {
      list-style-type: none;
      width: 100%;
      padding: 0;
      display: flex;
      gap: 20px;
      margin: 20px 0;
      justify-content: space-between;

      li {
        display: block;
        content: "";
        width: 100%;
        height: 350px;
        margin: 3px 0 4px;
        @include skeleton;
        border-radius: $borderRadius12;
      }
    }
    .card-wrapper {
      margin-top: 26px;
      background: transparent;
      overflow-x: scroll;
      gap: 20px;
      justify-content: space-between;
      position: relative;
      width: 100%;
      z-index: 2;
      &::-webkit-scrollbar {
        width: 0;
        background: transparent;
        margin: 0;
        padding: 0;
      }
      .card {
        min-width: 380px;
        width: 100%;
        padding: 29px 27px;
        max-width: 585px;
        background: $colorBackgroundSurfacePrimary;
        border: 0.5px solid $colorBackgroundSurfaceStroke;
        border-radius: $borderRadius12;
        color: $colorTextPrimary;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include mediaTo($size991) {
          padding: 24px 16px;
          .bold.font-size-22 {
            font-size: 16px;
            line-height: 22px;
          }
        }
        &.isBottomContent{
          .card-body {
            padding: 15px;
          }
        }
        .card-body {
          margin-top: 0;

          .card_top {
            align-items: center;
            display: flex;
            padding-bottom: 12px;
            text-align: start;
            .card_info {
              margin-left: 10px;

              span {
                color: #B3B3B3;
                font-size: 14px;
                font-weight: 100;
              }

              p {
                margin: 0 0 4px;

              }

              .prices {
                font-size: 26px;
                line-height: 32px;
                display: flex;
                flex-direction: column;
                align-items: baseline;
                justify-content: space-between;
                font-family: "NotoSansArmenian-SemiBold", sans-serif;

                b {
                  color: $colorTextSecondary;
                  margin-bottom: 5px;
                  font-weight: 100;
                }
              }
            }
            .card_img{
              max-width: 110px;
              img{
                width: 100%;
              }
            }
          }
          ul {
            word-break: break-word;
            text-align: start;
            line-height: 20px;
            padding: 0;
            list-style: none;
            width: 100%;
            margin:  16px 0 0;
            li {
              font-size: $fontSize14;
              line-height: 20px;
              display: flex;
              align-items: center;
              padding: 8px 0;

              .info_block {
                position: relative;
                display: flex;
                align-items: center;
                margin-left: 12px;

                svg {
                  cursor: pointer;

                  &:hover {
                    path {
                      stroke: $colorControlPrimaryHover;
                    }
                  }
                }

                .info {
                  opacity: 0;
                  position: absolute;
                  left: -10px;
                  bottom: unset;
                  top: 35px;
                  margin-bottom: 5px;
                  background: $colorBackgroundSurfacePrimary;
                  box-shadow: 0 0 16px 0 rgba(34, 59, 148, 0.1607843137);
                  width: max-content;
                  max-width: 256px;
                  border-radius: 6px;
                  padding: 8px 12px 8px 12px;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  white-space: initial;
                  font-size: 14px;
                  line-height: 20px;
                  text-align: center;
                  transition: opacity 0.3s;

                  &:after {
                    display: block;
                    content: "";
                    position: absolute;
                    left: 25px;
                    margin-left: -15px;
                    top: -10px;
                    width: 17px;
                    height: 11px;
                    border-style: solid;
                    transform: rotate(180deg);
                    border-width: 9px 9px 0;
                    border-color: #ffffff transparent;
                    z-index: 9;
                  }
                }
              }

              span {
                display: block;
                width: calc(100% - 32px);
              }

              .image-wrapper {
                border-radius: $borderRadius4;
                width: 24px;
                height: 24px;
                padding: 4px;
                background: $colorBackgroundSurfaceSecondary;
                margin-right: 8px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                  width: 100%;
                }
              }
            }
          }

        }

        &.isBottomContent{
          width: 325px;
          min-width: unset;
          display: flex;
        }
        &.active_card{
          border: unset;
          .card-body{
            border: 1px solid $brandBlue;
          }
        }
      }
      &.isBottomWrapper{
          overflow-x: unset;
          background: $colorConstantGrey50;
          display: flex;
          border-top-left-radius: 0;
          margin-top: 0;
          .card-center-image {
            position: absolute;
            top: 0;
            left: 0;
          }

          .card {
            min-width: 380px;
            background: $colorConstantBlue50;
            color: $colorTextField;
            font-size: $fontSize12;
            border-radius: $borderRadius20;
            padding: 29px 27px;
            display:none;

            .card-body {
              background: $colorBackgroundSurfacePrimary;
              border-radius: $borderRadius12;
              height: 100%;
              .card_top {
                display: flex;

                align-items: center;
                text-align: start;
                padding-bottom: 12px;
                .prices_wrapper{
                  margin: 8px 0;
                  font-size: $fontSize14;
                  .prices_title{
                    color: $colorTextSecondary;
                  }
                  .prices{
                    margin-top: 8px;
                    margin-bottom: 4px;
                  }
                }
                .card_img{
                  img{
                    max-width: 105px;
                  }
                }
              }

              ul {
                padding: 0;
                list-style: none;
                width: 100%;
                margin:  16px 0 0;

                li {
                  font-size: $fontSize12;
                  padding: 8px 0;
                  svg {
                    margin-right: 8px;
                  }
                }
              }
            }


          }

        .isBottomContent {
          display: flex;

        }
      }
    }

  }

  .switch_btns {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;

    button, a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: max-content;
      border: none;
      background: transparent;
      font-size: 140%;
      line-height: 17px;
      color: #070B11;
      text-align: left;
      position: relative;
      padding: 14px 20px;
      border-radius: 10px;
    }

    button.selected {
      box-shadow: 0 4px 16px 0 rgba(34, 59, 148, 0.16);
      pointer-events: none;
      background: $colorBackgroundSurfacePrimary;
      color: $colorTextPrimary;
    }
  }
}


@media (max-width: $size1200) {
  body {
    .activate_package_block .activate_package .activate_package_info .card-wrapper {
      overflow-x: scroll;

      &::-webkit-scrollbar {
        width: 0;
        background: transparent; /* make scrollbar transparent */
      }
    }
  }
}

@media (max-width: $size640) {
  body {
    .activate_package_block .activate_package .activate_package_info .card-wrapper .card {
      min-width: 288px;
    }
  }
}