@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.popup_msg {
    .popup_title {
        color: $colorTextPrimary;
    }
}
.popup_body{
    margin: 24px;
    .app_wrapper{
        padding: 0;

    }
}