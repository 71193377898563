@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';
@import '../../components/uiKit/styles/mixins';

.futures_template {
  width: 100%;
  background: $colorBackgroundSurfacePrimary;
  padding: 40px 0 0;
  .page_container{
    p{
      margin: 0 0 24px;
    }
  }
  .items-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    > div {
      border: 1px solid $colorBackgroundSurfaceStroke;
      border-radius: $borderRadius20;
      flex: 1 0 30%;
      max-width: 33%;
      padding: 32px 24px;

      div {
        margin-bottom: 8px;
        display: flex;
        flex-direction: column;
        p{
          margin: 0;
        }
      }

      svg,img {
        margin-right: 8px;
        width: 24px;
        height: 24px;
        float: left;
        path {
          stroke: $colorBrandElectricblue;
        }
      }
    }
    .counts_block_sk {
      list-style-type: none;
      width: 100%;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 14px;
      li{
        display: block;
        content: "";
        height: 200px;
        width: 370px;
        margin: 3px 0 4px;
        @include skeleton;
        border-radius: $borderRadius12;
      }
    }
  }
}