@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';
@import '../../../components/uiKit/styles/mixins';

.comparing-wrapper{
  .header-wrapper{
    align-items: center;
    margin: 64px 0 24px;
    .buttons-wrapper{
      gap: 12px;
      button{
        width: 32px!important;
        font-size: unset;
        height: 32px;
        padding: 8px 4px 8px 10px;
        border-radius: $borderRadius8;
      }
      @include mediaTo($size991){
        position: fixed;
        top: 50%;
        right: 10px;
        z-index: 1;
      }
    }
  }
  .comparing-table-wrapper{
    overflow-x: scroll;
    margin-bottom: 60px;
    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
    .section_title{
      color: $colorTextPrimary;
      font-size: $fontSize20;
    }
    .section_description{
      color: $colorTextSecondary;
      @include standardText;
      font-weight: 100;
    }
    .comparing-table{
      overflow-x: scroll;
      border-spacing: 0;
      width: 100%;
      background: $colorConstantGrey50;
      border-radius: $borderRadius20;
      tr{
        width: 100%;
        td{

        }
      }
      thead{
        tr{
          th{
            min-width: 250px;
            padding: 24px;
            font-family: $medium;
            text-align: start;
            &:first-child{
              padding: 70px 24px ;
              width: 432px;
            }
            &:nth-child(2){
              background: white;

            }
            &:not(:first-child){
              p{
                font-size: $fontSize22;
                font-family: "NotoSansArmenian-SemiBold", sans-serif;
                color: $colorTextPrimary;
              }
              .prices{
                font-size: 26px;
                line-height: 32px;
                span{
                  color:  #B3B3B3;
                  font-size: 14px;
                 font-weight: 100;
                }
              }
            }

          }
        }
      }
      tbody{
        tr{
          .td-span{
            width: 400px;
          }
          td{
            transition: 0.3s ease-out;
            position: relative;
            border-right:1px solid $colorBackgroundSurfaceSecondary ;
            text-align: center;
            padding: 24px;
            color: $colorTextPrimary;
            font-size: $fontSize16;
            line-height: 22px;
            .info_block{
              position: relative;
              display: flex;
              align-items: center;
              margin-left: 12px;
              svg{
                cursor: pointer;
                &:hover{
                  path{
                    stroke: $colorControlPrimaryHover;
                  }
                }
              }
              .info{
                opacity: 0;
                position: absolute;
                left: 40px;
                bottom: -10px;
                margin-bottom: 5px;
                background: $colorBackgroundSurfacePrimary;
                box-shadow: 0 0 16px 0 rgba(34, 59, 148, 0.1607843137);
                width: max-content;
                max-width: 256px;
                border-radius: 6px;
                padding: 8px 12px 8px 12px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                white-space: initial;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                transition: opacity 0.3s;
                z-index: 11;
                &:after{
                  display: block;
                  content: "";
                  position: absolute;
                  left: 1px;
                  margin-left: -15px;
                  bottom: 10px;
                  width: 17px;
                  height: 11px;
                  border-style: solid;
                  transform: rotate(90deg);
                  border-width: 9px 9px 0;
                  border-color: #ffffff transparent;
                  z-index: 9;
                }
              }
            }

            &:nth-child(2){
              background: white;
            }

            &:first-child{
              text-align: start;
              width: 432px;
              display: flex;
              justify-content: space-between;
              svg{
                margin-left: 10px;
                cursor: pointer;
              }
            }
            .package_title_info{
              position: absolute;
              opacity: 0;
              top: 2px;
              left: 0;
              right: 0;
              font-size: 12px;
              color: $colorTextSecondary;
              transition: 0.3s ease-out;
            }
          }
          &:nth-child(odd){
            background: $colorBackgroundSurfaceStroke;
            td{
              &:nth-child(2){
                background: $colorConstantGrey50;
              }
            }
          }
          @include mediaTo($size991) {
            &:hover {
              box-shadow: rgba(0, 0, 0, 0.15) 0 15px 25px, rgba(0, 0, 0, 0.05) 0 5px 10px;
              td {
                .package_title_info {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }
}
.package_slider{

  .slick-active + .slick-active {
      .info_wrapper{
        tr{
          td{
            &:first-child{
              display: none;
            }
          }
        }
      }
  }
  .slick-slide{
    padding: 10px;
    .prices_wrapper{
      .prices{
        align-items: baseline;
        display: flex;
        flex-direction: column;
        font-size: 26px;
        font-family: "NotoSansArmenian-SemiBold", sans-serif;
        justify-content: space-between;
        line-height: 32px;
        margin: 0 0 4px;
        span{
          color: #b3b3b3;
          font-size: 14px;
          font-weight: 100;
        }
      }
    }
  }
}
.comparing-small-wrapper{
  .rows{
    transition: 0.3s ease-out;
    position: relative;
    border-right: 1px solid $colorBackgroundSurfaceSecondary;
    text-align: center;
    padding: 24px;
    color: $colorTextPrimary;
    font-size: 16px;
    line-height: 22px;
    .info_possibility{
      display: flex;
      justify-content: space-between;
    }
    .status_possibility{
      display: flex;
      justify-content: space-evenly;
      .statuses{
        &:nth-child(n+3) {
          display: none;
        }
      }
    }
    &:nth-child(odd){
      background: #CCD6E6;
    }
     .info_block .info {
      opacity: 0;
      position: absolute;
      left: 40px;
      bottom: -10px;
      margin-bottom: 5px;
      background: #FFFFFF;
      box-shadow: 0 0 16px 0 rgba(34, 59, 148, 0.1607843137);
      width: max-content;
      max-width: 256px;
      border-radius: 6px;
      padding: 8px 12px 8px 12px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      white-space: initial;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      transition: opacity 0.3s;
      z-index: 11;
    }
  }
}
.comparing-wrapper-sk{

    list-style-type: none;
    width: 100%;
    margin: 20px 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    li{
      display: block;
      content: "";
      width: 100%;
      height: 450px;
      margin: 3px 0 4px;
      @include skeleton;
      border-radius: $borderRadius12;
      span{
        display: block;
        position: relative;
        top: 300px;
        width: 100%;
        height: 1px;
        background: $colorBackgroundSurfaceSecondary;
        &:after{
          position: absolute;
          width: 450px;
          top: -75px;
          left: 200px;
          height: 1px;
          transform: rotate(90deg);
          content: '';
          background: $colorBackgroundSurfaceSecondary;
        }
        &:before{
          position: absolute;
          width: 100%;
          top: 70px;
          left: 0;
          height: 1px;
          content: '';
          background: $colorBackgroundSurfaceSecondary;
        }
      }

    }
}
@media (max-width: $size991) {
  .comparing-small-wrapper .rows {
    border-right: unset;
    .info_block {
      display: none;
    }
  }
}