@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';
@import '../../components/uiKit/styles/mixins';


.universal_account_block .activate_universal_account .section_title{
  margin: 0;
}
.compare_accounts_block {
  background: $colorConstantGrey50;
  padding: 32px 65px 56px;

  .info-wrapper {
    background: $colorBackgroundSurfacePrimary;
    border-radius: $borderRadius12;
    padding: 40px 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    div {
      @include standardCol(8);
      p{
        color: $colorTextPrimary;
        margin: 0 0 16px;
        &:first-child{
          @include mediaTo($size991){
            font-size: $fontSize16;
            line-height: 22px;

          }
          line-height: 26px;
        }
      }
    }

  }
  .universal_block_sk {
    list-style-type: none;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    li{
      display: block;
      content: "";
      height: 14px;
      width: 50%;
      margin: 3px 0 4px;
      @include skeleton;
      border-radius: $borderRadius12;
      &:first-child{
        height: 40px;
        width: 60%;
      }
      &:last-child{
        width: 30%;
        height: 40px;
      }
    }
  }
}


.image_right{
  .banner_img{
    margin-left: 40px;
  }
}
.image-text-block {
  position: relative;
  padding: 32px 0 80px;

  .image-text {
    position: relative;
    box-shadow: $boxShadowLowest;
    border-radius: $borderRadius20;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .banner_img {
      width: 100%;
      display: flex;
      position: relative;
      overflow: hidden;
      border-radius: $borderRadius12;
      max-width: 520px;
      align-items: end;
      @include mediaTo($size991){
        align-items: start;
      }
      img {
        width: 100%;
        object-fit: cover;
        height: 300px;
        border-radius: $borderRadius20;
      }
      .video_btn {
        position: relative;
        //width: 420px;
        min-height: 260px;
        overflow: hidden;
        min-width: 72px;
        width: 100%;
      }
      .btn_play {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 72px;
        height: 72px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $colorControlSecondaryDefault;
        border-radius: $borderRadius16;
        cursor: pointer;
        border: none;
        svg{
          margin-right: 0;
        }
      }
    }

    .banner_info {
      width: 100%;
      z-index: 2;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .mobile_app{
        width: max-content;
        background: $colorBackgroundSurfaceSecondary;
        border-radius: $borderRadius100;
        padding: 2px 12px;
        color: $colorTextSecondary;
        margin-bottom: 10px;
        cursor: pointer;
      }
    }

    .section_title {
      margin: 0;
      max-width: 540px;
    }

    .banner_description {
      @include mediumText;
      max-width: 540px;
      margin-top: 16px;
      @include mediaTo($size991){
        margin-top: 8px;
        @include standardText;
      }
      p {
        margin: 0;
        padding: 0;
      }
    }

    .primary_btn {
      align-self: flex-start;
      margin-top: 24px;
    }
  }
}

.image-text-block.background_blue.p-0 {
  .banner_info{
    .section_title{
      display: block;
    }
  }
  .universal_block_sk {
    padding: 64px;
  }
}
.image-text-block.background_blue {
  .image-text {
    background: $colorBrandDarkblue;
    color: $colorTextInverted;
    .banner_info{
      a,button{
        margin-top: 16px;
      }
    }
  }
  .universal_block_sk {
    list-style-type: none;
    width: 100%;
    margin: 0;
    li{
      display: block;
      content: "";
      height: 14px;
      width: 50%;
      margin: 3px 0 4px;
      @include skeleton;
      border-radius: 10px;
      &:first-child{
        margin-bottom: 16px;
        height: 40px;
      }

      &:nth-child(3){
        height: 40px;
        width: 200px;
        margin-top: 32px;
      }
      &:nth-child(2){
        height: 60px;
      }
      &:last-child{
        width: 40%;
        height: 90%;
        top: 4%;
        position: absolute;
        right: 10px;
      }
    }
  }

}

.image-text-block.background_blue_50 {
  .image-text {
    background: $colorConstantBlue50;
    color: $colorTextPrimary;
    box-shadow: none;

  }
}
.image-text-block.background_blue_50.image_left,.image-text-block.background_blue.image_left {
  .image-text{
    .universal_block_sk {
      list-style-type: none;
      width: 100%;
      margin: 0;
      padding: 64px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      li{
        display: block;
        content: "";
        height: 14px;
        width: 50%;
        margin: 3px 0 4px;
        @include skeleton;
        border-radius: 10px;
        &:last-child{
          height: 40px;
          width: 200px;
          margin-top: 32px;
          margin-right: calc(50% - 200px);
          position: unset;
        }

        &:nth-child(3){
          height: 60px;
        }
        &:nth-child(2){
          margin-bottom: 16px;
          height: 40px;
        }
        &:first-child{
          width: 40%;
          height: 90%;
          top: 4%;
          position: absolute;
          left: 10px;
        }
      }
    }

  }
}
.image-text-block.background_blue_50.credit_limit_facility.image_left{
  .universal_block_sk {
    list-style-type: none;
    width: 100%;
    margin: 0;
    padding: 64px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    li{
      display: block;
      content: "";
      height: 14px;
      width: 50%;
      margin: 3px 0 4px;
      @include skeleton;
      border-radius: 10px;
      &:last-child{
        height: 40px;
        width: 50%;
        margin-top: 32px;
        margin-right: unset;
      }

      &:nth-child(3){
        height: 60px;
      }
      &:nth-child(2){
        margin-bottom: 16px;
        height: 40px;
      }
      &:first-child{
        width: 40%;
        height: 90%;
        top: 4%;
        position: absolute;
        left: 10px;
      }
    }
  }

}
.image-text-block.background_transparent {
  .image-text {
    background: transparent;
    color: $colorTextPrimary;
    box-shadow: none;
    border: 1px solid $colorBackgroundSurfaceStroke;
    .universal_block_sk {
      list-style-type: none;
      width: 100%;
      margin: 0;
      padding: 64px;
      li{
        display: block;
        content: "";
        height: 14px;
        width: 50%;
        margin: 3px 0 4px;
        @include skeleton;
        border-radius: 10px;
        &:first-child{
          margin-bottom: 16px;
          height: 40px;
        }

        &:nth-child(3){
          height: 40px;
          width: 200px;
          margin-top: 32px;
        }
        &:nth-child(2){
          height: 60px;
        }
        &:last-child{
          width: 40%;
          height: 90%;
          top: 4%;
          position: absolute;
          right: 10px;
          margin-top: unset;

        }
      }
    }
  }
}

.image-text-block.video_block {
  .image-text {
    background: transparent;
    color: $colorTextPrimary;
    position: relative;
    align-items: end;
    box-shadow: none;
    .banner_info{
      max-width: 475px;
      .info{
        max-width: 379px;
        @include mediaTo($size991){
          max-width: 100%;
        }
        .section_title{
          display: block;
        }
      }
      border-top-left-radius: $borderRadius20;
      border-bottom-left-radius: $borderRadius20;
      box-shadow: $boxShadowLowest;
    }
    .banner_img{
      max-width: 725px;
      padding-left: 0!important;
      padding-right: 0!important;
      position: relative;
      .play_video{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 72px;
        height: 72px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $colorControlSecondaryDefault;
        border-radius: $borderRadius16;
        cursor: pointer;
      }
    }
    .universal_block_sk {
      list-style-type: none;
      width: 100%;
      margin: 0;
      padding: 0;
      margin-top: 200px;
      li{
        display: block;
        content: "";
        height: 14px;
        width: 50%;
        margin: 3px 0 4px;
        @include skeleton;
        border-radius: 10px;
        &:first-child{
          margin-bottom: 16px;
          height: 40px;
        }
        &:nth-child(2){
          height: 60px;
        }
        &:last-child{
          width: 60%;
          height: 100%;
          top: -1%;
          position: absolute;
          right: 10px;
          margin-top: 0;
        }
      }
    }

  }
}

.account-to-account-block {
  position: relative;
  padding: 64px 0 54px;
  background: $colorConstantGrey50;
  margin-bottom: 40px;
  @include mediaTo($size991){
    margin-bottom: 28px;
  }
  .account-to-account {
    border-radius: $borderRadius20;
    background: $colorConstantBlue50;

    .account-to-account-top {
      max-height: 285px;
      position: relative;
      background: transparent;
      border-radius: $borderRadius20;
      color: $colorTextInverted;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .banner_img {
        background: $colorConstantBlue50;

        @include standardCol(4);
        padding-left: 0 !important;
        padding-right: 0 !important;
        width: 385px;
        max-height: 285px;
        display: flex;
        position: relative;
        justify-content: center;
        border-radius: $borderRadius20;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        &:after {
          position: absolute;
          bottom: -35px;
          right: -35px;
          width: 40px;
          height: 40px;
          z-index: 111;
          border-radius: 100%;
          content: '';
          background: $colorConstantBlue50;
        }
        .deal-icon{
          position: absolute;
          right: -45px;
          top: 78px;
          z-index: 111;
          img {
            max-width: 153px;
          }
        }
      }

      .banner_info {
        border-bottom-left-radius: 50px;
        background: $colorConstantGrey50;
        width: 100%;
        padding: 0 0 68px 100px;
        z-index: 2;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .universal_block_sk {
          list-style-type: none;
          width: 100%;
          margin: 0;
          li{
            display: block;
            content: "";
            height: 14px;
            width: 100%;
            margin: 3px 0 4px;
            @include skeleton;
            border-radius: 10px;
            &:first-child{
              margin-bottom: 16px;
              height: 40px;
            }
            &:nth-child(2){
              height: 60px;
            }
            &:last-child{
              height: 40px;
              width: 200px;
              margin-top: 32px;
            }


          }
        }

      }

      .section_title {
        max-width: 540px;
      }

      .banner_description {
        @include mediumText;

        p {
          margin: 0;
          padding: 0;
          @include textOverflow(3, relative);
        }
      }

      .primary_btn {
        align-self: flex-start;
        margin-top: 24px;
      }
    }

    .account-to-account-bottom {
      position: relative;
      background: transparent;
      border-radius: $borderRadius20;
      color: $colorTextInverted;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .banner_img {
        padding-left: 0 !important;
        padding-right: 0 !important;
        width: 385px;
        display: flex;
        position: relative;
        overflow: hidden;
        @include standardCol(4);
        border-top-right-radius: 50px;
        background: $colorConstantGrey50;
        //&:after{
        //  position: absolute;
        //  width: 100%;
        //  height: 40px;
        //  content: '';
        //  background: white;
        //  left: 0;
        //  bottom: 40px;
        //}
        img {
          height: 100%;
          object-fit: contain;
        }
      }



      .section_title {
        max-width: 540px;
      }

      .banner_description {
        @include standardText;
        max-width: 540px;
        @include textOverflow(3, relative);
        margin-top: 16px;

        p {
          margin: 0;
          padding: 0;
          @include textOverflow(3, relative);
        }
      }

      .primary_btn {
        align-self: flex-start;
        margin-top: 24px;
      }
    }
  }


  .bottom-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    font-family: $fontFamilySemiBold;
    color: $colorTextPrimary;

    .bottom-block-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 20px;
      font-family: $fontFamilySemiBold;
      color: $colorTextPrimary;
      > div {
        border-radius: $borderRadius20;
        flex: 0 0 50%;
        max-width: calc(50% - 20px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 0 40px;
        box-shadow: $boxShadowLowest;
        background: $colorBackgroundSurfacePrimary;

        img {
          border-bottom-right-radius: $borderRadius20;
        }
      }
    }
    .advantages_block_sk {
      list-style-type: none;
      width: 100%;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: space-between;
      li{
        display: block;
        content: "";
        height: 80px;
        width: 48%;
        margin: 3px 0 4px;
        @include skeleton;
        border-radius: 10px;
      }
    }
  }
}
.account-to-account-bottom{

  //.card{
  //  .card-body{
  //    background: $backgroundGrey;
  //    padding: 24px 16px 12px;
  //    .card_top{
  //      padding-bottom: 18px;
  //    }
  //    .card_info{
  //      .prices{
  //        font-size: 26px;
  //        line-height: 32px;
  //        display: flex;
  //        flex-direction: column;
  //        align-items: baseline;
  //        justify-content: space-between;
  //        font-weight: 600;
  //        margin: 0 0 4px;
  //      }
  //      span{
  //        color: #B3B3B3;
  //        font-size: 14px;
  //        font-weight: 100;
  //      }
  //    }
  //    .card_img{
  //      display: flex;
  //      img{
  //        max-width: 100px;
  //      }
  //      .checked{
  //        display: flex;
  //        align-items: center;
  //        justify-content: center;
  //        width: 24px;
  //        height: 24px;
  //        background: $colorBackgroundSurfacePrimary;
  //        border-radius: 100%;
  //        margin-left: 12px;
  //      }
  //    }
  //    ul{
  //      margin: 12px 0 0;
  //      li{
  //        padding: 4px 0;
  //        color: $txtField;
  //      }
  //    }
  //  }
  //}
}
.video_block{
   .image-text {
     .banner_info{
       padding: 64px 32px 64px 64px;
       .banner_img {
         img {
           height: 100%;
         }
       }

     }
   }
}

.block-7{
    .banner_info{
      padding: 64px;
    }
     .banner_img img {
     min-height: 485px;
       @include mediaTo($size991){
         min-height: unset;
       }
     }
}

.block-10{
  .image-text{
    padding: 0;
    .banner_info{
      padding: 64px 40px ;
      .banner_description{
        max-width: 576px;
      }
    }
     .banner_img img {
      border-radius: 20px;
      height: 100% ;
      width: 100%;
    }
  }
  @include mediaTo($size991){
    margin-bottom: 24px;
  }
}
.block-9{
  .image-text{
    padding: 64px 36px 64px 64px;
    .banner_info{
      padding: 0;
    }
  }
}
.block-3{
  .image-text{
    padding: 0;
    .banner_info{
      padding: 53px 0 64px 64px;
    }
    .banner_img{
      .banner_image{
        height: 100%;
      }
    }
  }
}
.block-6{
  .image-text{
    padding: 0;
    .banner_info{
      padding: 64px;
    }
  }
}
.block-5{
  .image-text{
    padding: 0;
    .banner_info{
      padding: 64px;
    }
  }
}
.block-8{
  .image-text{
    padding: 64px 0 0;
    .banner_info{
      padding: 64px;
    }
  }
}
.image-text-block.background_blue_50,.image-text-block.background_transparent,.image-text-block.video_block{
  padding: 40px 0;
}
