@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.template {
  width: 100%;
  .global-title{
    @include sectionMediumTitleSize;
    font-family: "NotoSansArmenian-SemiBold", sans-serif;
    margin-bottom: 24px;
  }
}

.dynamic_section {
  display: flex;
  flex-wrap: wrap;

  .template {
    width: 100%;

    .start_icon {
      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  .loading_description {
    @include mediumText
  }

  .inner_section, .content-wrapper {
    .right_block {
      min-width: 230px;

      > img {
        min-width: 230px;
      }
    }

    .left_block {
      min-width: 230px;

      > img {
        min-width: 230px;
      }
    }
  }

  .default, .template4, .template9,.template12 {
    margin: 40px 0;

    .content-wrapper {
      width: 100%;
      background: #FFFFFF;
      box-shadow: 0 4px 16px 0 rgba(34, 59, 148, 0.16);
      display: flex;
      border-radius: 24px;
      flex-direction: row;
      justify-content: space-between;
      padding: 64px;
      align-items: center;
      height: 100%;
      gap: 30px;

      .loading_description {
        color: $colorTextPrimary;
      }

      .section_title {
        color: $colorTextPrimary;
        margin-bottom: 16px;
      }

      .right_block {
        justify-content: center;
        display: flex;
        margin-left: auto;
        @include standardCol(5);
        @include mediaTo($size991) {
          @include standardCol(12);
          display: flex;
          margin-top: 30px;
          justify-content: center;
        }

        img {
          display: block;
          height: auto;
          max-width: 100%;
          max-height: 300px;

          border-radius: 20px;
        }
      }
    }

    &.isBlack {
      .text_btn {
        background: $backgroundBlack;
      }
    }
  }

  .template1 {
    width: 100%;
    padding: 40px 0;

    .content-wrapper {
      background: $colorBackgroundSurfacePrimary;
      display: flex;
      border-radius: $borderRadius24;
      flex-direction: row;
      justify-content: space-between;
      border: 1px solid $backgroundHeaderLine;
      padding: 64px;
      align-items: center;
      gap: 30px;

      .loading_description {
        color: $colorTextPrimary;
      }

      .section_title {
        color: $colorTextPrimary;
        margin-bottom: 16px;
      }


      .right_block {
        margin-left: auto;
        @include mediaFrom($size960) {
          @include standardCol(5);
        }
        @include mediaTo($size991) {
          @include standardCol(12);
          display: flex;
          margin-top: 30px;
          justify-content: center;
        }

        img {
          display: block;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          border-radius: 20px;
        }
      }

      .primary_btn {
        margin-top: 40px;
        @include mediaTo($size768) {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 20px auto;
          width: max-content;
        }
      }
    }

  }

  .template2, .template15 {
    padding: 40px 0;
    width: 100%;

    .small_banner {
      background: $colorBrandDarkblue;
      box-shadow: $boxShadowLowest;
      display: flex;
      border-radius: $borderRadius12;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      padding: 64px;
      width: 100%;

      .store_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 24px;
        flex-wrap: wrap;
        @include mediaTo($size991) {
          justify-content: start;
          gap: 16px;
        }
      }

      @include mediaTo($size991) {
        padding: 24px 16px;
        background-position: initial;
        background-size: cover;
      }

      .banner_description {
        @include subtitleSize;
        text-align: center;
        max-width: 794px;
        margin: 0 auto 20px;
        color: $colorTextInverted;
        @include mediaTo($size991) {
          text-align: start;
        }
      }

      .section_title {
        text-align: center;
        color: $colorTextInverted;
        font-weight: normal;
        margin-bottom: 32px;
        @include mediaTo($size991) {
          text-align: start;
        }
      }

      .page_row {
        align-items: center;
        justify-content: start;
        gap: 24px;

        a {
          display: block;
          //padding: 0 10px;
        }
      }
    }

    .small_banner.isBlack {
      background: $backgroundBlack;
    }
  }

  .template3, .template13 {
    li {
      padding-top: 24px;
      margin: 0 5px;

      button {
        width: 18px;
        height: 18px;
        font-size: 0;
        line-height: 0;
        border: 4px solid $colorTextInverted;
        background: $grayE9;
        border-radius: 50%;
        padding: 0;

        &:before {
          display: none;
          pointer-events: none;
          overflow: hidden;
          height: 0;
        }
      }
    }
  }

  .template3 {
    width: 100%;
    padding: 56px 0;
    background: $colorConstantGrey50;
    margin: 40px 0;

    .slick-dots {
      @include standardList;
      display: flex;
      justify-content: center;
      position: relative;
      bottom: unset;
      margin-bottom: 10px;
      @include mediaTo($size960) {
        li {
          padding-top: 12px;

          &.slick-active button {
            border-width: 6px;
          }
        }
        button {
          width: 24px;
          height: 24px;
          border-width: 7px;
        }
      }
    }

    .slick-slider.product_section {
      @include rowMargin;
      margin-bottom: 40px;

      .slick-list .slick-track {
        display: flex;
        gap: 32px;
      }
    }

    .section_head {
      align-items: center;
      justify-content: center;
      text-align: center;
      @include mediaTo($size991) {
        justify-content: start;
        text-align: start;
      }

      .section_title {
        margin: 0;
      }
    }

    .product_section_slider {
      display: block;
      @include overHidden;
      border-radius: 10px;
    }

    .section_img_wrapper {
      background: $colorBrandDarkblue;
      padding: 28px;
      border-radius: $borderRadius24;
      margin-bottom: 16px;

      .section_img {
        border-radius: $borderRadius12;
        display: block;
        filter: drop-shadow(0px 4px 24px rgba(19, 69, 98, 0.1));
        flex: 1 1;
        max-width: 350px;
        position: relative;

        img {
          border-radius: $borderRadius12;
          display: block;
          height: auto;
          width: 100%;
        }
      }
    }

    .landing_title {
      text-align: center;
      max-width: 350px;
      width: 100%;
      margin: 0 auto;
      padding: 0;
      @include mediumText;
      font-family: "NotoSansArmenian-SemiBold", sans-serif;
      color: $colorTextPrimary;
    }

    .primary_btn {
      margin: 50px auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: max-content;
    }

    &.isBlack {
      background: $creamyF2;

      .section_img_wrapper {
        background: $backgroundBlack;
      }

      .text_btn {
        background: $backgroundBlack;
      }
    }
  }

  .template4 {
    .content-wrapper {
      background: $colorBrandDarkblue;

      .section_title {
        color: white;
      }

      .loading_description {
        color: white;
      }

      .download_app {
        color: white;
      }
    }

    &.isBlack {
      .content-wrapper {
        background: $backgroundBlack;
      }
    }
  }

  .template5 {
    display: flex;
    margin-bottom: 40px;
    min-height: 372px;
    position: relative;
    width: 50%;
    z-index: 1;
    max-width: unset;

    .page_container {
      max-width: 620px;
      margin: unset;
      //margin-left: auto;
    }

    .page_row {
      flex-wrap: unset;
      height: 100%;

      .inner_section {
        display: flex;
        align-items: start;
        height: 100%;
        background: $colorTextInverted;
        border-radius: 30px;
        padding: 40px 64px;
        border: 1px solid $colorBackgroundSurfaceStroke;
        gap: 10px;

        .left_block {
          display: flex;
          justify-content: flex-end;
          align-items: start;
          height: 100%;
          min-width: unset;
          width: 160px;

          video,
          img {
            display: block;
            height: auto;
            border-radius: 20px;
            min-width: unset;
            width: 100%;
          }

        }

        .right_block {
          min-width: 320px;

          a, button {
            margin: 24px 0;
          }

          .loading_summary {
            font-size: $fontSize14;
            line-height: 20px;
            color: $colorTextSecondary;
          }
        }

      }

    }

    .loading_description {
      //color: $colorTextSecondary;
      @include mediaFrom($size960) {
        margin-top: 20px;
      }
      @include mediaTo($size960) {
        margin-top: 10px;
      }
    }

    .primary_btn {
      @include mediaFrom($size960) {
        margin: 40px 0 20px;
      }
      @include mediaTo($size960) {
        margin: 20px 0 10px;
      }
      @include mediaTo($size768) {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px auto;
        width: max-content;
      }
    }

    &:nth-child(odd) {
      .page_container {
        margin: unset;
        margin-left: auto;
        max-width: 620px;

      }
    }
  }

  .template6,.template23 {
    width: 100%;
    max-width: 100%;
    margin: 40px 0;
    padding-bottom: 0 !important;

    .inner_section {
      display: flex;
      height: 100%;
      background: $colorTextInverted;
      border-radius: 30px;
      padding: 40px 64px;
      border: 1px solid $colorBackgroundSurfaceStroke;
      background: $colorTextInverted;
      width: 100%;
      justify-content: space-evenly;
      align-items: center;
      gap: 30px;

      .secondary-button {
        margin: 24px 0;
      }

      .left_block {
        min-width: 340px;
        display: flex;
        justify-content: center;
        align-items: start;
        height: 100%;

        video,
        img {
          display: block;
          height: auto;
          max-width: 100%;
          //max-height: 100%;
          border-radius: 20px;
          max-height: 300px;
          object-fit: contain;
        }
      }

      .right_block {
        min-width: 320px;

        a, button {
          margin: 24px 0;
        }

        .loading_summary {
          font-size: $fontSize14;
          line-height: 20px;
          color: $colorTextSecondary;
        }
      }
    }
  }

  .template7, .template11, .template19 {
    width: 100%;
    max-width: 100%;
    margin: 40px 0;

    .inner_section {
      display: flex;
      height: 100%;
      background: $colorTextInverted;
      border-radius: 30px;
      padding: 40px 64px;
      border: 1px solid $colorBackgroundSurfaceStroke;
      background: $colorTextInverted;
      width: 100%;
      justify-content: space-evenly;
      align-items: center;
      gap: 30px;

      .secondary-button {
        margin: 24px 0;
      }

      .left_block {
        min-width: 340px;
        display: flex;
        align-items: start;
        height: 100%;
        width: 100%;
        @include mediaTo($size991) {
          justify-content: center;
        }

        video,
        img {
          display: block;
          height: auto;
          max-width: 100%;
          //max-height: 100%;
          border-radius: 20px;
          max-height: 547px;
        }
      }

      .right_block {
        min-width: 320px;

        a, button {
          margin: 24px 0;
        }

        .loading_summary {
          font-size: $fontSize14;
          line-height: 20px;
          color: $colorTextSecondary;
        }
      }
    }
  }

  .template11, .template19 {
    .inner_section {
      border: none;

      .left_block {
        flex-direction: column;

        .buttons_wrapper {
          width: 100%;
        }
      }

      background: $colorBrandDarkblue;

      .loading_description, .section_title {
        color: white;
      }
    }

    &.isBlack {
      .inner_section {
        background: $backgroundBlack;
      }
    }
  }

  .template19 {
    .inner_section {
      .mobile_app {
        width: max-content;
        background: $colorBackgroundSurfaceSecondary;
        border-radius: $borderRadius100;
        padding: 2px 12px;
        color: $colorTextSecondary;
        margin-bottom: 10px;
        cursor: pointer;
      }
    }

  }

  .template17, .template16 {
    &.isBlack {
      background: $creamyF2;
    }
  }

  .template7 {
    .inner_section {
      border: none;
      background: $colorConstantBlue50;
    }

    &.isBlack {
      .inner_section {
        background: $creamyF2;
      }
    }
  }

  .template8 {
    margin: 40px 0;

    .content-wrapper {
      align-items: end;
      background: transparent;
      box-shadow: none;
      color: #070b11;
      position: relative;
      width: 100%;
      display: flex;

      .left_block {
        padding: 64px 32px 64px 64px;
        border-top-left-radius: $borderRadius20;
        border-bottom-left-radius: $borderRadius20;
        box-shadow: $boxShadowLowest;
        max-width: 495px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        z-index: 2;
        @media (max-width: 991px) {
          border-top-right-radius: 0;
          border-top-left-radius: 0;
          border-bottom-right-radius: $borderRadius20;
        }
      }

      .right_block {
        margin: 0 auto;
        align-items: end;
        border-radius: 12px;
        display: flex;
        width: 100%;
        overflow: hidden;
        max-width: 725px;
        padding-left: 0 !important;
        padding-right: 0 !important;
        position: relative;

        img {
          border-radius: 20px;
          width: 100%;
        }
      }
    }
  }


  .template9 {
    width: 100%;

    &.isBlack {
      .content-wrapper {
        width: 100%;
        box-shadow: none;
        background: $creamyF2;
      }
    }
  }

  .template10 {
    .inner_section{
      align-items: center;
      .left_block {
        img {
          max-width: 300px;
        }
      }
      .video_block {
        height: 100%;
        max-height: 500px;
        video{
          height: 100%;
          max-height: 500px;
        }
      }
    }
  }


  .template12 {
    width: 100%;
    .home_banner_inner {
      width: 100%;
      background: #e5e9f4!important;
      box-shadow: unset;
      border: unset;
      display: flex;
      border-radius: 24px;
      flex-direction: row;
      justify-content: space-between;
      padding: 64px;
      align-items: center;
      height: 100%;
      gap: 30px;
      .banner_description{
        @include mediumText;
      }
      @include mediaTo($size991) {
        flex-direction: column-reverse;
        padding: 16px 24px;
      }
    }

  }

  .template18 {
    &.isBlack {
      .items-wrapper {
        > div {
          border: 1px solid $colorBackgroundSurfaceStroke;
        }

        img {
          filter: brightness(0) invert(0);
        }
      }

    }
  }

  .template13 {
    width: 100%;
    margin: 40px 0;
    padding: 56px 0;
    background: $colorConstantGrey50;
    position: relative;

    > .page_container {
      position: relative;
      z-index: 2;
    }

    .slick-dots {
      @include standardList;
      display: flex;
      justify-content: center;
      position: relative;
      bottom: unset;
      margin-bottom: 10px;

      li {

        &.slick-active button {
          border-width: 3px;
          background: $siteColor;
          pointer-events: none;
        }
      }

      @include mediaTo($size960) {
        li {
          padding-top: 12px;

          &.slick-active button {
            border-width: 6px;
          }
        }
        button {
          width: 24px;
          height: 24px;
          border-width: 7px;
        }
      }
    }

    .section_head {
      justify-content: center;
      text-align: center;
      max-width: 1005px;
      margin: 0 auto;
      flex-direction: column;
    }

    .section_title {
      margin-bottom: 32px;

      @include mediaFrom($size991) {
        margin-bottom: 24px;
      }

    }

    .landing_title {
      @include mediaFrom($size960) {
        padding: 30px 20px 15px;
      }

      @include mediaRange($size768, $size960) {
        padding: 20px 20px 15px;
      }

      @include mediaTo($size768) {
        padding: 10px 20px 15px;
      }
    }

    .product_card {
      .slick-list {
        .slick-track {
          display: flex;
          gap: 32px;
        }
      }
    }


    .section_img {
      display: block;
      flex: 1 1;
      position: relative;
      border-radius: $borderRadius20;

      img {
        border-radius: $borderRadius20;
        display: block;
        height: auto;
        width: 100%;
      }
    }


    .inner_slider {
      background: $colorTextInverted;
      box-shadow: 0 4px 24px rgba(19, 69, 98, 0.1);
      border-radius: $borderRadius20;
      text-align: center;
    }

    .primary_btn {
      @include mediaFrom($size960) {
        margin: 30px 0 40px;
      }
      @include mediaTo($size960) {
        margin: 20px 0 20px;
      }
    }

    .primary_button {
      margin-top: 40px !important;
    }

  }

  .template14 {
    width: 100%;
    position: relative;
    padding: 64px 0 54px;
    background: $colorConstantGrey50;
    margin-bottom: 0 !important;
    margin-top: 40px;

    .account-to-account {
      border-radius: $borderRadius20;
      background: $colorConstantBlue50;

      .account-to-account-top {
        max-height: 285px;
        position: relative;
        background: transparent;
        border-radius: $borderRadius20;
        color: $colorTextInverted;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .banner_img {
          background: $colorConstantBlue50;

          @include standardCol(4);
          padding-left: 0 !important;
          padding-right: 0 !important;
          width: 385px;
          max-height: 285px;
          display: flex;
          position: relative;
          justify-content: start;
          border-radius: $borderRadius20;

          img {
            height: unset !important;
            width: unset !important;
            object-fit: unset !important;
          }

          &:after {
            position: absolute;
            bottom: -35px;
            right: -35px;
            width: 40px;
            height: 40px;
            z-index: 111;
            border-radius: 100%;
            content: '';
            background: $colorConstantBlue50;
          }

          .deal-icon {
            position: absolute;
            right: -45px;
            top: 78px;
            z-index: 111;

            img {
              max-width: 153px;
            }
          }
        }

        .banner_info {
          border-bottom-left-radius: 50px;
          background: $colorConstantGrey50;
          width: 100%;
          padding: 0 0 40px 100px;
          z-index: 2;
          display: flex;
          justify-content: center;
          flex-direction: column;

          .universal_block_sk {
            list-style-type: none;
            width: 100%;
            margin: 0;

            li {
              display: block;
              content: "";
              height: 14px;
              width: 100%;
              margin: 3px 0 4px;
              @include skeleton;
              border-radius: 10px;

              &:first-child {
                margin-bottom: 16px;
                height: 40px;
              }

              &:nth-child(2) {
                height: 60px;
              }

              &:last-child {
                height: 40px;
                width: 200px;
                margin-top: 32px;
              }


            }
          }

        }

        .section_title {
          max-width: 540px;
        }

        .banner_description {
          @include mediumText;

          p {
            margin: 0;
            padding: 0;
            @include textOverflow(3, relative);
          }
        }

        .primary_btn {
          align-self: flex-start;
          margin-top: 24px;
        }
      }

      .account-to-account-bottom {
        position: relative;
        background: transparent;
        border-radius: $borderRadius20;
        color: $colorTextInverted;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .banner_img {
          padding-left: 0 !important;
          padding-right: 0 !important;
          width: 385px;
          display: flex;
          position: relative;
          overflow: hidden;
          @include standardCol(4);
          border-top-right-radius: 50px;
          background: $colorConstantGrey50;
          //&:after{
          //  position: absolute;
          //  width: 100%;
          //  height: 40px;
          //  content: '';
          //  background: white;
          //  left: 0;
          //  bottom: 40px;
          //}
          img {
            height: 100%;
            object-fit: contain;
          }
        }


        .section_title {
          max-width: 540px;
          @include textOverflow(2, relative);
        }

        .banner_description {
          @include standardText;
          max-width: 540px;
          @include textOverflow(3, relative);
          margin-top: 16px;

          p {
            margin: 0;
            padding: 0;
            @include textOverflow(3, relative);
          }
        }

        .primary_btn {
          align-self: flex-start;
          margin-top: 24px;
        }
      }
    }


    .bottom-block {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 20px;
      font-family: $fontFamilySemiBold;
      color: $colorTextPrimary;

      div {
        border-radius: $borderRadius20;
        flex: 0 0 50%;
        max-width: calc(50% - 20px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 0 40px;
        box-shadow: $boxShadowLowest;
        background: $colorBackgroundSurfacePrimary;

        img {
          border-bottom-right-radius: $borderRadius20;
        }
      }

      .advantages_block_sk {
        list-style-type: none;
        width: 100%;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: space-between;

        li {
          display: block;
          content: "";
          height: 80px;
          width: 48%;
          margin: 3px 0 4px;
          @include skeleton;
          border-radius: 10px;
        }
      }
    }

    &.isBlack {
      background: $backgroundBlack;

      .account-to-account {
        background: $creamyF2;

        .account-to-account-top {
          .banner_img {
            background: $creamyF2;

            &:after {
              background: $creamyF2;
            }
          }

          .banner_info {
            background: $backgroundBlack;
          }
        }

        .activate_package .activate_package_info .card-wrapper {
          background: $backgroundBlack;

          .card {
            background: $creamyF2;
          }
        }

        .account-to-account-bottom .banner_img {
          background: $backgroundBlack;
        }
      }
    }
  }

  .template15 {

  }

  .template16 {
    background: $colorConstantGrey50;
    width: 100%;

    .info-wrapper {
      background: $colorBackgroundSurfacePrimary;
      border-radius: $borderRadius12;
      padding: 40px 64px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      div {
        @include standardCol(8);

        p {
          color: $colorTextPrimary;
          margin: 0 0 16px;

          &:first-child {
            @include mediaTo($size991) {
              font-size: $fontSize16;
              line-height: 22px;

            }
            line-height: 26px;
          }
        }
      }

    }

    .universal_block_sk {
      list-style-type: none;
      width: 100%;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;

      li {
        display: block;
        content: "";
        height: 14px;
        width: 50%;
        margin: 3px 0 4px;
        @include skeleton;
        border-radius: $borderRadius12;

        &:first-child {
          height: 40px;
          width: 60%;
        }

        &:last-child {
          width: 30%;
          height: 40px;
        }
      }
    }

    &.isBlack {

    }
  }

  .template21 {
    width: 100%;
    background: $colorBackgroundSurfaceFooter;

    .bottom-block-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 20px;
      font-family: $fontFamilySemiBold;
      color: $colorTextPrimary;

      div {
        border-radius: $borderRadius20;
        flex: 0 0 50%;
        max-width: calc(50% - 20px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 0 40px;
        box-shadow: $boxShadowLowest;
        background: $colorBackgroundSurfacePrimary;

        img {
          border-bottom-right-radius: $borderRadius20;
        }
      }

    }

    .advantages_block_sk {
      list-style-type: none;
      width: 100%;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: space-between;

      li {
        display: block;
        content: "";
        height: 80px;
        width: 48%;
        margin: 3px 0 4px;
        @include skeleton;
        border-radius: 10px;
      }
    }
    &.isBlack {
      background: white;
      .bottom-block-wrapper div {
        background: #070B11;
        color: white;
      }
    }
  }

  .template22{
    width: calc(50% - 16px);
    display: flex;
    max-width: 600px;
    align-items: end;
    .inner_section{
      box-shadow: 0 4px 16px 0 rgba(34, 59, 148, 0.16);
      border-radius: $borderRadius20;
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: max-content;
      align-items: center;
      .title{
        @include subtitleSize;
        margin: 0;
        padding: 40px;
        color: $colorTextPrimary;
        font-family: "NotoSansArmenian-SemiBold", sans-serif;

      }
      img{
        width: 105px;
        border-radius: 20px;
      }
    }
    &.hasTitle{
      flex-direction: column;
      .global-title{
        text-align: start;
        width: 100%;
        margin: auto 0 24px 0;
        @media (max-width: 991px) {
          margin: 0 0 24px;
          width: 100%;
          max-width: 1240px;
          padding: 0 16px;
        }
      }
    }
    &:nth-child(odd){
      margin: 0 auto 32px 16px;
      @media (max-width: 991px) {
        margin: 0;
        width: 100%;
        max-width: 1240px;
        padding: 0 16px;
      }
    }
    &:nth-child(even){
      margin: 0 16px 32px auto;
      @media (max-width: 991px) {
        width: 100%;
        max-width: 1240px;
        padding: 0 16px;
        margin: 0 0 20px!important;
      }
    }
    @media (max-width: 1300px) {
      
    }
  }

  .template23{
    .inner_section{
      box-shadow: 0 4px 16px 0 rgba(34, 59, 148, 0.16);
      border: none;
    }
  }
  .video_btn {
    position: relative;
    min-height: 260px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 72px;

    img {
      border-radius: 20px;
      display: block;
      height: auto;
      max-height: 100%;
      max-width: 100%;
    }

    .btn_play {
      align-items: center;
      background: $backgroundSecondary;
      border: none;
      border-radius: 16px;
      bottom: 0;
      cursor: pointer;
      display: flex;
      height: 72px;
      justify-content: center;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
      width: 72px;

      svg {
        margin-right: 0;
      }
    }
  }

  .has_background {
    background-repeat: no-repeat !important;
    overflow: hidden !important;
    background-position: right !important;
    background-size: 40% 100% !important;
  }


  @media (max-width: $size1200) {
    .template5 .page_row .inner_section {
      padding: 40px;
    }
  }
  @media (max-width: $size1201) {
    gap: 0;
    .template5 {
      .page_container {
        .page_row {
          .inner_section {
            .right_block {
              min-width: 300px;
            }

            padding: 20px;
          }
        }
      }
    }
    .template14 {
      .account-to-account {
        background: unset;

        .account-to-account-top {
          margin-bottom: 20px;

          .banner_info {
            padding: 0 0 40px 50px;
          }

          .banner_img {
            @include standardCol(4);

            img {
              border-radius: $borderRadius20;
            }

            &:after {
              content: unset;
            }
          }
        }

        .account-to-account-bottom {
          .banner_img {
            @include standardCol(0);
          }

          .card-wrapper {
            .card {
              width: 100%;

              &:first-child {
                border-top-left-radius: $borderRadius20;
              }
            }
          }
        }


      }

      &.isBlack {
        .account-to-account {
          background: $backgroundBlack;

          .account-to-account-bottom {
            padding-top: 20px;
            background: $backgroundBlack;
            border-radius: unset;

            .activate_package {
              margin-top: 10px;
            }
          }

          .account-to-account-top {
            margin-bottom: 0;
            background: $backgroundBlack;

          }
        }
      }
    }
  }
  @media (max-width: $size991) {
    .template {
      margin: 28px 0;
      padding: 0;
    }
    .template5 .page_container .page_row .inner_section .right_block {
      width: 100%;
    }
    .template14 {
      .account-to-account {
        .account-to-account-top {
          max-height: unset;
          flex-direction: column;

          .banner_info {
            padding: 20px;
          }

          .banner_img {
            width: 90%;
            @include standardCol(12);
            max-height: unset;

            img {
              border-radius: $borderRadius20;
            }

            &:after {
              content: unset;
            }
          }
        }

        .account-to-account-bottom {

          .banner_info {
            padding: 0 0 40px 50px;
          }

          .banner_img {
            display: none;

            img {
              border-radius: $borderRadius20;
            }

            &:after {
              content: unset;
            }
          }

          .card-wrapper {
            flex-direction: column;
            flex-wrap: wrap;

            .card {
              min-width: unset;
              padding: 20px;

              .card-body {
                padding: 15px;
              }
            }
          }
        }
      }
    }

    .section {
      padding: 28px 0;
    }
    .template1 {
      margin: 56px 0 28px;

      .content-wrapper {
        flex-direction: column-reverse;
        padding: 24px 16px;


        .right_block {
          flex: initial;
          margin-left: 0;
          margin-top: 0;
        }
      }
    }
    .template8 {
      .content-wrapper {
        flex-direction: column-reverse;

        .left_block {
          max-width: unset;
          padding: 16px 24px;
        }

        .right_block {
          //margin: unset;
          img {
            border-radius: unset;
            height: unset
          }
        }
      }
    }
    .template2, .template15 {
      .small_banner {
        background-position: 0 0;
        background-size: cover;
        padding: 24px 16px!important;

        .section_title {
          text-align: start;
        }

        .store_wrapper {
          gap: 16px;
          justify-content: start;
        }
      }
    }
    .default, .template4, .template9 {
      .content-wrapper {
        padding: 16px 24px;

        flex-direction: column-reverse;

        .right_block {
          margin-top: 0;
          margin-bottom: 10px;
          margin-left: unset;

          img {
            max-height: 300px
          }
        }
      }
    }
    .template6 {
      .inner_section {
        padding: 16px 24px;
        flex-direction: column;

        .left_block {
          width: 100%;
          margin-bottom: 10px;
          justify-content: center;

          img {
            max-height: 300px;
          }
        }
      }
    }
    .template5 {
      width: 100%;

      .page_container {
        margin-left: unset !important;
        max-width: unset;

        .page_row {
          .inner_section {
            width: 100%;
            flex-direction: column-reverse;
            padding: 16px 24px;

            .left_block {
              width: 100%;
              align-items: flex-end;
              justify-content: flex-end;

              > img {
                max-width: 210px;
              }
            }
          }
        }
      }

      &:nth-child(odd) .page_container {
        margin: initial;
        margin-left: auto !important;
        max-width: unset;
      }
    }
    .template21 {
      .bottom-block-wrapper {
        flex-direction: column;
        flex-wrap: wrap;
        margin: 30px 0 0;

        div {
          padding: 18px 0 0 16px;
          width: 100%;
          max-width: unset;

          p {
            margin: 0;
            padding: 0;
            word-break: break-all;
          }
        }
      }
    }
    .template11, .template7, .template19 {
      .inner_section, .content-wrapper {
        flex-direction: column;
        padding: 16px 24px;

        img {
          max-height: 300px !important;
        }
      }
    }
    .template11, .template19 {
      .left_block {
        align-items: center !important;
      }
    }
    .template8 {
      .image-text {
        background: transparent;
        color: $colorTextPrimary;
        position: relative;
        align-items: end;
        box-shadow: none;

        .banner_info {
          max-width: 475px;

          .info {
            max-width: 379px;
            @include mediaTo($size991) {
              max-width: 100%;
            }

            .section_title {
              display: block;
            }
          }

          border-top-left-radius: $borderRadius20;
          border-bottom-left-radius: $borderRadius20;
          box-shadow: $boxShadowLowest;
        }

        .banner_img {
          max-width: 725px;
          padding-left: 0 !important;
          padding-right: 0 !important;
          position: relative;

          .play_video {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: 72px;
            height: 72px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $colorControlSecondaryDefault;
            border-radius: $borderRadius16;
            cursor: pointer;
          }
        }

        .universal_block_sk {
          list-style-type: none;
          width: 100%;
          margin: 0;
          padding: 0;
          margin-top: 200px;

          li {
            display: block;
            content: "";
            height: 14px;
            width: 50%;
            margin: 3px 0 4px;
            @include skeleton;
            border-radius: 10px;

            &:first-child {
              margin-bottom: 16px;
              height: 40px;
            }

            &:nth-child(2) {
              height: 60px;
            }

            &:last-child {
              width: 60%;
              height: 100%;
              top: -1%;
              position: absolute;
              right: 10px;
              margin-top: 0;
            }
          }
        }

      }
    }
  }

}

@media (max-width: $size720) {
  .buttons_wrapper {
    flex-direction: column;
    margin: 16px 0 !important;
    gap: 16 !important;

    a {
      margin: 0 !important;
    }
  }

}
