@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';
@import '../../components/uiKit/styles/mixins';


.white_black_slider {
  margin-bottom: 120px;
  .slick-dots {
    li {
      > span {
        .slide_category {
          color: #333;
        }
        .slide_name{
          color: $colorTextSecondary;
        }
        &:after {
          background: $colorIconsPrimary;
        }
      }
    }

    .slick-active {
      > span {
        .slide_category {
          color: #333;
        }
        .slide_name{
          color: $colorTextPrimary;
        }
      }
    }
  }
}
.grey-section{
  background: #F2F2F2;
  margin: 40px 0;
}

.max521{
  max-width: 512px;
}

.max540{
  max-width: 540px;
}

.section{
  padding: 56px 0;

  .inner_section{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: $borderRadius20;
    .section_description{
      @include mediumText();
      color: $colorTextPrimary;
    }
    .buttons-wrapper{
      display: flex;
      gap: 24px;
    }
    .section_summary{
      margin-top: 24px;
      @include standardNarrow;
      color: $colorTextSecondary;
    }
    .possibilities{
      width: 100%;
      .possibility.full-width{
        width: 100%;
        margin-bottom: 32px;
      }
      .possibility{
        padding: 40px 64px;
        border: 1px solid $colorBackgroundSurfaceStroke;
        border-radius: $borderRadius20;
        display: flex;
        justify-content: space-between;
        .info-wrapper{
          display: flex;
          justify-content: center;
          flex-direction: column;
          color: $colorTextPrimary;
        }
      }
      .odder-possibilities{
        display: flex;
        gap: 32px;
        .possibility{
          padding: 40px;
          width: 50%;
          justify-content: start;
          .info-wrapper{
            max-width: 320px;
          }
          .image-wrapper{
            img{
              max-width: 160px;
            }
          }
        }
      }
    }
  }
  .services{
    display: flex;
    gap: 8px 32px;
    flex-wrap: wrap;
    justify-content: space-between;
    .service{
      display: flex;
      background: #272B30;
      padding: 32px 24px;
      border-radius: $borderRadius20;
      width: calc(100% / 3 - 32px);
      .image{
        margin-right: 8px;
        img{
          max-width: 28px;
        }
      }
      .info-wrapper{
        .section_title{
          @include mediumText;
          margin: 0 0 8px 0;
          font-weight: normal;
        }
        .section_description{
          @include standardNarrow;
        }
      }
    }
  }

  .shadow{
    box-shadow: $boxShadowLowest;
  }
  .border{
    border: 1px solid $colorBackgroundSurfaceStroke;
  }
  .full-padding{
    padding: 40px 64px;
    margin: 0;
  }
  .section-padding{
    padding: 40px 0;
  }
  .info-margin{
    margin: 64px;
  }
  .info-padding{
    padding: 64px;
  }
  .padding-for-block{
    padding: 40px 105px 0 64px;
  }
.app_section{
  background: $colorIconsPrimary no-repeat right;
  box-shadow: $boxShadowLowest;
  display: flex;
  border-radius: $borderRadius12;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  background-size: 40% 100%;
  .section_title{
    color: white;
    font-weight: normal;
    margin: 0 0 32px 0;
  }
  .store_wrapper{
    display: flex;
    gap: 24px;

  }
}
  .simple_section{
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .blocks-wrapper{
      display: flex;
      gap: 32px;
      padding-bottom: 16px;
      justify-content: space-between;
      .block{
        display: flex;
        width: 100%;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        max-width: 378px;
        .image-wrapper{
          width: 100%;
          padding: 28px;
          border-radius: $borderRadius24;
          background: $colorIconsPrimary;
          img{
            width: 100%;
          }
        }
        .title{
          color: $colorTextPrimary;
          @include mediumText;
          font-family: "NotoSansArmenian-SemiBold", sans-serif;
        }
      }
    }
  }
}
.black-section{
  margin: 40px 0;
  padding: 56px 0;
  background: $colorBackgroundSurfaceInverted!important;
  .section_description,.section_summary,.section_title{
    color: white!important;
  }
}
.landing_body.black-section{
  .left_block{
    .loading_description{
      color: white;
    }
  }
}
.activate_package_section{
  margin-bottom: 0;
}
.comparing-section{
  margin-top: 0;
  padding: 4px 0 56px 0;
  .inner_section{
    background: white;
  }
}
.universal-counts-block{
  .futures-section{
    img{
      filter: grayscale(1);
    }
  }
}
.account-to-account-section{
  .account-to-account{
    background: #F2F2F2;
    .account-to-account-bottom {
      .banner_img {
        max-height: 325px;
        background: $colorIconsPrimary;
      }
      .card-wrapper{
        background: $colorIconsPrimary;
        .card{
          background: #F2F2F2;
          .card-body{
            background: white;
            border:none;
            .card_top{
              border-bottom: unset;
            }
          }
        }
      }
    }
    .account-to-account-top {
      .banner_info {
        background: $colorIconsPrimary;
      }
      .banner_img{
        background: #F2F2F2;
        .deal-icon{
          right: -76px;
        }
          &:after{
          background: #F2F2F2;
        }
      }
    }
  }
}
.mobile-app-section{
.mobile_app{
  width: max-content;
  background: #E6EAF6;
  border-radius: 100px;
  padding: 2px 12px;
  color: #677482;
  margin-bottom: 10px;
  cursor: pointer;
}
}
.video_section {
  .video_block {
    background: transparent;
    color: $colorTextPrimary;
    position: relative;
    align-items: end;
    box-shadow: none;
    display: flex;
    width: 100%;
    justify-content: space-between;
    .banner_info{
      width: 100%;
      max-width: 495px;
      padding: 64px 32px 64px 64px;
      .info{
        max-width: 379px;
        @include mediaTo($size991){
          max-width: 100%;
        }
        .section_title{
          display: block;
        }
        .section_description{
          @include mediumText
        }
      }
      border-top-left-radius: $borderRadius20;
      border-bottom-left-radius: $borderRadius20;
      box-shadow: $boxShadowLowest;
    }
    .banner_img{
      max-width: 725px;
      width: 100%;
      padding-left: 0!important;
      padding-right: 0!important;
      position: relative;
      img {
        height: 100%;
      }
      .play_video{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 72px;
        height: 72px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        border: 1px solid $colorIconsPrimary;
        border-radius: $borderRadius16;
        cursor: pointer;
        svg{
          path{
            fill:$colorIconsPrimary;
          }
        }
      }
      img {
        width: 100%;
        object-fit: cover;
        height: 458px;
        border-radius: 20px;
      }
    }
    .universal_block_sk {
      list-style-type: none;
      width: 100%;
      margin: 0;
      padding: 0;
      margin-top: 200px;
      li{
        display: block;
        content: "";
        height: 14px;
        width: 50%;
        margin: 3px 0 4px;
        @include skeleton;
        border-radius: 10px;
        &:first-child{
          margin-bottom: 16px;
          height: 40px;
        }
        &:nth-child(2){
          height: 60px;
        }
        &:last-child{
          width: 60%;
          height: 100%;
          top: -1%;
          position: absolute;
          right: 10px;
          margin-top: 0;
        }
      }
    }

  }
}
.dynamic_section{
  .bottom-block.template21{
    padding: 64px 0;
  }
}
@media (max-width: $size1200) {
  .section{
   .padding-for-block{
      padding: 40px 64px 0;
    }
     .simple_section .blocks-wrapper{
      overflow-x: scroll;
       &::-webkit-scrollbar {
         width: 0;
         background: transparent;
       }
    }
  }

}
@media (max-width: $size991) {
  .dynamic_section{
    .bottom-block.template21{
      padding: 0 0 24px!important;
    }
  }
  body .account-to-account-block .account-to-account .account-to-account-top .banner_img img{
    width: unset;
  }
  .section{
    padding: 28px 0;
    .padding-for-block,
    .inner_section .possibilities .possibility,
    .inner_section .possibilities .odder-possibilities .possibility,
    .full-padding,
    .possibilities .possibility .info-wrapper,
    .video_block .banner_info{
      padding: 24px 16px;
    }
    .inner_section,
    .possibilities .possibility,
    .video_block{
      flex-direction: column;
      div,.banner_info,.banner_img{
        width: 100%;
        max-width: unset;
        img{
          height: 300px;
          object-fit: contain;
        }
      }
      .mobile_app{
        width: max-content;
      }
      .image-wrapper{
        text-align: center;
      }
    }
    .column-reverse,
    .inner_section.column-reverse,
    .possibility.column-reverse{
      flex-direction: column-reverse;
    }
    .inner_section .possibilities .odder-possibilities{
      flex-direction: column;
      .possibility{
        width: 100%;
        .image-wrapper img{
          max-width: unset;
        }
      }
    }
    .services .service{
      width: calc(100% / 2 - 32px);

    }
    .info-margin {
      padding: 24px 16px;
    }
    .black-section{
      margin: 24px 0;
    }
  }
}