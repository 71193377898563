//https://www.figma.com/file/HnwNMlEJfMXmreD5O9KaBr/%F0%9F%A7%AC-Converse-Bank-UI-Kit?type=design&node-id=48-3676&mode=design&t=UJDVZZdr64kq08de-0

//fonts
//font families
//@font-face {
//  font-family: 'NotoSansArmenian-Bold';
//  src: url('../../../assets/fonts/NotoSansArmenian/NotoSansArmenian-Bold.ttf'),
//}
//font family
 $fontFamilySemiBold:'NotoSansArmenian-SemiBold' ,sans-serif;
 $fontFamilyBold:'NotoSansArmenian-SBold' ,sans-serif;

//font sizes
$fontSize12:12px;
$fontSize14:14px;

//box shadow
$boxShadow28: 0 4px 28px 0 rgba(0, 0, 0, 0.12);
$boxShadowLowest: 0 4px 16px 0 rgba(34, 59, 148, 0.16);
$boxShadowLow: 0 4px 16px 0 rgba(13, 43, 150, 0.08);
$boxShadowLowSmall: 0px 4px 4px 0px rgba(0, 14, 65, 0.08);

//new design colors

//Text colors
$colorTextPrimary: #070B11;
$colorTextSecondary: #677482;
$colorTextTertiary: #95A4B4;
$colorTextInverted: #FFFFFF;
$colorTextWhite: #FFFFFF;
$colorTextGreen: #00C270;
$colorTextRed: #FF2F01;
$colorTextField: #3B4A60;
$txtDark: #1F314A;
$txtField: #3B4A60;
$brandBlue: #007DC3;

//Link colors
$colorTextLinkDefault: #3F00FF;
$colorTextLinkActive: #335CFF;

//Background colors
$backgroundWhite: #FFFFFF;
$backgroundSecondary: #E6EAF6;
$backgroundBlack: #070B11;
$backgroundOverlay: #070B11CC;
$backgroundHeaderLine: #CCD6E6;
$backgroundGrey: #F3F8FB;

//color / Surface
$colorBackgroundSurfaceStroke: #CCD6E6;
$colorBackgroundSurfacePrimary:#FFFFFF;
$colorBackgroundSurfaceBadge:#FFFFFF;
$colorBackgroundSurfaceSecondary: #E6EAF6;
$colorBackgroundSurfaceNavigation: #FFFFFF;
$colorBackgroundSurfaceFooter: #F6F7FF;
$colorBackgroundSurfaceFooterLogoDefault: #A5B4C3;
$colorBackgroundSurfaceInverted: #070B11;
$colorBackgroundSurfaceBorder: #CAD3E1;

//Icon colors
$colorIconsSecondary: #677482;
$colorIconsGrey: #677482;
$colorIconsTertiary: #95A4B4;
$colorIconsPrimary: #070B11;
$colorIconsInverted: #FFFFFF;

//Control colors
$colorControlInactive: #B4C3D2;
$colorControlTagActive: #E6EAF6;
$colorControlDisabled: #DBE2ED;
$colorControlPrimary: #2400FF;
$colorControlPrimaryDefault: #3F00FF;
$colorControlSelectorDefault: #A5B4C3;
$colorControlPrimaryHover: #3800E2;
$colorControlPrimaryPressed: #3201C8;
$colorControlTabPressed: #3F00FF;
$colorControlSecondaryDefault: #E6EAF6;
$colorControlInvertedDefault: #FFFFFF;
$colorControlInvertedHover: #F6F7FF;
$colorControlInvertedActive: #E6EAF6;
$colorControlSecondaryHover: #DBE2ED;
$colorControlSecondaryPressed: #CCD6E6;
$colorControlSecondaryFocus: #070B11;
$colorControlBlack: #070B11;
$colorControlBorderFocus: #6685FF;
$colorControlBorderDefault: #CCD6E6;
$colorControlBListHover: #F6F7FF;
$colorControlTabbarInactive: #95A4B4;
$colorControlTableHeader: #F6F7FF;


//color / Highlight
$colorAccentActive: #3F00FF;
$colorAccentBlue: #3F00FF;
$colorAccentNegative: #FF2F01;
$colorAccentPositive: #00C270;

//color / Constant
$colorConstantGrey0: #ffffff;
$colorConstantGrey100: #E6EAF6;
$colorConstantGrey400: #7D8C9B;
$colorConstantYellow200: #FFE999;
$colorConstantRed200: #FFAC99;
$colorConstantGreen200: #92EAC4;
$colorConstantGrey50: #F6F7FF;
$colorConstantBlue50: #E4EAFF;
//color / Brand
$colorBrandDarkblue: #002366;
$colorBrandElectricblue: #3F00FF;


///////////////// START BORDER //////////////////
$borderRadius100: 100px;
$borderRadius24: 24px;
$borderRadius20: 20px;
$borderRadius16: 16px;
$borderRadius12: 12px;
$borderRadius10: 10px;
$borderRadius8: 8px;
$borderRadius6: 6px;
$borderRadius4: 4px;
///////////////// END BORDER //////////////////

///////////////// START BORDER //////////////////
$fontSize32: 32px;
$fontSize26: 26px;
$fontSize24: 24px;
$fontSize22: 22px;
$fontSize20: 20px;
$fontSize18: 18px;
$fontSize16: 16px;
$fontSize14: 14px;
$fontSize12: 12px;
$fontSize10: 10px;
$fontSize8: 8px;
$fontSize6: 6px;
///////////////// END BORDER //////////////////
