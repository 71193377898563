.image-zoom-container {
  position: relative;
  display: inline-block;
}

.image-to-zoom {
  width: 100%; /* Adjust as needed */
  height: auto;
  cursor: crosshair;
}

.zoom-window {
  position: fixed;
  width: 30vw;
  height: 15vw;
  border: 1px solid #000;
  border-radius: 20px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 500%; /* Adjust zoom level */
  pointer-events: none; /* Prevent interaction */
  z-index: 10;
}