@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.main_form {
  max-width: $containerWidth;
  margin: auto auto 88px;
  border-radius: 20px;
  .line{
    margin: 30px 0;
    opacity: 0.2;
  }
  h1 {
    @include pageTitleSize;
  }
  .submit-application{
    padding: 40px 0 32px 20px ;
    color: $colorTextPrimary;
    @include sectionMediumTitleSize;
  }
  .form_subtitle {
    font-family: $semibold;
    @include smallText;
    display: flex;
    align-items: center;
    justify-content: start;
    .acc-number{
      background: #F9F9F9;
      border-radius: 30px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }
  }
.success-sa-modal{
  text-align: center;
}
  .submit_form {
    padding: 0 20px;
    .chakra-accordion {
      .chakra-accordion__item {
        .files-wrapper{
          margin-bottom: 10px;
          .images-title{
            margin-top: 10px;
            font-size: 12px;
            &:first-child{
              margin-top: 20px;
            }
          }
        }
        .chakra-accordion__button {
          color: #333333;;
          border: none;
          background: none;
          margin: unset;
          padding: unset;

          .chakra-text {
            font-size: 12px;
          }

          svg {
            width: 30px;
            height: 30px;
            color: #007DC3;
          }
        }

        .chakra-textarea {
          resize: none;
        }

        .form_description {
          .description-helper-text {
            text-align: right;
            color: $gray66;

          }
        }
        .images-blob{
          margin-top: 10px;
          z-index: 1;
          position: relative;
          padding: 10px;
          border: 1px dashed $fieldBorder;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          background: #f2f5f7;
          border-bottom: none;
          .blob-wrapper{
            margin: 5px 0;
            display: flex;
            align-items: center;
            justify-content: start;
            .image-wrapper{
              cursor: pointer;
              position: relative;
              overflow: hidden;
              width: max-content;
              img{
                width: 50px;
                height: 50px;
                object-fit: contain;
              }
              .delete{
                position: absolute;
                bottom: -55px;
                left: 0;
                width: 100%;
                height: 100%;
                background: #007dc3e0;
                transition: 0.3s ease-out;
                z-index: 1000000000000;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
              }
              &:hover{
                .delete{
                  bottom: 0;
                }
              }
            }

            p{
              margin-left: 10px;
              font-size: 12px;
              color: #333333;
            }
          }
        }
        .images-input-wrapper-blob{
          .images-blob{
            border: 1px dashed $fieldBorder;
            border-bottom: none;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            background: #f2f5f7;
          }
        }
        .file_input_label {
          margin-top: 0;
          position: relative;
          font-size: 12px;
          cursor: pointer;

          .input-wrapper {
            margin-top: 0;
            margin-bottom: 20px;
            position: relative;
            cursor: pointer;
            .images-loading{
              z-index: 1;
              position: absolute;
              top: 40px;
              right: 0;
              left: 0;
              bottom: 0;
              margin: auto;
              width: 50px;
            }
            .cover-wrapper {
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              width: 100%;
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;
              border: 1px dashed $fieldBorder;
              border-top: none;
              height: 100%;
              background: #f2f5f7;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-wrap: wrap;

              svg {
                position: relative;
                top: unset;
                left: unset;
                margin-right: 5px;
              }

              span {
                font-size: 14px;
                color: $black33;
                font-family: "NotoSansArmenian-SemiBold", sans-serif;
              }

              p {
                display: block;
                width: 100%;
                text-align: center;
                padding: 0;
                margin: 0;
              }

            }
            .person-photo {
              opacity: 0.5;

            }
          }
          .input-wrapper.is-empty{
            margin-top: 10px;
            .cover-wrapper{
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
              border-top: 1px dashed $fieldBorder;
            }
          }

          .input-wrapper.images-input-wrapper {
            position: relative;
            cursor: pointer;
            margin-top: 0;
            .cover-border{
              border-top-left-radius: 10px!important;
              border-top-right-radius: 10px!important;
            }
            .cover-unborder{
              border-top: none!important;
            }
            .cover-wrapper {
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              width: 100%;
              border-top-left-radius: 0;
              border-top-right-radius: 0;
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;
              border: 1px dashed $fieldBorder;
              border-top: 1px dashed $fieldBorder ;
              height: 100%;
              background: #f2f5f7;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-wrap: wrap;

              svg {
                position: relative;
                top: unset;
                left: unset;
                margin-right: 5px;
              }

              span {
                font-size: 14px;
                color: $black33;
                font-family: "NotoSansArmenian-SemiBold", sans-serif;
              }

              p {
                display: block;
                width: 100%;
                text-align: center;
                padding: 0;
                margin: 0;
              }

            }

            .person-photo {
              opacity: 0.5;

            }
          }

          input {
            cursor: pointer;
            width: 100%;
            opacity: 0 !important;

          }

        }
        .file_input_label.large_height{
          input{
            height: 80px;

          }
        }
        .file_input_label.small_height{
          input{
            height: 65px;
          }
        }

        .mandatory-fields-wrapper {
          font-size: 12px;
          background: $creamyF2;
          height: 52px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: start;
          font-family: "NotoSansArmenian-SemiBold", sans-serif;
          border-radius: 150px;
          margin-top: 30px;
          padding: 0 10px;

          svg {
            margin-right: 10px;
          }
        }
      }
    }

  }

  .form_terms.accept_checkbox {
    width: 100%;
    margin: 0 0 15px;
    &:first-child{
      margin-top: 10px;
    }
    input {
      width: 20px !important;
    }

    .checkbox-message {
      font-size: 12px;
      width: 90%;
    }
  }

  .first-area{
    border-top: 1px solid $colorBackgroundSurfaceStroke;

  }
  .checkbox-area{
    padding: 16px 0 0 0;
    width: 100%;
    align-items: center;
    .checkbox-message {
      font-size: 12px;
      width: 100%;
    }
    .chakra_checkbox{
      width: 24px;
      height: 24px;
      border: 1px solid $colorControlSelectorDefault;
      border-radius: $borderRadius6;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      &[data-checked] {
        border: 1px solid $colorControlPrimaryDefault;
        color: white;
        background: $colorControlPrimaryDefault;
      }
      svg{
        width: 13px;
        height: 13px;
      }
    }
  }

.otpModal{
  .popup_title{
    margin-bottom: 0;
    .otp-wrapper{
      .field_block{
      margin-bottom: 0;
         .error-icon{
           background: white;
         }
    }
    }
  }
  .countdown{

    .timer-wrapper{
      color: $colorBrandElectricblue;
      font-family: "NotoSansArmenian-SemiBold", sans-serif;
      font-size: 12px;
      padding: 5px 5px 5px 0;
      border-radius: 5px;
      b{
        color: #333;
      }
    }
  span{
    font-size: 12px;

    width: 60px;
    display: block;
  }
    margin: 10px 0;


  }
  .resend_button{
      width: max-content;
      height: 25px;
      color: $colorBrandElectricblue;
      border: none;
      font-size: 12px;
      margin-left: 20px;
      border-radius: 5px;
      background: transparent;
  }
}


  .form_checkbox {
    padding: 8px 16px;
    border: 1px solid $colorBackgroundSurfaceStroke;
    border-radius: 30px;
    @include standardText;
    @include btnLightHover;
    color: $colorTextSecondary;

    &[data-checked] {
      border: 1px solid $colorBrandElectricblue;
      color: $colorBrandElectricblue;
      background: $colorConstantBlue50;
      .chakra-checkbox__control{
        display: block;
      }
    }
    .chakra-checkbox__control{
      display: none;
    }
  }

  .form_blocks {
    border-radius: $borderRadius20;
    width: 100%;

    .has-error {
      border: 1px solid $colorAccentNegative !important;
      border-radius: 11px;
      .error_hint,
      + .error_hint {
        max-height: 60px;
      }
    }
    .title-field{
      margin-bottom: 24px;
      display: block;
      font-size: 16px;
    }
    .default-text{
      font-family: "NotoSansArmenian-SemiBold", sans-serif;
      @include mediumText;
      color: $colorTextPrimary;
        width: 80%;
        margin-top: 32px;
        text-align: center;
    }
  }

  .form_inputs {
    padding: 24px 32px;
    //height: 700px;
    //overflow-y: auto;
    box-shadow: 0 4px 28px 0 #00000014;

    &::-webkit-scrollbar {
      width: 1em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $fieldBorder;
      border-radius: 6px;
    }
  }
  .address_sk.skeleton {
    width: 100%;
      list-style-type: none;
      padding: 0;
    li:before {
      display: block;
      width: 50%;
      height: 25px;
      max-width: 100%;
      content: ".";
      color: transparent;
    }
    li::marker{display: none}
  }
  .blog_info {
    display: flex;
    background: $colorBackgroundSurfaceSecondary;
    align-items: center;
    margin-left: 25px;
    justify-content: center;
    min-height: 620px;
    height: max-content;
    padding: 32px;
    .form_image{
      max-height: 350px;
      object-fit: cover;
    }
    .address{
      margin-bottom: 12px;
    }
    .phone-link{
      margin: 0;
      padding: 0;
    }
    .email{
      margin-top: 2px;
    }
    a {
      width: 100%;
      color: $colorTextPrimary;
      padding-top: 10px;
      @include smallText;
    }
  }

  .sub_title {
    color: $colorTextPrimary;
    margin-bottom: 14px;
    a{
      @include subtitleSize;
      font-family: "NotoSansArmenian-SemiBold", sans-serif;
    }
  }

  .form_links {
    color: white;
    cursor: pointer;
    justify-content: start;
    @include standardHover;
  }


  .chakra-select__icon-wrapper{
    right: 5px;
    background: white;
    font-size: 25px;
    color: #007DC3;
  }

  .form_terms {
    width: 50%;
    font-size: 12px;

    input {
      display: inline-block;
      margin-right: 11px;
      padding: 0 !important;
      appearance: auto !important;
      border: 1px solid #D9D9D9 !important;
      height: 24px !important;
      width: 30px !important;
      -webkit-appearance: checkbox !important;
      position: relative !important;
      -moz-appearance: checkbox !important;
    }
  }

  .error_hint {
    display: block;
    max-height: max-content;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    overflow: hidden;
    color: $colorAccentNegative;
    @include standardNarrow;
    margin-top: 3px;
    text-align: start;
  }

  .close_button {
    width: 100%;
    height: 49px;
    border-radius: 10px;
    border: none;
    color: white;
    font-size: 14px;
  }




}
.checkbox-message {
  @include smallText;
  color: $colorTextSecondary;
  width: 90%;
}
@media (max-width: $size960) {
  .main_form {
    .form_blocks.blog_info {
      //display: none;
      div {
        padding: 20px;

        img {
          width: 100%;
        }
      }
    }

    //.form_blocks.form_inputs{
    //  width: 100%;
    //}
  }
}


@media (max-width: 900px) {
  .main_form {
    .form_blocks.blog_info {
      display: none;
    }

    .form_blocks.form_inputs {
      width: 100%;

      .chakra-accordion__panel {
        div {
          width: 100%;
          display: block;
        }
        .CashRegister{
          display: flex;
        }
      }
      .chakra-accordion__panel.service-wrapper {
        div {
          width: 100%;
          display: flex;
        }

      }
    }
  }
}

@media (max-width:$size991) {
  .main_form {
    margin: auto auto 64px;
    .form_blocks .default-text {
      width: 100%;
    }
    }
}

@media (max-width:$size600) {
  .main_form {
    .error_hint {
      font-size: 11px;
    }

    .field_block .error-icon{
      width: 15px;
    }
   .form_select {
      font-size: 110%;
     color: $textColor;
    }
    .checkbox-area {
      .chakra_checkbox {
        width: 20px;
        height: 20px;
      }
    }
    .select-block > div .select__control .select__placeholder {
      font-size: 10px;
      line-height: 11px;
    }
    .submit_form .chakra-accordion .chakra-accordion__item .file_input_label .input-wrapper .cover-wrapper span {
      font-size: 11px;
    }
    .submit_form .chakra-accordion .chakra-accordion__item .file_input_label .input-wrapper.images-input-wrapper .cover-wrapper span{
       font-size: 11px;
    }
    .chakra-accordion .btn_block{
      margin-top: 10px!important;
    }
    .form_inputs{
      padding: 20px;
    }
  }
}
@media (max-width: $size576) {
  .form_blocks {
    .fields-wrapper{
      margin-top: 24px;
      .inputs-area{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 15px;
        .field_block{
          margin-bottom: 15px;
        }
        >div{
          display: flex;
          flex-direction: column;
          width: 100%;
          .select__control{
            margin-bottom: 20px;
          }
          .select__menu{
            top: 70%;
          }
          .chakra-select__icon-wrapper {
            top: 30px;
          }
        }
      }
    }
  }
}