@import './variablesLightMode';

@mixin animStyle($parameters) {
  -o-transition: $parameters;
  -ms-transition: $parameters;
  -moz-transition: $parameters;
  -webkit-transition: $parameters;
  transition: $parameters;
}


@mixin LinkHover {
  @include animStyle(color 0.3s);
  &:hover {
    color: $colorTextLinkActive;
    @include mediaTo($size960) {
      color:inherit;
    }
  }
}


@mixin DefaultHover {
  @include animStyle(all 0.3s);
  &:hover {
    color: $white;
    background-color: $colorTextLinkActive;
    border-color: transparent;
  }
}