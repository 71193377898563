@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';



.exchange_section .calc_section .app_left{
    .page_row{
        justify-content: start;
    }
}
.popup_wrapper {
    @include popupBackLayer;
    .popup_block {
        @include popupBlock(0.5s);
    }
    .popup_inner {
        @include popupInner(20px $mobileContainerPadding);
        .section_title{
            margin-bottom: 0;
            font-size: $fontSize20;
        }
        .app_description{
            margin-bottom: 28px;
        }
    }

    .popup_container {
        @include popupContainer($white, 584px, 40px);
        border-radius: 20px;
    }
    .popup_close {
        border: none;
        background: transparent;
        padding: 4px;
        position: absolute;
        top: 6px;
        right: 6px;
        color: $colorIconsPrimary;
        @include standardHover;
        font-size: 120%;
        &:before {
            display: block;
        } 
    }
    .popup-cancel{
        background: transparent;
        border: none;
        width: 100%;
        font-size: 14px;
        font-family: "NotoSansArmenian-SemiBold", sans-serif;
        color: $colorTextPrimary;
        border-radius: 10px;
    }
    .popup_title {
        font-size: 20px;
        line-height: 26px;
        font-family: "NotoSansArmenian-SemiBold", sans-serif;
        color: $colorTextPrimary;
    }
    .popup_description {
        @include standardText;
        color: $colorTextSecondary;
        font-size: $fontSize14;
    }
    .btn_block {
        padding-top: 4px;
    }
    .primary_btn {
        width: 100%;
    }

    @include mediaRange($size480, $size960) {
        .popup_container {
            padding: 40px 24px 32px;
            max-width: 440px;
        }
    }

    @include mediaTo($size480) {
        .popup_container {
            padding: 40px 16px 24px;
            max-width: 420px
        }
    }
    &.showed {
        @include showedPopupBackLayer;
        .popup_block {
            top: 0;
            overflow-y: auto;
            @include animDelay(0.3s);
        }
    }
}

