@import "./mixins";
@import "./variablesLightMode";

//global css
.toast-black{
  font-size:$fontSize16;
  background: $colorBackgroundSurfaceInverted!important;
  color: $colorTextInverted!important;
}

.toast{
  min-width: max-content;
  svg{
    width: 16px;
    height: 11px;
  }
}
.m-0 {
  margin: 0!important;
}
.mt-40 {
  margin-top: 40px!important;
}
.mt-16{
  margin-top: 16px!important;
}
.m-auto {
  margin: auto!important;
}
.mt-20 {
  margin-top: 20px!important;
}
.mt-32 {
  margin-top: 32px!important;
}
.mb-40 {
  margin-bottom: 40px!important;
}
.mb-20 {
  margin-bottom: 20px!important;
}
.m-20 {
  margin: 20px!important;
}

.pt-40 {
  padding-top: 40px!important;
}
.pt-20 {
  padding-top: 20px!important;
}
.p-20 {
  padding: 20px!important;
}
.p-0 {
  padding: 0!important;
}

.pb-0 {
  padding-bottom: 0!important;
}
.pb-40 {
  padding-bottom: 40px!important;
}
.pb-20 {
  padding-bottom: 20px!important;
}

.w-100{
  width: 100%!important;
}
.h-100{
  height: 100%!important;
}

.w-max-content{
  width: max-content!important;
}

.font-size-20{
  font-size: $fontSize20;
}
.font-size-14{
  font-size: $fontSize14;
  line-height: 20px;
}
.font-size-16{
  font-size: $fontSize16;
  line-height: 22px;
}
.font-size-22{
  font-size: $fontSize22;
  line-height: 22px;
}
.font-size-26{
  font-size: $fontSize26;
  line-height: 32px;
}
.bold{
  font-family: "NotoSansArmenian-SemiBold", sans-serif;
}

.color-primary{
  color: $colorTextPrimary;
}
.color-secondary{
  color: $colorTextSecondary!important;
}
.color-inverted{
  color: $colorTextInverted!important;
}