@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.SME_main_form {
  max-width: $containerWidth;
  margin: auto auto 88px;
  border-radius: 20px;

  .pladge-subject {
    margin-bottom: 24px;
    span {
      font-family: "NotoSansArmenian-SemiBold", sans-serif;
      color: $colorTextSecondary;
      margin-bottom: 15px;
      @include mediumText;

    }

    b {
      color: $colorTextSecondary;
      @include standardNarrow;


    }
  }

  .line {
    margin: 32px 0 0;
    opacity: 0.2;
  }

  h1 {
    @include pageTitleSize;
  }

  .submit-application {
    text-align: center;
    padding: 40px 0 32px 20px;
    color: $colorTextPrimary;
    @include sectionMediumTitleSize;
  }
  .loanParticipants-wrapper{
    >div{
      width: calc(50% - 8px);
    }
    @media (max-width: 576px) {
      flex-direction: column;
      >div{
        width: 100%;
      }
    }
  }
  .form_subtitle {
    font-family: $semibold;
    @include smallText;
    display: flex;
    align-items: center;
    justify-content: start;

    .acc-number {
      background: #F9F9F9;
      border-radius: 30px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }
  }

  .success-sa-modal {
    text-align: center;
  }

  .submit_form {
    padding: 0 20px;

    .form-image-small {
      display: none;
    }

    .service-wrapper {
      margin-bottom: 32px;

      div {
        justify-content: center;
      }

      .loans_select_wrapper {
        padding: 20px 32px;
        box-shadow: 0 4px 28px 0 #00000014;
        width: 100%;
        border-radius: $borderRadius20;

        .loans_select {
          z-index: 1111;
          border-radius: $borderRadius12;
          @include mediumText;
          color: $colorTextPrimary;
          border: 1px solid #CCD6E6;

          span {
            display: none;
          }

          svg {
            color: $colorIconsSecondary;
          }

          #react-select-3-listbox {
            > div {
              div {
                &:first-child {
                  background: transparent;
                }
              }
            }

            .loans_select__option--is-selected {
              background: #E1E6EB !important;
              color: $colorTextPrimary;
            }

            .loans_select__option {
              &:hover {
                color: $colorTextPrimary;
                background: #E1E6EB;
              }
            }
          }

          .loans_select__control {
            border-radius: $borderRadius12;
            border-color: #CCD6E6;
            height: 48px;
            border: 1px;

            .loans_select__input-container {
              @include mediumText;
              color: $colorTextPrimary;
            }
          }
        }

        .loans_select_label {
          color: $colorTextSecondary;
          display: block;
          @include standardNarrow;
          margin-bottom: 4px;
        }
      }

    }

    .files-wrapper {
      margin-bottom: 48px;

      .images-title {
        margin-top: 10px;
        @include standardNarrow;
        color: $colorTextSecondary;

        &:first-child {
          margin-top: 20px;
        }
      }
      &:last-child{
        margin-bottom: 10px;
      }
    }

    .chakra-textarea {
      resize: none;
    }

    .form_description {
      .description-helper-text {
        text-align: right;
        color: $gray66;

      }
    }

    .images-blob {
      margin-top: 10px;
      z-index: 1;
      position: relative;

      .blob-wrapper {
        margin: 5px 0;
        display: flex;
        align-items: center;
        justify-content: start;
        position: relative;
        overflow: hidden;
        background: #E1E6EB;
        padding: 10px 8px;
        border-radius: $borderRadius8;

        .image-wrapper {
          cursor: pointer;
          width: max-content;

          img {
            width: 40px;
            height: 40px;
            object-fit: contain;
          }

          .delete {
            position: absolute;
            right: 12px;
            top: 12px;
            bottom: 0;
            width: 40px;
            height: 40px;
            transition: 0.3s ease-out;
            z-index: 1000000000000;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
          }

          &:hover {
            .delete {
              right: 12px;
            }
          }
        }

        p {
          font-size: 12px;
          color: #333333;
          margin: 2px 10px 2px;

          &.name {
            @include mediumText;
            color: $colorTextPrimary
          }
        }
        .bottom-line{
          position: absolute;
          bottom: 0;
          left: 0;
          height: 2px;
          background: #3F00FF;
        }
      }
    }

    .images-input-wrapper-blob {
      .images-blob {
        border: 1px dashed $fieldBorder;
        border-bottom: none;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background: #f2f5f7;
      }
    }

    .file_input_label {
      margin-top: 0;
      position: relative;
      font-size: 12px;
      cursor: pointer;

      .input-wrapper {
        margin-top: 0;
        position: relative;
        cursor: pointer;

        .images-loading {
          z-index: 1;
          position: absolute;
          top: 40px;
          right: 0;
          left: 0;
          bottom: 0;
          margin: auto;
          width: 50px;
        }

        .cover-wrapper {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          width: 100%;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          border: 1px dashed $fieldBorder;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;

          svg {
            position: relative;
            top: unset;
            left: unset;
            margin-right: 5px;
          }

          span {
            font-size: 14px;
            color: $black33;
            font-family: "NotoSansArmenian-Regular", sans-serif;
          }

          p {
            display: block;
            width: 100%;
            text-align: center;
            padding: 0;
            margin: 0;
          }
          .download-file{
            color: $colorControlPrimaryDefault!important;
          }
        }

        .person-photo {
          opacity: 0.5;

        }
      }

      .input-wrapper.is-empty {
        margin-top: 10px;

        .cover-wrapper {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          border-bottom: 1px dashed $fieldBorder;
        }
      }

      .input-wrapper.images-input-wrapper, {
        position: relative;
        cursor: pointer;
        margin-top: 0;

        .cover-border {
          border-bottom-left-radius: 10px !important;
          border-bottom-right-radius: 10px !important;
        }

        .cover-wrapper {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          width: 100%;
          border-radius: 10px;
          border: 1px dashed $fieldBorder;
          border-top: 1px dashed $fieldBorder;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          .file-text-desc{
            @media (max-width: $size991) {
              display: none;
            }
          }
          svg {
            position: relative;
            top: unset;
            left: unset;
            margin-right: 5px;
          }

          span {
            font-size: 14px;
            color: $colorTextPrimary;
          }

          p {
            display: block;
            width: 100%;
            text-align: center;
            padding: 0;
            margin: 0;
          }

        }

        .person-photo {
          opacity: 0.5;

        }
      }

      input {
        cursor: pointer;
        width: 100%;
        opacity: 0 !important;

      }

      .file-size {
        margin-top: 8px;
        color: $colorTextSecondary;
      }
    }

    .file_input_label.large_height {
      input {
        height: 48px;

      }
    }

    .file_input_label.small_height {
      input {
        height: 65px;
      }
    }

    .mandatory-fields-wrapper {
      font-size: 12px;
      background: $creamyF2;
      height: 52px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: start;
      font-family: "NotoSansArmenian-SemiBold", sans-serif;
      border-radius: 150px;
      margin-top: 30px;
      padding: 0 10px;

      svg {
        margin-right: 10px;
      }
    }
    .date-field {
      .type_field.type_has_error{
        svg{
          right: 40px;
        }
      }
    }
  }

  .form_terms.accept_checkbox {
    width: 100%;
    margin: 0 0 15px;

    &:first-child {
      margin-top: 10px;
    }

    input {
      width: 20px !important;
    }

    .checkbox-message {
      font-size: 12px;
      width: 90%;
    }
  }

  .first-area {
    border-top: 1px solid $colorBackgroundSurfaceStroke;

  }

  .checkbox-area {
    padding: 16px 0 0 0;
    width: 100%;
    align-items: center;

    .checkbox-message {
      @include smallText;
      width: 100%;
    }
    .open-pdf-modal{
      background: transparent;
      border: none;
      margin: 0;
      padding: 0;
      color: $colorControlPrimaryDefault;
      cursor: pointer;
    }
    .chakra_checkbox {
      width: 24px;
      height: 24px;
      border: 1px solid $colorControlSelectorDefault;
      border-radius: $borderRadius6;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      &[data-checked] {
        border: 1px solid $colorControlPrimaryDefault;
        color: white;
        background: $colorControlPrimaryDefault;
      }

      svg {
        width: 13px;
        height: 13px;
      }
    }
  }

  .otpModal {
    .popup_title {
      margin-bottom: 0;

      .otp-wrapper {
        .field_block {
          margin-bottom: 0;

          .error-icon {
            background: white;
          }
        }
      }
    }

    .countdown {

      .timer-wrapper {
        color: $colorBrandElectricblue;
        font-family: "NotoSansArmenian-SemiBold", sans-serif;
        font-size: 12px;
        padding: 5px 5px 5px 0;
        border-radius: 5px;

        b {
          color: #333;
        }
      }

      span {
        font-size: 12px;

        width: 60px;
        display: block;
      }

      margin: 10px 0;


    }

    .resend_button {
      width: max-content;
      height: 25px;
      color: $colorBrandElectricblue;
      border: none;
      font-size: 12px;
      margin-left: 20px;
      border-radius: 5px;
      background: transparent;
    }
  }


  .form_checkbox {
    padding: 8px 16px;
    border-radius: 14px;
    @include mediumText;
    color: $colorTextPrimary;
    transition: 0.3s ease-out;

    &[data-checked] {
      box-shadow: $boxShadowLowest;

    }

    .chakra-checkbox__control {
      display: none;
    }

    &:hover {
      box-shadow: $boxShadowLowest;
    }
  }

  .form_blocks {
    border-radius: $borderRadius20;
    width: 100%;

    .has-error {
      border: 1px solid $colorAccentNegative !important;
      border-radius: 11px;

      .error_hint,
      + .error_hint {
        max-height: 60px;
      }
    }

    .title-field {
      margin-bottom: 24px;
      display: block;
      font-size: 16px;
    }

    .default-text {
      font-family: "NotoSansArmenian-SemiBold", sans-serif;
      @include mediumText;
      color: $colorTextPrimary;
      width: 80%;
      margin-top: 32px;
      text-align: center;
    }
  }

  .form_inputs {
    padding: 38px 32px 24px 32px;
    //height: 700px;
    //overflow-y: auto;
    box-shadow: 0 4px 28px 0 #00000014;

    &::-webkit-scrollbar {
      width: 1em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $fieldBorder;
      border-radius: 6px;
    }
    .phone-wrapper{
      .phone-code{
        position: absolute;
        top: 43px;
        font-size: 16px;
        z-index: 111;
        left: 13px;
      }
      input{
        padding-left: 52px;
      }
    }
  }

  .title_sk.skeleton {
    width: 100%;
    list-style-type: none;
    display: flex;
    padding: 0;
    justify-content: center;

    li {
      width: 50%;

      &:before {
        display: block;
        width: 100%;
        height: 25px;
        max-width: 100%;
        content: ".";
        color: transparent;
      }
    }

    li::marker {
      display: none
    }
  }

  .address_sk.skeleton {
    width: 100%;
    list-style-type: none;
    padding: 0;

    li{
      margin: 10px 0;
      &:before {
        display: block;
        width: 80%;
        height: 25px;
        max-width: 100%;
        content: ".";
        color: transparent;
      }
    }

    li::marker {
      display: none
    }
  }

  .form_sk.skeleton {
    width: 100%;
    list-style-type: none;
    padding: 0;
    display: flex;
    gap: 30px;
    flex-direction: column;
    justify-content: center;

    li:before {
      display: block;
      width: 100%;
      height: 48px;
      max-width: 100%;
      border-radius: 12px;
      content: ".";
      color: transparent;

    }

    li {
      &:last-child {
        width: 200px;
      }
    }

    li::marker {
      display: none
    }
  }

  .loans_sk.skeleton {
    width: 100%;
    list-style-type: none;
    display: flex;
    padding: 0;
    gap: 4px;
    justify-content: center;

    li {
      width: 120px;
      height: 38px;

      &:before {
        border-radius: 14px;
        display: block;
        width: 100%;
        height: 100%;
        max-width: 100%;
        content: ".";
        color: transparent;
      }
    }

    li::marker {
      display: none
    }
  }

  .blog_info {
    display: flex;
    background: $colorBackgroundSurfaceSecondary;
    align-items: center;
    margin-left: 25px;
    justify-content: center;
    min-height: 620px;
    height: max-content;
    padding: 32px;
    .loan-info{
      color: $colorTextPrimary;
      @include mediumText;
      margin: 0;
      padding: 0 0 0 15px;
      li{
        font-family: "NotoSansArmenian-SemiBold", sans-serif;
        margin-bottom: 24px;
        &:last-child{
          margin-bottom: 0;
        }
        &:first-child{
          margin-top: 24px;
        }
      }
    }
    .form_image {
      max-height: 350px;
      object-fit: cover;
    }

    .phone-link {
      margin: 0;
      padding: 0;
    }

    .email {
      margin-top: 2px;
    }

    a {
      width: 100%;
      color: $colorTextPrimary;
      padding-top: 10px;
      @include smallText;
    }
  }

  .sub_title {
    margin-top: 16px;
    color: $colorTextPrimary;
    @include subtitleText;
    font-family: "NotoSansArmenian-SemiBold", sans-serif;
  }

  .summary {
    color: $colorTextSecondary;
    @include mediumText;

    p {
      margin-top: 0;
    }
  }

  .description {
    color: $colorTextPrimary;
    @include mediumText;
  }

  .form_links {
    cursor: pointer;
    justify-content: start;
    @include standardHover;
  }

  .form_select {
    margin-top: 5px;
    @include mediumText;
    border: 1px solid $fieldBorder;
    border-radius: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin-right: 2rem;
    font-family: inherit;
    padding: 15px;
    width: 100%;
    color: $colorTextSecondary;
  }

  .select__menu-list {
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      background: $colorControlTabbarInactive;
    }
  }

  .chakra-select__icon-wrapper {
    right: 5px;
    top: 32px;
    background: white;
    font-size: 25px;
    color: $brandBlue;
  }

  .form_terms {
    width: 50%;
    font-size: 12px;

    input {
      display: inline-block;
      margin-right: 11px;
      padding: 0 !important;
      appearance: auto !important;
      border: 1px solid $fieldBorder !important;
      height: 24px !important;
      width: 30px !important;
      -webkit-appearance: checkbox !important;
      position: relative !important;
      -moz-appearance: checkbox !important;
    }
  }

  .error_hint {
    display: block;
    max-height: max-content;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    overflow: hidden;
    color: $colorAccentNegative;
    @include standardNarrow;
    margin-top: 3px;
    text-align: start;
  }

  .close_button {
    width: 100%;
    height: 49px;
    border-radius: 10px;
    border: none;
    color: white;
    font-size: 14px;
  }

  .select__control {
    border-radius: $borderRadius12;
    min-height: 49px;
    box-shadow: unset;
    margin-top: 5px;

    .select__placeholder {
      @include standardText;
      color: $colorTextSecondary;
      text-overflow: ellipsis;
      text-wrap: nowrap;
      overflow: hidden;
      line-height: 21px;
    }

    &:hover {
      border: 1px solid $colorControlBorderFocus;
    }

    .select__indicators {
      > div {
        cursor: pointer;
        padding: 4px;
      }

      .select__dropdown-indicator {
        svg {
          transition: 0.3s ease-out;
          color: $colorIconsSecondary;
        }
      }

      .select__loading-indicator {
        margin-right: 0;
        padding: 3px;
      }

      .select__indicator-separator {
        display: none;
      }
    }

    .select__multi-value {
      background: $colorBrandElectricblue;
      color: white;
      border-radius: 10px;

      .select__multi-value__remove {
        cursor: pointer;

        &:hover {
          background: transparent;
        }
      }

      .select__multi-value__label {
        color: white;

      }
    }

    .select__value-container {

      .select__single-value {
        @include mediumText;
        @media (max-width: 600px) {
          font-size: 110%;
          line-height: 15px;
        }
      }
    }
  }

  .select__menu {
    top: 100%;
  }

  .select__control--menu-is-open {
    .select__indicators .select__dropdown-indicator svg {
      transform: rotate(-180deg);
    }
  }

  .select__menu {
    .select__menu-list {
      .select__option {
        font-size: 14px;
      }

      .select__option--is-focused, .select__option--is-selected {
        cursor: pointer;
        background: $colorControlSecondaryDefault;
        color: $colorTextPrimary;
      }
    }
  }

  .select-full-placeholder {
    position: absolute;
    top: -20px;
    right: 0;
    border-radius: 10px;
    font-size: 11px;
    background: #f0f3f5;
    padding: 2px 5px;
    color: $colorTextSecondary;
    opacity: 0;
    transition: 0.3s ease-out;
    width: max-content;
  }


}

.type_has_error .psevdo_ph {
  @include standardNarrow;
}

.checkbox-message {
  @include smallText;
  color: $colorTextSecondary;
  width: 90%;
}

.otp-phone-modal {
  .popup_container {
    max-width: 440px;
  }
}
.pdf-modal {
  .annotationLayer{
    display: none;
  }

  .popup_container {
    max-width: 100%;
    width: 90vw;
    height: 90vh;
    .popup_title{
      p{
        margin: 0;
      }
    }
    .popup_body{
      margin: 20px;
      overflow-y: scroll;
      height: calc(100% - 30px);
      overflow-x: auto;
      &::-webkit-scrollbar {
        height: 1em;
        width: 1em;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $fieldBorder;
        border-radius: 6px;
      }
    }
  }
}

.otp-inputs-wrapper {
  align-items: center;
  justify-content: center;
  gap: 16px;

  .otp-nums {
    border-radius: $borderRadius12;
    width: 48px;
    height: 48px;
    border: 1px solid $colorControlBorderDefault;
    padding: 13px 18px;
    @include mediumText;
    color: $colorTextSecondary;

    &:focus {
      border: 1px solid $colorControlBorderFocus;

    }
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


#verifiBtn {
  margin-top: 16px;
  justify-content: center;
  @include standardNarrow;
  color: $colorTextLinkDefault;
}

.resend-wrapper {
  @include mediumText;

  span {
    color: $colorTextSecondary;
  }
}
.otp_error{
  text-align: center;
  color: $colorAccentNegative;
  @include smallText;
}
@media (max-width: $size960) {
  .SME_main_form {
    .form_blocks.blog_info {
      display: none;

      div {
        padding: 20px;

        img {
          width: 100%;
        }
      }
    }

    //.form_blocks.form_inputs{
    //  width: 100%;
    //}
  }
}


@media (max-width: 900px) {
  .SME_main_form {
    .form_blocks.blog_info {
      display: none;
    }

    .form_blocks.form_inputs {
      width: 100%;

      .chakra-accordion__panel {
        div {
          width: 100%;
          display: block;
        }

        .CashRegister {
          display: flex;
        }
      }

      .chakra-accordion__panel.service-wrapper {
        div {
          width: 100%;
          display: flex;
        }

      }
    }
  }
}

@media (max-width: $size991) {
  .SME_main_form {
    margin: auto auto 64px;

    .submit_form {
      .form-image-small {
        display: block;
        margin-bottom: 45px;
      }
    }

    .form_blocks .default-text {
      width: 100%;
    }
  }
}

@media (max-width: $size600) {
  .SME_main_form {
    .error_hint {
      font-size: 11px;
    }

    .field_block .error-icon {
      width: 15px;
    }

    .form_select {
      font-size: 110%;
      color: $textColor;
    }

    .checkbox-area {
      .chakra_checkbox {
        width: 20px;
        height: 20px;
      }
    }

    .select-block > div .select__control .select__placeholder {
      font-size: 10px;
      line-height: 11px;
    }

    .submit_form .chakra-accordion .chakra-accordion__item .file_input_label .input-wrapper .cover-wrapper span {
      font-size: 11px;
    }

    .submit_form .chakra-accordion .chakra-accordion__item .file_input_label .input-wrapper.images-input-wrapper .cover-wrapper span {
      font-size: 11px;
    }

    .chakra-accordion .btn_block {
      margin-top: 10px !important;
    }

    .form_inputs {
      padding: 20px;
    }
  }
}

@media (max-width: $size576) {
  .form_blocks {
    .fields-wrapper {
      margin-top: 24px;

      .inputs-area {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 15px;

        .field_block {
          margin-bottom: 15px;
        }

        > div {
          display: flex;
          flex-direction: column;
          width: 100%;

          .select__control {
            margin-bottom: 20px;
          }

          .select__menu {
            top: 70%;
          }

          .chakra-select__icon-wrapper {
            top: 30px;
          }
        }
      }
    }
  }
}