//font families
$regular: "montserrat", "montserratarm-light";
$medium: "montserrat-medium", "montserratarm";
$semibold: "montserrat-semibold", "montserratarm-medium";
//media query sizes

$size360: 360px;
$size400: 400px;
$size480: 480px;
$size576: 576px;
$size600: 600px;
$size640: 640px;
$size720: 720px;
$size768: 768px;
$size991: 991px;
$size960: 1025px;
$size1201: 1200px;
$size1200: 1280px;
$size1366: 1366px;
$size1440: 1440px;
$size1600: 1600px;

//colors
$black: #000000;
$black33: #333333; 
$gray66: #666666;
$gray99: #999999;
$grayE5: #e5e5e5;
$grayE9: #e9e9e9;
$grayF5: #F5F7F9;
$grayA9: #A9A9A9;
$grayCC: #CCCCCC;
$white: #ffffff;
$creamyF2:#f2f2f2;
$creamyF8: #f8f8f8;
$error: #FF5252;
$siteColor: #007DC3;
$siteLightColor: #E5F2F9;
$siteLightHover: #D6EAF5;
$secondColor: #F78E1E;
$secondDarkColor: #E57905;
$textColor: $black33;
$fieldBorder: #d9d9d9;
$calcBorder: #EFF3F6;
$success: #00CC39;

//social icons original color
$facebookColor: #3b5998;
$twitterColor: #00acee;
$youtubeColor: #c4302b;
$behanceColor: #053eff;
$linkedinColor: #0e76a8;
$okColor: #ed812b;
$pinterestColor: #c8232c;
$vkColor: #4c75a3;
$tiktokColor: #EE1D52;
$telegramColor: #0088cc;
$instagramColor: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);

//sizes
$containerWidth: 1200px;
$containerPadding: 20px;
$mobileContainerPadding: 16px;
$colPadding: 10px;
$headerPadding: 16px 40px;
$mobileColPadding: 8px;
$rowMargin: -$colPadding;
$mobileRowMargin: -$mobileColPadding;

@keyframes shine {
    to {
      background-position-x: -200%;
    }
}