@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.branches-map-wrapper{
  .cluster{
    div{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px!important;
      height: 48px;
      font-size: 16px!important;
      line-height: 22px!important;
      font-family: "NotoSansArmenian-SemiBold", sans-serif!important;
      background: $colorBrandDarkblue;
      border-radius: 100%;
      color: white!important;
    }
  }
  .gm-style-iw.gm-style-iw-c{
    padding: 24px;
    .info-wrapper{
      max-width: 320px;
      .title{
        @include mediumText;
        color: $colorTextPrimary;
        font-family: 'montserrat', sans-serif;
        font-weight: 600;
        margin: 0;
        padding: 0;
      }
      .summary{
        @include standardNarrow;
        color: $colorTextPrimary;
        font-family: "NotoSansArmenian-SemiBold", sans-serif;
      }
      .work-days-wrapper{
        gap: 10px;
        align-items: center;
        .work-days{
          flex-direction: column;
          align-items: start;
          gap: 4px;
          color: $colorTextSecondary;
          @include standardNarrow;
          *{
          margin: 0;
          }
        }
      }
      .branch{
        @include smallText;
        color: $colorTextSecondary;
      }
      button,a{
        margin-top: 16px!important;
      }
      .phone-wrapper{
        align-items: center;
        gap: 10px;
        color: $colorTextPrimary;
        @include standardNarrow;
      }
    }

  }
  .gm-ui-hover-effect{
    width: 32px!important;
    height: 32px!important;
    span{
      width: 12px!important;
      height: 12px!important;
    }
  }
}







  .regions-wrapper{
    .chakra-tabs{
      width: 100%;
      .chakra-tabs__tablist{
        align-items: center;
        gap: 24px;
          .chakra-tabs__tab{
            border: none;
            @include mediumText;
            padding: 12px 0;
            background: transparent;
            &[aria-selected="true"]{
              border-bottom: 2px solid $colorControlTabPressed;
            }
          }
      }
  }
}
